/* tslint:disable */
/* eslint-disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface XAny {}

export interface Role {
  id?: number;
  name: string;
  description?: string;
  created?: string;
  modified?: string;
}

/**
 * Map principals to roles
 */
export interface RoleMapping {
  id?: number;

  /** The principal type, such as USER, APPLICATION, ROLE, or user model name in case of multiple user models */
  principalType?: string;
  principalId?: string;
  roleId?: number;
}

export interface AccountToken {
  id: string;

  /** time to live in seconds (2 weeks by default) */
  ttl?: number;

  /** Array of scopes granted to this access token. */
  scopes?: string[];
  created?: string;
  userId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Order {
  id: string;
  title?: string;
  type?: string;
  images?: object[];
  desiredDepartureDate?: string;
  desiredArrivalDate?: string;
  actualDepartureDate?: string;
  actualArrivalDate?: string;
  description?: string;
  note?: string;
  qrCode: string;
  status?: string;
  proposedInitCost?: number;
  isMovingToDeliver?: boolean;
  isMovingToReceive?: boolean;
  isBringingOut?: boolean;
  isBringingInto?: boolean;
  proposedConfirmedCost?: number;
  confirmedTotalCost?: number;
  ecopostProfit?: number;
  totalSize?: number;
  promotionCode?: object;
  distance?: number;
  arrivalNote?: string;
  departureNote?: string;
  arrivalContactName?: string;
  arrivalContactPhone?: string;
  departureContactName?: string;
  departureContactPhone?: string;
  ownerId?: string;
  transporterId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Message {
  content?: string;
  proposedCost?: number;
  proposedDisplayCost?: number;
  proposalStatus?: string;
  isRead?: boolean;
  id?: number;
  authorId?: string;
  createdAt?: string;
  updatedAt?: string;
  conversationId?: number;
}

export interface PaymentRequest {
  id: string;
  amount: number;
  status?: string;
  metadata?: object;
  authorId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Route {
  startDate?: string;
  returnDate?: string;
  searchRadius?: number;
  isFrequent?: boolean;
  isMonday?: boolean;
  isTuesday?: boolean;
  isWednesday?: boolean;
  isThursday?: boolean;
  isFriday?: boolean;
  isSaturday?: boolean;
  isSunday?: boolean;
  desiredPackageEstimatedSize?: string;
  desiredVolume?: number;
  isRoundTrip?: boolean;
  isPassengerAccepted?: boolean;
  isAnimalAccepted?: boolean;
  status?: string;
  id?: number;
  accountId?: string;
  createdAt?: string;
  updatedAt?: string;
  vehicleId?: number;
}

export interface Conversation {
  name?: string;
  description?: string;
  lastProposedCost?: number;
  status?: string;
  id?: number;
  orderId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface AccountConversation {
  id?: number;
  accountId?: string;
  conversationId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface InAppNotification {
  title?: string;
  message?: string;
  type?: string;
  isRead?: boolean;
  id?: number;
  accountId?: string;
  orderId?: string;
  routeId?: number;
  conversationId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface AccountReport {
  currentAmount?: number;
  unpaidAmount?: number;
  earningThisMonthAmount?: number;
  earningThisYearAmount?: number;
  currentOnlineAnnoncesCount?: number;
  currentDeliveringOrderCount?: number;
  id?: number;
  accountId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Payment {
  id: string;
  status?: string;
  method?: string;
  amount?: number;
  stripeToken?: string;
  stripeCardId?: string;
  paypalAuthorizationId?: string;
  orderId?: string;
  paymentRequestId?: string;
  authorId?: string;
  conversationId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface PaymentDetail {
  id: string;
  amount: number;
  status?: string;
  paymentId?: string;
  ownerId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface AccountStatistics {
  amount?: number;
  durationType?: string;
  month?: number;
  year?: number;
  type?: string;
  id?: number;
  accountId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface UserIdentity {
  provider?: string;
  authScheme?: string;
  externalId?: string;
  profile?: object;
  credentials?: object;
  created?: string;
  modified?: string;
  id?: number;
  accountId?: string;
  userId?: string;
}

export interface UserCredential {
  provider?: string;
  authScheme?: string;
  externalId?: string;
  profile?: object;
  credentials?: object;
  created?: string;
  modified?: string;
  id?: number;
  accountId?: string;
  userId?: string;
}

export interface Account {
  id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  avatar?: string;
  stripeCustomerId?: string;
  currentAmount?: number;
  unpaidAmount?: number;
  language?: string;
  birthday?: string;
  gender?: string;
  bankInformation?: object;
  legalDocuments?: object[];
  isEmailNotificationRegistered?: boolean;
  isPushNotificationRegistered?: boolean;
  isSMSNotificationRegistered?: boolean;
  isNewsletterSubscribed?: boolean;
  isEmailVerified?: boolean;
  isPhoneNumberVerified?: boolean;
  isLegalDocumentVerified?: boolean;
  isBankInformationVerified?: boolean;
  type?: string;
  company?: string;
  companyType?: string;
  isActive?: boolean;
  realm?: string;
  username?: string;
  email: string;
  emailVerified?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface Package {
  uuid?: string;
  name?: string;
  description?: string;
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  volume?: number;
  quantity?: number;
  estimatedSize?: string;
  id?: number;
  orderId?: string;
  createdAt?: string;
  updatedAt?: string;
  packageCategoryId?: number;
}

export interface Address {
  displayData?: object;
  postalCode?: string;
  location?: GeoPoint;
  metadata?: object;
  detailNotes?: string;
  type?: string;
  index?: number;
  placeId?: string;
  id?: number;
  routeId?: number;
  orderId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface RouteOrderSuggestion {
  id?: number;
  routeId?: number;
  orderId?: string;
}

export interface GeoPoint {
  lat?: number;
  lng?: number;
}

export interface PackageCategory {
  name?: string;
  description?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Vehicle {
  displayName?: string;
  description?: string;
  displayData?: object;
  defaultPackageEstimatedSize?: string;
  defaultVolume?: number;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Setting {
  name: string;
  stringValue?: string;
  objectValue?: object;
  numberValue?: number;
  description?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Push {
  id?: number;
}

export interface Notification {
  alert?: XAny;
  badge?: number;
  category?: string;
  collapseKey?: string;
  contentAvailable?: boolean;
  created?: string;
  delayWhileIdle?: boolean;
  deviceToken: string;
  deviceType: string;
  expirationInterval?: number;
  expirationTime?: string;
  modified?: string;
  scheduledTime?: string;
  sound?: string;
  status?: string;
  urlArgs?: string[];
  id?: number;
}

export interface Application {
  id: string;
  realm?: string;
  name: string;
  description?: string;

  /** The icon image url */
  icon?: string;

  /** The user id of the developer who registers the application */
  owner?: string;

  /** A list of users ids who have permissions to work on this app */
  collaborators?: string[];
  email?: string;
  emailVerified?: boolean;

  /** The application URL for OAuth 2.0 */
  url?: string;

  /** OAuth 2.0 code/token callback URLs */
  callbackUrls?: string[];

  /** A list of permissions required by the application */
  permissions?: string[];
  clientKey?: string;
  javaScriptKey?: string;
  restApiKey?: string;
  windowsKey?: string;
  masterKey?: string;
  pushSettings?: XAny;
  authenticationEnabled?: boolean;
  anonymousAllowed?: boolean;
  authenticationSchemes?: XAny[];

  /** Status of the application, production/sandbox/disabled */
  status?: string;
  created?: string;
  modified?: string;
}

export interface Installation {
  appId: string;
  appVersion?: string;
  badge?: number;
  created?: string;
  deviceToken: string;
  deviceType: string;
  modified?: string;
  status?: string;
  subscriptions?: string[];
  timeZone?: string;
  userId?: string;
  id?: number;
}

export interface Contact {
  email?: string;
  phoneNumber?: string;
  message?: string;
  id?: number;
}

export interface SystemStatistics {
  amount?: number;
  durationType?: string;
  day?: number;
  month?: number;
  year?: number;
  type?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

type ApiConfig<SecurityDataType> = {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
};

enum BodyType {
  Json,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType = null as any;
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"] = (() => {}) as any;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor({ baseUrl, baseApiParams, securityWorker }: ApiConfig<SecurityDataType> = {}) {
    this.baseUrl = baseUrl || this.baseUrl;
    this.baseApiParams = baseApiParams || this.baseApiParams;
    this.securityWorker = securityWorker || this.securityWorker;
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(response: Response): Promise<T> =>
    response
      .json()
      .then((data) => data)
      .catch((e) => response.text);

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): Promise<T> =>
    fetch(`${this.baseUrl}${path}`, {
      // @ts-ignore
      ...this.mergeRequestOptions(params, (secureByDefault || secure) && this.securityWorker(this.securityData)),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    }).then(async (response) => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
}

/**
 * @title ECOPOST-API
 * @version 1.1.1
 * @baseUrl /api
 * ECOPOST-API
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  accounts = {
    /**
     * @tags Account
     * @name Account.prototype.__findById__roles
     * @summary Find a related item by id for roles.
     * @request GET:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeFindByIdRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__roles
     * @summary Delete a related item by id for roles.
     * @request DELETE:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeDestroyByIdRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__roles
     * @summary Update a related item by id for roles.
     * @request PUT:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeUpdateByIdRoles: (id: string, fk: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__link__roles
     * @summary Add a related item by id for roles.
     * @request PUT:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeLinkRoles: (id: string, fk: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Accounts/${id}/roles/rel/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__unlink__roles
     * @summary Remove the roles relation to an item by id.
     * @request DELETE:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeUnlinkRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles/rel/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__exists__roles
     * @summary Check the existence of roles relation to an item by id.
     * @request HEAD:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeExistsRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Accounts/${id}/roles/rel/${fk}`, "HEAD", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__accessTokens
     * @summary Find a related item by id for accessTokens.
     * @request GET:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeFindByIdAccessTokens: (id: string, fk: string, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__accessTokens
     * @summary Delete a related item by id for accessTokens.
     * @request DELETE:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeDestroyByIdAccessTokens: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accessTokens/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__accessTokens
     * @summary Update a related item by id for accessTokens.
     * @request PUT:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeUpdateByIdAccessTokens: (id: string, fk: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__annonces
     * @summary Find a related item by id for annonces.
     * @request GET:/Accounts/{id}/annonces/{fk}
     */
    accountPrototypeFindByIdAnnonces: (id: string, fk: string, params?: RequestParams) =>
      this.request<Order, any>(`/Accounts/${id}/annonces/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__annonces
     * @summary Delete a related item by id for annonces.
     * @request DELETE:/Accounts/{id}/annonces/{fk}
     */
    accountPrototypeDestroyByIdAnnonces: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/annonces/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__annonces
     * @summary Update a related item by id for annonces.
     * @request PUT:/Accounts/{id}/annonces/{fk}
     */
    accountPrototypeUpdateByIdAnnonces: (id: string, fk: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Accounts/${id}/annonces/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__deliveries
     * @summary Find a related item by id for deliveries.
     * @request GET:/Accounts/{id}/deliveries/{fk}
     */
    accountPrototypeFindByIdDeliveries: (id: string, fk: string, params?: RequestParams) =>
      this.request<Order, any>(`/Accounts/${id}/deliveries/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__deliveries
     * @summary Delete a related item by id for deliveries.
     * @request DELETE:/Accounts/{id}/deliveries/{fk}
     */
    accountPrototypeDestroyByIdDeliveries: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/deliveries/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__deliveries
     * @summary Update a related item by id for deliveries.
     * @request PUT:/Accounts/{id}/deliveries/{fk}
     */
    accountPrototypeUpdateByIdDeliveries: (id: string, fk: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Accounts/${id}/deliveries/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__messages
     * @summary Find a related item by id for messages.
     * @request GET:/Accounts/{id}/messages/{fk}
     */
    accountPrototypeFindByIdMessages: (id: string, fk: string, params?: RequestParams) =>
      this.request<Message, any>(`/Accounts/${id}/messages/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__messages
     * @summary Delete a related item by id for messages.
     * @request DELETE:/Accounts/{id}/messages/{fk}
     */
    accountPrototypeDestroyByIdMessages: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/messages/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__messages
     * @summary Update a related item by id for messages.
     * @request PUT:/Accounts/{id}/messages/{fk}
     */
    accountPrototypeUpdateByIdMessages: (id: string, fk: string, data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Accounts/${id}/messages/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__paymentRequests
     * @summary Find a related item by id for paymentRequests.
     * @request GET:/Accounts/{id}/paymentRequests/{fk}
     */
    accountPrototypeFindByIdPaymentRequests: (id: string, fk: string, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/Accounts/${id}/paymentRequests/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__paymentRequests
     * @summary Delete a related item by id for paymentRequests.
     * @request DELETE:/Accounts/{id}/paymentRequests/{fk}
     */
    accountPrototypeDestroyByIdPaymentRequests: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/paymentRequests/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__paymentRequests
     * @summary Update a related item by id for paymentRequests.
     * @request PUT:/Accounts/{id}/paymentRequests/{fk}
     */
    accountPrototypeUpdateByIdPaymentRequests: (id: string, fk: string, data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/Accounts/${id}/paymentRequests/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__routes
     * @summary Find a related item by id for routes.
     * @request GET:/Accounts/{id}/routes/{fk}
     */
    accountPrototypeFindByIdRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<Route, any>(`/Accounts/${id}/routes/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__routes
     * @summary Delete a related item by id for routes.
     * @request DELETE:/Accounts/{id}/routes/{fk}
     */
    accountPrototypeDestroyByIdRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/routes/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__routes
     * @summary Update a related item by id for routes.
     * @request PUT:/Accounts/{id}/routes/{fk}
     */
    accountPrototypeUpdateByIdRoutes: (id: string, fk: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Accounts/${id}/routes/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__conversations
     * @summary Find a related item by id for conversations.
     * @request GET:/Accounts/{id}/conversations/{fk}
     */
    accountPrototypeFindByIdConversations: (id: string, fk: string, params?: RequestParams) =>
      this.request<Conversation, any>(`/Accounts/${id}/conversations/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__conversations
     * @summary Delete a related item by id for conversations.
     * @request DELETE:/Accounts/{id}/conversations/{fk}
     */
    accountPrototypeDestroyByIdConversations: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/conversations/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__conversations
     * @summary Update a related item by id for conversations.
     * @request PUT:/Accounts/{id}/conversations/{fk}
     */
    accountPrototypeUpdateByIdConversations: (id: string, fk: string, data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Accounts/${id}/conversations/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__link__conversations
     * @summary Add a related item by id for conversations.
     * @request PUT:/Accounts/{id}/conversations/rel/{fk}
     */
    accountPrototypeLinkConversations: (id: string, fk: string, data: AccountConversation, params?: RequestParams) =>
      this.request<AccountConversation, any>(`/Accounts/${id}/conversations/rel/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__unlink__conversations
     * @summary Remove the conversations relation to an item by id.
     * @request DELETE:/Accounts/{id}/conversations/rel/{fk}
     */
    accountPrototypeUnlinkConversations: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/conversations/rel/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__exists__conversations
     * @summary Check the existence of conversations relation to an item by id.
     * @request HEAD:/Accounts/{id}/conversations/rel/{fk}
     */
    accountPrototypeExistsConversations: (id: string, fk: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Accounts/${id}/conversations/rel/${fk}`, "HEAD", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__inAppNotifications
     * @summary Find a related item by id for inAppNotifications.
     * @request GET:/Accounts/{id}/inAppNotifications/{fk}
     */
    accountPrototypeFindByIdInAppNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/Accounts/${id}/inAppNotifications/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__inAppNotifications
     * @summary Delete a related item by id for inAppNotifications.
     * @request DELETE:/Accounts/{id}/inAppNotifications/{fk}
     */
    accountPrototypeDestroyByIdInAppNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/inAppNotifications/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__inAppNotifications
     * @summary Update a related item by id for inAppNotifications.
     * @request PUT:/Accounts/{id}/inAppNotifications/{fk}
     */
    accountPrototypeUpdateByIdInAppNotifications: (
      id: string,
      fk: string,
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/Accounts/${id}/inAppNotifications/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__report
     * @summary Fetches hasOne relation report.
     * @request GET:/Accounts/{id}/report
     */
    accountPrototypeGetReport: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<AccountReport, any>(`/Accounts/${id}/report${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__report
     * @summary Creates a new instance in report of this model.
     * @request POST:/Accounts/{id}/report
     */
    accountPrototypeCreateReport: (id: string, data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/Accounts/${id}/report`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__update__report
     * @summary Update report of this model.
     * @request PUT:/Accounts/{id}/report
     */
    accountPrototypeUpdateReport: (id: string, data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/Accounts/${id}/report`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__destroy__report
     * @summary Deletes report of this model.
     * @request DELETE:/Accounts/{id}/report
     */
    accountPrototypeDestroyReport: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/report`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__payments
     * @summary Find a related item by id for payments.
     * @request GET:/Accounts/{id}/payments/{fk}
     */
    accountPrototypeFindByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<Payment, any>(`/Accounts/${id}/payments/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__payments
     * @summary Delete a related item by id for payments.
     * @request DELETE:/Accounts/{id}/payments/{fk}
     */
    accountPrototypeDestroyByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/payments/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__payments
     * @summary Update a related item by id for payments.
     * @request PUT:/Accounts/{id}/payments/{fk}
     */
    accountPrototypeUpdateByIdPayments: (id: string, fk: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Accounts/${id}/payments/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__paymentDetails
     * @summary Find a related item by id for paymentDetails.
     * @request GET:/Accounts/{id}/paymentDetails/{fk}
     */
    accountPrototypeFindByIdPaymentDetails: (id: string, fk: string, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/Accounts/${id}/paymentDetails/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__paymentDetails
     * @summary Delete a related item by id for paymentDetails.
     * @request DELETE:/Accounts/{id}/paymentDetails/{fk}
     */
    accountPrototypeDestroyByIdPaymentDetails: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/paymentDetails/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__paymentDetails
     * @summary Update a related item by id for paymentDetails.
     * @request PUT:/Accounts/{id}/paymentDetails/{fk}
     */
    accountPrototypeUpdateByIdPaymentDetails: (id: string, fk: string, data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/Accounts/${id}/paymentDetails/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__accountStatistics
     * @summary Find a related item by id for accountStatistics.
     * @request GET:/Accounts/{id}/accountStatistics/{fk}
     */
    accountPrototypeFindByIdAccountStatistics: (id: string, fk: string, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/Accounts/${id}/accountStatistics/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__accountStatistics
     * @summary Delete a related item by id for accountStatistics.
     * @request DELETE:/Accounts/{id}/accountStatistics/{fk}
     */
    accountPrototypeDestroyByIdAccountStatistics: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accountStatistics/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__accountStatistics
     * @summary Update a related item by id for accountStatistics.
     * @request PUT:/Accounts/{id}/accountStatistics/{fk}
     */
    accountPrototypeUpdateByIdAccountStatistics: (
      id: string,
      fk: string,
      data: AccountStatistics,
      params?: RequestParams,
    ) => this.request<AccountStatistics, any>(`/Accounts/${id}/accountStatistics/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__identities
     * @summary Find a related item by id for identities.
     * @request GET:/Accounts/{id}/identities/{fk}
     */
    accountPrototypeFindByIdIdentities: (id: string, fk: string, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/Accounts/${id}/identities/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__identities
     * @summary Delete a related item by id for identities.
     * @request DELETE:/Accounts/{id}/identities/{fk}
     */
    accountPrototypeDestroyByIdIdentities: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/identities/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__identities
     * @summary Update a related item by id for identities.
     * @request PUT:/Accounts/{id}/identities/{fk}
     */
    accountPrototypeUpdateByIdIdentities: (id: string, fk: string, data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/Accounts/${id}/identities/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__credentials
     * @summary Find a related item by id for credentials.
     * @request GET:/Accounts/{id}/credentials/{fk}
     */
    accountPrototypeFindByIdCredentials: (id: string, fk: string, params?: RequestParams) =>
      this.request<UserCredential, any>(`/Accounts/${id}/credentials/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__credentials
     * @summary Delete a related item by id for credentials.
     * @request DELETE:/Accounts/{id}/credentials/{fk}
     */
    accountPrototypeDestroyByIdCredentials: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/credentials/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__credentials
     * @summary Update a related item by id for credentials.
     * @request PUT:/Accounts/{id}/credentials/{fk}
     */
    accountPrototypeUpdateByIdCredentials: (id: string, fk: string, data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/Accounts/${id}/credentials/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__roles
     * @summary Queries roles of Account.
     * @request GET:/Accounts/{id}/roles
     */
    accountPrototypeGetRoles: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role[], any>(`/Accounts/${id}/roles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__roles
     * @summary Creates a new instance in roles of this model.
     * @request POST:/Accounts/{id}/roles
     */
    accountPrototypeCreateRoles: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__roles
     * @summary Deletes all roles of this model.
     * @request DELETE:/Accounts/{id}/roles
     */
    accountPrototypeDeleteRoles: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__roles
     * @summary Counts roles of Account.
     * @request GET:/Accounts/{id}/roles/count
     */
    accountPrototypeCountRoles: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/${id}/roles/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__accessTokens
     * @summary Queries accessTokens of Account.
     * @request GET:/Accounts/{id}/accessTokens
     */
    accountPrototypeGetAccessTokens: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken[], any>(`/Accounts/${id}/accessTokens${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__accessTokens
     * @summary Creates a new instance in accessTokens of this model.
     * @request POST:/Accounts/{id}/accessTokens
     */
    accountPrototypeCreateAccessTokens: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__accessTokens
     * @summary Deletes all accessTokens of this model.
     * @request DELETE:/Accounts/{id}/accessTokens
     */
    accountPrototypeDeleteAccessTokens: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accessTokens`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__accessTokens
     * @summary Counts accessTokens of Account.
     * @request GET:/Accounts/{id}/accessTokens/count
     */
    accountPrototypeCountAccessTokens: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/accessTokens/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__annonces
     * @summary Queries annonces of Account.
     * @request GET:/Accounts/{id}/annonces
     */
    accountPrototypeGetAnnonces: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Order[], any>(`/Accounts/${id}/annonces${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__annonces
     * @summary Creates a new instance in annonces of this model.
     * @request POST:/Accounts/{id}/annonces
     */
    accountPrototypeCreateAnnonces: (id: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Accounts/${id}/annonces`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__annonces
     * @summary Deletes all annonces of this model.
     * @request DELETE:/Accounts/{id}/annonces
     */
    accountPrototypeDeleteAnnonces: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/annonces`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__annonces
     * @summary Counts annonces of Account.
     * @request GET:/Accounts/{id}/annonces/count
     */
    accountPrototypeCountAnnonces: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/annonces/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__deliveries
     * @summary Queries deliveries of Account.
     * @request GET:/Accounts/{id}/deliveries
     */
    accountPrototypeGetDeliveries: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Order[], any>(`/Accounts/${id}/deliveries${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__deliveries
     * @summary Creates a new instance in deliveries of this model.
     * @request POST:/Accounts/{id}/deliveries
     */
    accountPrototypeCreateDeliveries: (id: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Accounts/${id}/deliveries`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__deliveries
     * @summary Deletes all deliveries of this model.
     * @request DELETE:/Accounts/{id}/deliveries
     */
    accountPrototypeDeleteDeliveries: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/deliveries`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__deliveries
     * @summary Counts deliveries of Account.
     * @request GET:/Accounts/{id}/deliveries/count
     */
    accountPrototypeCountDeliveries: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/deliveries/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__messages
     * @summary Queries messages of Account.
     * @request GET:/Accounts/{id}/messages
     */
    accountPrototypeGetMessages: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Message[], any>(`/Accounts/${id}/messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__messages
     * @summary Creates a new instance in messages of this model.
     * @request POST:/Accounts/{id}/messages
     */
    accountPrototypeCreateMessages: (id: string, data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Accounts/${id}/messages`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__messages
     * @summary Deletes all messages of this model.
     * @request DELETE:/Accounts/{id}/messages
     */
    accountPrototypeDeleteMessages: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/messages`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__messages
     * @summary Counts messages of Account.
     * @request GET:/Accounts/{id}/messages/count
     */
    accountPrototypeCountMessages: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/messages/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__paymentRequests
     * @summary Queries paymentRequests of Account.
     * @request GET:/Accounts/{id}/paymentRequests
     */
    accountPrototypeGetPaymentRequests: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentRequest[], any>(
        `/Accounts/${id}/paymentRequests${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__create__paymentRequests
     * @summary Creates a new instance in paymentRequests of this model.
     * @request POST:/Accounts/{id}/paymentRequests
     */
    accountPrototypeCreatePaymentRequests: (id: string, data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/Accounts/${id}/paymentRequests`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__paymentRequests
     * @summary Deletes all paymentRequests of this model.
     * @request DELETE:/Accounts/{id}/paymentRequests
     */
    accountPrototypeDeletePaymentRequests: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/paymentRequests`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__paymentRequests
     * @summary Counts paymentRequests of Account.
     * @request GET:/Accounts/{id}/paymentRequests/count
     */
    accountPrototypeCountPaymentRequests: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/paymentRequests/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__routes
     * @summary Queries routes of Account.
     * @request GET:/Accounts/{id}/routes
     */
    accountPrototypeGetRoutes: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Route[], any>(`/Accounts/${id}/routes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__routes
     * @summary Creates a new instance in routes of this model.
     * @request POST:/Accounts/{id}/routes
     */
    accountPrototypeCreateRoutes: (id: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Accounts/${id}/routes`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__routes
     * @summary Deletes all routes of this model.
     * @request DELETE:/Accounts/{id}/routes
     */
    accountPrototypeDeleteRoutes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/routes`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__routes
     * @summary Counts routes of Account.
     * @request GET:/Accounts/{id}/routes/count
     */
    accountPrototypeCountRoutes: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/${id}/routes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__conversations
     * @summary Queries conversations of Account.
     * @request GET:/Accounts/{id}/conversations
     */
    accountPrototypeGetConversations: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Conversation[], any>(`/Accounts/${id}/conversations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__conversations
     * @summary Creates a new instance in conversations of this model.
     * @request POST:/Accounts/{id}/conversations
     */
    accountPrototypeCreateConversations: (id: string, data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Accounts/${id}/conversations`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__conversations
     * @summary Deletes all conversations of this model.
     * @request DELETE:/Accounts/{id}/conversations
     */
    accountPrototypeDeleteConversations: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/conversations`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__conversations
     * @summary Counts conversations of Account.
     * @request GET:/Accounts/{id}/conversations/count
     */
    accountPrototypeCountConversations: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/conversations/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__inAppNotifications
     * @summary Queries inAppNotifications of Account.
     * @request GET:/Accounts/{id}/inAppNotifications
     */
    accountPrototypeGetInAppNotifications: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification[], any>(
        `/Accounts/${id}/inAppNotifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__create__inAppNotifications
     * @summary Creates a new instance in inAppNotifications of this model.
     * @request POST:/Accounts/{id}/inAppNotifications
     */
    accountPrototypeCreateInAppNotifications: (id: string, data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/Accounts/${id}/inAppNotifications`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__inAppNotifications
     * @summary Deletes all inAppNotifications of this model.
     * @request DELETE:/Accounts/{id}/inAppNotifications
     */
    accountPrototypeDeleteInAppNotifications: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/inAppNotifications`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__inAppNotifications
     * @summary Counts inAppNotifications of Account.
     * @request GET:/Accounts/{id}/inAppNotifications/count
     */
    accountPrototypeCountInAppNotifications: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/inAppNotifications/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__payments
     * @summary Queries payments of Account.
     * @request GET:/Accounts/{id}/payments
     */
    accountPrototypeGetPayments: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Payment[], any>(`/Accounts/${id}/payments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__payments
     * @summary Creates a new instance in payments of this model.
     * @request POST:/Accounts/{id}/payments
     */
    accountPrototypeCreatePayments: (id: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Accounts/${id}/payments`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__payments
     * @summary Deletes all payments of this model.
     * @request DELETE:/Accounts/{id}/payments
     */
    accountPrototypeDeletePayments: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/payments`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__payments
     * @summary Counts payments of Account.
     * @request GET:/Accounts/{id}/payments/count
     */
    accountPrototypeCountPayments: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/payments/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__paymentDetails
     * @summary Queries paymentDetails of Account.
     * @request GET:/Accounts/{id}/paymentDetails
     */
    accountPrototypeGetPaymentDetails: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentDetail[], any>(`/Accounts/${id}/paymentDetails${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__paymentDetails
     * @summary Creates a new instance in paymentDetails of this model.
     * @request POST:/Accounts/{id}/paymentDetails
     */
    accountPrototypeCreatePaymentDetails: (id: string, data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/Accounts/${id}/paymentDetails`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__paymentDetails
     * @summary Deletes all paymentDetails of this model.
     * @request DELETE:/Accounts/{id}/paymentDetails
     */
    accountPrototypeDeletePaymentDetails: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/paymentDetails`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__paymentDetails
     * @summary Counts paymentDetails of Account.
     * @request GET:/Accounts/{id}/paymentDetails/count
     */
    accountPrototypeCountPaymentDetails: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/paymentDetails/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__accountStatistics
     * @summary Queries accountStatistics of Account.
     * @request GET:/Accounts/{id}/accountStatistics
     */
    accountPrototypeGetAccountStatistics: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountStatistics[], any>(
        `/Accounts/${id}/accountStatistics${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__create__accountStatistics
     * @summary Creates a new instance in accountStatistics of this model.
     * @request POST:/Accounts/{id}/accountStatistics
     */
    accountPrototypeCreateAccountStatistics: (id: string, data: AccountStatistics, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/Accounts/${id}/accountStatistics`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__accountStatistics
     * @summary Deletes all accountStatistics of this model.
     * @request DELETE:/Accounts/{id}/accountStatistics
     */
    accountPrototypeDeleteAccountStatistics: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accountStatistics`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__accountStatistics
     * @summary Counts accountStatistics of Account.
     * @request GET:/Accounts/{id}/accountStatistics/count
     */
    accountPrototypeCountAccountStatistics: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/accountStatistics/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__identities
     * @summary Queries identities of Account.
     * @request GET:/Accounts/{id}/identities
     */
    accountPrototypeGetIdentities: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserIdentity[], any>(`/Accounts/${id}/identities${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__identities
     * @summary Creates a new instance in identities of this model.
     * @request POST:/Accounts/{id}/identities
     */
    accountPrototypeCreateIdentities: (id: string, data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/Accounts/${id}/identities`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__identities
     * @summary Deletes all identities of this model.
     * @request DELETE:/Accounts/{id}/identities
     */
    accountPrototypeDeleteIdentities: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/identities`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__identities
     * @summary Counts identities of Account.
     * @request GET:/Accounts/{id}/identities/count
     */
    accountPrototypeCountIdentities: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/identities/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__credentials
     * @summary Queries credentials of Account.
     * @request GET:/Accounts/{id}/credentials
     */
    accountPrototypeGetCredentials: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserCredential[], any>(`/Accounts/${id}/credentials${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__credentials
     * @summary Creates a new instance in credentials of this model.
     * @request POST:/Accounts/{id}/credentials
     */
    accountPrototypeCreateCredentials: (id: string, data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/Accounts/${id}/credentials`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__credentials
     * @summary Deletes all credentials of this model.
     * @request DELETE:/Accounts/{id}/credentials
     */
    accountPrototypeDeleteCredentials: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/credentials`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__credentials
     * @summary Counts credentials of Account.
     * @request GET:/Accounts/{id}/credentials/count
     */
    accountPrototypeCountCredentials: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/credentials/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Accounts
     */
    accountCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Accounts
     */
    accountPatchOrCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "PATCH", params, data),

    /**
     * @tags Account
     * @name Account.replaceOrCreate__put_Accounts
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Accounts
     */
    accountReplaceOrCreatePutAccounts: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Accounts
     */
    accountFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account[], any>(`/Accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.replaceOrCreate__post_Accounts_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Accounts/replaceOrCreate
     */
    accountReplaceOrCreatePostAccountsReplaceOrCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Accounts/upsertWithWhere
     */
    accountUpsertWithWhere: (data: Account, query?: { where?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.exists__get_Accounts_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Accounts/{id}/exists
     */
    accountExistsGetAccountsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accounts/${id}/exists`, "GET", params),

    /**
     * @tags Account
     * @name Account.exists__head_Accounts_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Accounts/{id}
     */
    accountExistsHeadAccountsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accounts/${id}`, "HEAD", params),

    /**
     * @tags Account
     * @name Account.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Accounts/{id}
     */
    accountFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.replaceById__put_Accounts_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Accounts/{id}
     */
    accountReplaceByIdPutAccountsId: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Accounts/{id}
     */
    accountDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/${id}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Accounts/{id}
     */
    accountPrototypePatchAttributes: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}`, "PATCH", params, data),

    /**
     * @tags Account
     * @name Account.replaceById__post_Accounts_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Accounts/{id}/replace
     */
    accountReplaceByIdPostAccountsIdReplace: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}/replace`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Accounts/findOne
     */
    accountFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Accounts/update
     */
    accountUpdateAll: (data: Account, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Accounts/count
     */
    accountCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.createChangeStream__post_Accounts_change-stream
     * @summary Create a change stream.
     * @request POST:/Accounts/change-stream
     */
    accountCreateChangeStreamPostAccountsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accounts/change-stream`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.createChangeStream__get_Accounts_change-stream
     * @summary Create a change stream.
     * @request GET:/Accounts/change-stream
     */
    accountCreateChangeStreamGetAccountsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accounts/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.login
     * @summary Login a user with username/email and password.
     * @request POST:/Accounts/login
     */
    accountLogin: (credentials: object, query?: { include?: string }, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/login${this.addQueryParams(query)}`, "POST", params, credentials),

    /**
     * @tags Account
     * @name Account.logout
     * @summary Logout a user with access token.
     * @request POST:/Accounts/logout
     */
    accountLogout: (params?: RequestParams) => this.request<any, any>(`/Accounts/logout`, "POST", params),

    /**
     * @tags Account
     * @name Account.prototype.verify
     * @summary Trigger user's identity verification with configured verifyOptions
     * @request POST:/Accounts/{id}/verify
     */
    accountPrototypeVerify: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/verify`, "POST", params),

    /**
     * @tags Account
     * @name Account.confirm
     * @summary Confirm a user registration with identity verification token.
     * @request GET:/Accounts/confirm
     */
    accountConfirm: (query: { uid: string; token: string; redirect?: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/confirm${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.resetPassword
     * @summary Reset password for a user with email.
     * @request POST:/Accounts/reset
     */
    accountResetPassword: (options: object, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/reset`, "POST", params, options),

    /**
     * @tags Account
     * @name Account.changePassword
     * @summary Change a user's password.
     * @request POST:/Accounts/change-password
     */
    accountChangePassword: (data: { oldPassword: string; newPassword: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/change-password`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.setPassword
     * @summary Reset user's password via a password-reset token.
     * @request POST:/Accounts/reset-password
     */
    accountSetPassword: (data: { newPassword: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/reset-password`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.phoneVerificationRequest
     * @summary Make a request on phone verification
     * @request GET:/Accounts/phone/verify
     */
    accountPhoneVerificationRequest: (query: { phoneNumber: string; locale?: string }, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/phone/verify${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.phoneVerificationCheck
     * @summary Validate verification code
     * @request POST:/Accounts/phone/verify
     */
    accountPhoneVerificationCheck: (data: { phoneNumber: string; code: string }, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/phone/verify`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.resetPasswordRequest
     * @summary Reset password for a user with email or phone number
     * @request POST:/Accounts/reset-password-request
     */
    accountResetPasswordRequest: (options: object, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/reset-password-request`, "POST", params, options),

    /**
     * @tags Account
     * @name Account.prototype.generatePassword
     * @summary Generate new password for account
     * @request POST:/Accounts/{id}/password/generate
     */
    accountPrototypeGeneratePassword: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Accounts/${id}/password/generate`, "POST", params),
  };
  accountTokens = {
    /**
     * @tags AccountToken
     * @name AccountToken.prototype.__get__user
     * @summary Fetches belongsTo relation user.
     * @request GET:/AccountTokens/{id}/user
     */
    accountTokenPrototypeGetUser: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/AccountTokens/${id}/user${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AccountTokens
     */
    accountTokenCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AccountTokens
     */
    accountTokenPatchOrCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "PATCH", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceOrCreate__put_AccountTokens
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AccountTokens
     */
    accountTokenReplaceOrCreatePutAccountTokens: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "PUT", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AccountTokens
     */
    accountTokenFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken[], any>(`/AccountTokens${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceOrCreate__post_AccountTokens_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AccountTokens/replaceOrCreate
     */
    accountTokenReplaceOrCreatePostAccountTokensReplaceOrCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AccountTokens/upsertWithWhere
     */
    accountTokenUpsertWithWhere: (data: AccountToken, query?: { where?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(
        `/AccountTokens/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountToken
     * @name AccountToken.exists__get_AccountTokens_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AccountTokens/{id}/exists
     */
    accountTokenExistsGetAccountTokensIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountTokens/${id}/exists`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.exists__head_AccountTokens_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AccountTokens/{id}
     */
    accountTokenExistsHeadAccountTokensId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountTokens/${id}`, "HEAD", params),

    /**
     * @tags AccountToken
     * @name AccountToken.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AccountTokens/{id}
     */
    accountTokenFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceById__put_AccountTokens_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AccountTokens/{id}
     */
    accountTokenReplaceByIdPutAccountTokensId: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}`, "PUT", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AccountTokens/{id}
     */
    accountTokenDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AccountTokens/${id}`, "DELETE", params),

    /**
     * @tags AccountToken
     * @name AccountToken.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AccountTokens/{id}
     */
    accountTokenPrototypePatchAttributes: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}`, "PATCH", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceById__post_AccountTokens_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AccountTokens/{id}/replace
     */
    accountTokenReplaceByIdPostAccountTokensIdReplace: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}/replace`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AccountTokens/findOne
     */
    accountTokenFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AccountTokens/update
     */
    accountTokenUpdateAll: (data: AccountToken, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountTokens/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AccountTokens/count
     */
    accountTokenCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountTokens/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.createChangeStream__post_AccountTokens_change-stream
     * @summary Create a change stream.
     * @request POST:/AccountTokens/change-stream
     */
    accountTokenCreateChangeStreamPostAccountTokensChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/AccountTokens/change-stream`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.createChangeStream__get_AccountTokens_change-stream
     * @summary Create a change stream.
     * @request GET:/AccountTokens/change-stream
     */
    accountTokenCreateChangeStreamGetAccountTokensChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountTokens/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  orders = {
    /**
     * @tags Order
     * @name Order.prototype.__get__owner
     * @summary Fetches belongsTo relation owner.
     * @request GET:/Orders/{id}/owner
     */
    orderPrototypeGetOwner: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Orders/${id}/owner${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__get__transporter
     * @summary Fetches belongsTo relation transporter.
     * @request GET:/Orders/{id}/transporter
     */
    orderPrototypeGetTransporter: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Orders/${id}/transporter${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__findById__packages
     * @summary Find a related item by id for packages.
     * @request GET:/Orders/{id}/packages/{fk}
     */
    orderPrototypeFindByIdPackages: (id: string, fk: string, params?: RequestParams) =>
      this.request<Package, any>(`/Orders/${id}/packages/${fk}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__destroyById__packages
     * @summary Delete a related item by id for packages.
     * @request DELETE:/Orders/{id}/packages/{fk}
     */
    orderPrototypeDestroyByIdPackages: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/packages/${fk}`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__updateById__packages
     * @summary Update a related item by id for packages.
     * @request PUT:/Orders/{id}/packages/{fk}
     */
    orderPrototypeUpdateByIdPackages: (id: string, fk: string, data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Orders/${id}/packages/${fk}`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__findById__conversations
     * @summary Find a related item by id for conversations.
     * @request GET:/Orders/{id}/conversations/{fk}
     */
    orderPrototypeFindByIdConversations: (id: string, fk: string, params?: RequestParams) =>
      this.request<Conversation, any>(`/Orders/${id}/conversations/${fk}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__destroyById__conversations
     * @summary Delete a related item by id for conversations.
     * @request DELETE:/Orders/{id}/conversations/{fk}
     */
    orderPrototypeDestroyByIdConversations: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/conversations/${fk}`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__updateById__conversations
     * @summary Update a related item by id for conversations.
     * @request PUT:/Orders/{id}/conversations/{fk}
     */
    orderPrototypeUpdateByIdConversations: (id: string, fk: string, data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Orders/${id}/conversations/${fk}`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__findById__addresses
     * @summary Find a related item by id for addresses.
     * @request GET:/Orders/{id}/addresses/{fk}
     */
    orderPrototypeFindByIdAddresses: (id: string, fk: string, params?: RequestParams) =>
      this.request<Address, any>(`/Orders/${id}/addresses/${fk}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__destroyById__addresses
     * @summary Delete a related item by id for addresses.
     * @request DELETE:/Orders/{id}/addresses/{fk}
     */
    orderPrototypeDestroyByIdAddresses: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/addresses/${fk}`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__updateById__addresses
     * @summary Update a related item by id for addresses.
     * @request PUT:/Orders/{id}/addresses/{fk}
     */
    orderPrototypeUpdateByIdAddresses: (id: string, fk: string, data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Orders/${id}/addresses/${fk}`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__findById__payments
     * @summary Find a related item by id for payments.
     * @request GET:/Orders/{id}/payments/{fk}
     */
    orderPrototypeFindByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<Payment, any>(`/Orders/${id}/payments/${fk}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__destroyById__payments
     * @summary Delete a related item by id for payments.
     * @request DELETE:/Orders/{id}/payments/{fk}
     */
    orderPrototypeDestroyByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/payments/${fk}`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__updateById__payments
     * @summary Update a related item by id for payments.
     * @request PUT:/Orders/{id}/payments/{fk}
     */
    orderPrototypeUpdateByIdPayments: (id: string, fk: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Orders/${id}/payments/${fk}`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__findById__suggestedRoutes
     * @summary Find a related item by id for suggestedRoutes.
     * @request GET:/Orders/{id}/suggestedRoutes/{fk}
     */
    orderPrototypeFindByIdSuggestedRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<Route, any>(`/Orders/${id}/suggestedRoutes/${fk}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__destroyById__suggestedRoutes
     * @summary Delete a related item by id for suggestedRoutes.
     * @request DELETE:/Orders/{id}/suggestedRoutes/{fk}
     */
    orderPrototypeDestroyByIdSuggestedRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/suggestedRoutes/${fk}`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__updateById__suggestedRoutes
     * @summary Update a related item by id for suggestedRoutes.
     * @request PUT:/Orders/{id}/suggestedRoutes/{fk}
     */
    orderPrototypeUpdateByIdSuggestedRoutes: (id: string, fk: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Orders/${id}/suggestedRoutes/${fk}`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__link__suggestedRoutes
     * @summary Add a related item by id for suggestedRoutes.
     * @request PUT:/Orders/{id}/suggestedRoutes/rel/{fk}
     */
    orderPrototypeLinkSuggestedRoutes: (id: string, fk: string, data: RouteOrderSuggestion, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(`/Orders/${id}/suggestedRoutes/rel/${fk}`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__unlink__suggestedRoutes
     * @summary Remove the suggestedRoutes relation to an item by id.
     * @request DELETE:/Orders/{id}/suggestedRoutes/rel/{fk}
     */
    orderPrototypeUnlinkSuggestedRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/suggestedRoutes/rel/${fk}`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__exists__suggestedRoutes
     * @summary Check the existence of suggestedRoutes relation to an item by id.
     * @request HEAD:/Orders/{id}/suggestedRoutes/rel/{fk}
     */
    orderPrototypeExistsSuggestedRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Orders/${id}/suggestedRoutes/rel/${fk}`, "HEAD", params),

    /**
     * @tags Order
     * @name Order.prototype.__get__packages
     * @summary Queries packages of Order.
     * @request GET:/Orders/{id}/packages
     */
    orderPrototypeGetPackages: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Package[], any>(`/Orders/${id}/packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__create__packages
     * @summary Creates a new instance in packages of this model.
     * @request POST:/Orders/{id}/packages
     */
    orderPrototypeCreatePackages: (id: string, data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Orders/${id}/packages`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__delete__packages
     * @summary Deletes all packages of this model.
     * @request DELETE:/Orders/{id}/packages
     */
    orderPrototypeDeletePackages: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/packages`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__count__packages
     * @summary Counts packages of Order.
     * @request GET:/Orders/{id}/packages/count
     */
    orderPrototypeCountPackages: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Orders/${id}/packages/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__get__conversations
     * @summary Queries conversations of Order.
     * @request GET:/Orders/{id}/conversations
     */
    orderPrototypeGetConversations: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Conversation[], any>(`/Orders/${id}/conversations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__create__conversations
     * @summary Creates a new instance in conversations of this model.
     * @request POST:/Orders/{id}/conversations
     */
    orderPrototypeCreateConversations: (id: string, data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Orders/${id}/conversations`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__delete__conversations
     * @summary Deletes all conversations of this model.
     * @request DELETE:/Orders/{id}/conversations
     */
    orderPrototypeDeleteConversations: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/conversations`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__count__conversations
     * @summary Counts conversations of Order.
     * @request GET:/Orders/{id}/conversations/count
     */
    orderPrototypeCountConversations: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Orders/${id}/conversations/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Order
     * @name Order.prototype.__get__addresses
     * @summary Queries addresses of Order.
     * @request GET:/Orders/{id}/addresses
     */
    orderPrototypeGetAddresses: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Address[], any>(`/Orders/${id}/addresses${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__create__addresses
     * @summary Creates a new instance in addresses of this model.
     * @request POST:/Orders/{id}/addresses
     */
    orderPrototypeCreateAddresses: (id: string, data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Orders/${id}/addresses`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__delete__addresses
     * @summary Deletes all addresses of this model.
     * @request DELETE:/Orders/{id}/addresses
     */
    orderPrototypeDeleteAddresses: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/addresses`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__count__addresses
     * @summary Counts addresses of Order.
     * @request GET:/Orders/{id}/addresses/count
     */
    orderPrototypeCountAddresses: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Orders/${id}/addresses/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Order
     * @name Order.prototype.__get__payments
     * @summary Queries payments of Order.
     * @request GET:/Orders/{id}/payments
     */
    orderPrototypeGetPayments: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Payment[], any>(`/Orders/${id}/payments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__create__payments
     * @summary Creates a new instance in payments of this model.
     * @request POST:/Orders/{id}/payments
     */
    orderPrototypeCreatePayments: (id: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Orders/${id}/payments`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__delete__payments
     * @summary Deletes all payments of this model.
     * @request DELETE:/Orders/{id}/payments
     */
    orderPrototypeDeletePayments: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/payments`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__count__payments
     * @summary Counts payments of Order.
     * @request GET:/Orders/{id}/payments/count
     */
    orderPrototypeCountPayments: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Orders/${id}/payments/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__get__suggestedRoutes
     * @summary Queries suggestedRoutes of Order.
     * @request GET:/Orders/{id}/suggestedRoutes
     */
    orderPrototypeGetSuggestedRoutes: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Route[], any>(`/Orders/${id}/suggestedRoutes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.prototype.__create__suggestedRoutes
     * @summary Creates a new instance in suggestedRoutes of this model.
     * @request POST:/Orders/{id}/suggestedRoutes
     */
    orderPrototypeCreateSuggestedRoutes: (id: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Orders/${id}/suggestedRoutes`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.prototype.__delete__suggestedRoutes
     * @summary Deletes all suggestedRoutes of this model.
     * @request DELETE:/Orders/{id}/suggestedRoutes
     */
    orderPrototypeDeleteSuggestedRoutes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Orders/${id}/suggestedRoutes`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.__count__suggestedRoutes
     * @summary Counts suggestedRoutes of Order.
     * @request GET:/Orders/{id}/suggestedRoutes/count
     */
    orderPrototypeCountSuggestedRoutes: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Orders/${id}/suggestedRoutes/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Order
     * @name Order.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Orders
     */
    orderCreate: (data: Order, params?: RequestParams) => this.request<Order, any>(`/Orders`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Orders
     */
    orderPatchOrCreate: (data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Orders`, "PATCH", params, data),

    /**
     * @tags Order
     * @name Order.replaceOrCreate__put_Orders
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Orders
     */
    orderReplaceOrCreatePutOrders: (data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Orders`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Orders
     */
    orderFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Order[], any>(`/Orders${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.replaceOrCreate__post_Orders_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Orders/replaceOrCreate
     */
    orderReplaceOrCreatePostOrdersReplaceOrCreate: (data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Orders/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Orders/upsertWithWhere
     */
    orderUpsertWithWhere: (data: Order, query?: { where?: string }, params?: RequestParams) =>
      this.request<Order, any>(`/Orders/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.exists__get_Orders_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Orders/{id}/exists
     */
    orderExistsGetOrdersIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Orders/${id}/exists`, "GET", params),

    /**
     * @tags Order
     * @name Order.exists__head_Orders_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Orders/{id}
     */
    orderExistsHeadOrdersId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Orders/${id}`, "HEAD", params),

    /**
     * @tags Order
     * @name Order.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Orders/{id}
     */
    orderFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Order, any>(`/Orders/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.replaceById__put_Orders_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Orders/{id}
     */
    orderReplaceByIdPutOrdersId: (id: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Orders/${id}`, "PUT", params, data),

    /**
     * @tags Order
     * @name Order.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Orders/{id}
     */
    orderDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Orders/${id}`, "DELETE", params),

    /**
     * @tags Order
     * @name Order.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Orders/{id}
     */
    orderPrototypePatchAttributes: (id: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Orders/${id}`, "PATCH", params, data),

    /**
     * @tags Order
     * @name Order.replaceById__post_Orders_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Orders/{id}/replace
     */
    orderReplaceByIdPostOrdersIdReplace: (id: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Orders/${id}/replace`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Orders/findOne
     */
    orderFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Order, any>(`/Orders/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Orders/update
     */
    orderUpdateAll: (data: Order, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Orders/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Orders/count
     */
    orderCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Orders/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.createChangeStream__post_Orders_change-stream
     * @summary Create a change stream.
     * @request POST:/Orders/change-stream
     */
    orderCreateChangeStreamPostOrdersChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Orders/change-stream`, "POST", params, data),

    /**
     * @tags Order
     * @name Order.createChangeStream__get_Orders_change-stream
     * @summary Create a change stream.
     * @request GET:/Orders/change-stream
     */
    orderCreateChangeStreamGetOrdersChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Orders/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Order
     * @name Order.search
     * @summary search order by filter
     * @request GET:/Orders/search
     */
    orderSearch: (
      query?: {
        departurePoint?: string;
        arrivalPoint?: string;
        radius?: number;
        estimatedSize?: string;
        skip?: number;
        limit?: number;
        search_id?: string;
      },
      params?: RequestParams,
    ) => this.request<object, any>(`/Orders/search${this.addQueryParams(query)}`, "GET", params),
  };
  packages = {
    /**
     * @tags Package
     * @name Package.prototype.__get__order
     * @summary Fetches belongsTo relation order.
     * @request GET:/Packages/{id}/order
     */
    packagePrototypeGetOrder: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Order, any>(`/Packages/${id}/order${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Package
     * @name Package.prototype.__get__packageCategory
     * @summary Fetches belongsTo relation packageCategory.
     * @request GET:/Packages/{id}/packageCategory
     */
    packagePrototypeGetPackageCategory: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/Packages/${id}/packageCategory${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Package
     * @name Package.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Packages
     */
    packageCreate: (data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Packages`, "POST", params, data),

    /**
     * @tags Package
     * @name Package.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Packages
     */
    packagePatchOrCreate: (data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Packages`, "PATCH", params, data),

    /**
     * @tags Package
     * @name Package.replaceOrCreate__put_Packages
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Packages
     */
    packageReplaceOrCreatePutPackages: (data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Packages`, "PUT", params, data),

    /**
     * @tags Package
     * @name Package.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Packages
     */
    packageFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Package[], any>(`/Packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Package
     * @name Package.replaceOrCreate__post_Packages_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Packages/replaceOrCreate
     */
    packageReplaceOrCreatePostPackagesReplaceOrCreate: (data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Packages/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Package
     * @name Package.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Packages/upsertWithWhere
     */
    packageUpsertWithWhere: (data: Package, query?: { where?: string }, params?: RequestParams) =>
      this.request<Package, any>(`/Packages/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Package
     * @name Package.exists__get_Packages_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Packages/{id}/exists
     */
    packageExistsGetPackagesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Packages/${id}/exists`, "GET", params),

    /**
     * @tags Package
     * @name Package.exists__head_Packages_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Packages/{id}
     */
    packageExistsHeadPackagesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Packages/${id}`, "HEAD", params),

    /**
     * @tags Package
     * @name Package.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Packages/{id}
     */
    packageFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Package, any>(`/Packages/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Package
     * @name Package.replaceById__put_Packages_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Packages/{id}
     */
    packageReplaceByIdPutPackagesId: (id: string, data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Packages/${id}`, "PUT", params, data),

    /**
     * @tags Package
     * @name Package.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Packages/{id}
     */
    packageDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Packages/${id}`, "DELETE", params),

    /**
     * @tags Package
     * @name Package.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Packages/{id}
     */
    packagePrototypePatchAttributes: (id: string, data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Packages/${id}`, "PATCH", params, data),

    /**
     * @tags Package
     * @name Package.replaceById__post_Packages_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Packages/{id}/replace
     */
    packageReplaceByIdPostPackagesIdReplace: (id: string, data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/Packages/${id}/replace`, "POST", params, data),

    /**
     * @tags Package
     * @name Package.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Packages/findOne
     */
    packageFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Package, any>(`/Packages/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Package
     * @name Package.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Packages/update
     */
    packageUpdateAll: (data: Package, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Packages/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Package
     * @name Package.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Packages/count
     */
    packageCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Packages/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Package
     * @name Package.createChangeStream__post_Packages_change-stream
     * @summary Create a change stream.
     * @request POST:/Packages/change-stream
     */
    packageCreateChangeStreamPostPackagesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Packages/change-stream`, "POST", params, data),

    /**
     * @tags Package
     * @name Package.createChangeStream__get_Packages_change-stream
     * @summary Create a change stream.
     * @request GET:/Packages/change-stream
     */
    packageCreateChangeStreamGetPackagesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Packages/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  packageCategories = {
    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.__findById__packages
     * @summary Find a related item by id for packages.
     * @request GET:/PackageCategories/{id}/packages/{fk}
     */
    packageCategoryPrototypeFindByIdPackages: (id: string, fk: string, params?: RequestParams) =>
      this.request<Package, any>(`/PackageCategories/${id}/packages/${fk}`, "GET", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.__destroyById__packages
     * @summary Delete a related item by id for packages.
     * @request DELETE:/PackageCategories/{id}/packages/{fk}
     */
    packageCategoryPrototypeDestroyByIdPackages: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/PackageCategories/${id}/packages/${fk}`, "DELETE", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.__updateById__packages
     * @summary Update a related item by id for packages.
     * @request PUT:/PackageCategories/{id}/packages/{fk}
     */
    packageCategoryPrototypeUpdateByIdPackages: (id: string, fk: string, data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/PackageCategories/${id}/packages/${fk}`, "PUT", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.__get__packages
     * @summary Queries packages of PackageCategory.
     * @request GET:/PackageCategories/{id}/packages
     */
    packageCategoryPrototypeGetPackages: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Package[], any>(`/PackageCategories/${id}/packages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.__create__packages
     * @summary Creates a new instance in packages of this model.
     * @request POST:/PackageCategories/{id}/packages
     */
    packageCategoryPrototypeCreatePackages: (id: string, data: Package, params?: RequestParams) =>
      this.request<Package, any>(`/PackageCategories/${id}/packages`, "POST", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.__delete__packages
     * @summary Deletes all packages of this model.
     * @request DELETE:/PackageCategories/{id}/packages
     */
    packageCategoryPrototypeDeletePackages: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/PackageCategories/${id}/packages`, "DELETE", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.__count__packages
     * @summary Counts packages of PackageCategory.
     * @request GET:/PackageCategories/{id}/packages/count
     */
    packageCategoryPrototypeCountPackages: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/PackageCategories/${id}/packages/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags PackageCategory
     * @name PackageCategory.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/PackageCategories
     */
    packageCategoryCreate: (data: PackageCategory, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/PackageCategories`, "POST", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/PackageCategories
     */
    packageCategoryPatchOrCreate: (data: PackageCategory, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/PackageCategories`, "PATCH", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.replaceOrCreate__put_PackageCategories
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/PackageCategories
     */
    packageCategoryReplaceOrCreatePutPackageCategories: (data: PackageCategory, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/PackageCategories`, "PUT", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/PackageCategories
     */
    packageCategoryFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PackageCategory[], any>(`/PackageCategories${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.replaceOrCreate__post_PackageCategories_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/PackageCategories/replaceOrCreate
     */
    packageCategoryReplaceOrCreatePostPackageCategoriesReplaceOrCreate: (
      data: PackageCategory,
      params?: RequestParams,
    ) => this.request<PackageCategory, any>(`/PackageCategories/replaceOrCreate`, "POST", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/PackageCategories/upsertWithWhere
     */
    packageCategoryUpsertWithWhere: (data: PackageCategory, query?: { where?: string }, params?: RequestParams) =>
      this.request<PackageCategory, any>(
        `/PackageCategories/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PackageCategory
     * @name PackageCategory.exists__get_PackageCategories_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/PackageCategories/{id}/exists
     */
    packageCategoryExistsGetPackageCategoriesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PackageCategories/${id}/exists`, "GET", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.exists__head_PackageCategories_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/PackageCategories/{id}
     */
    packageCategoryExistsHeadPackageCategoriesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PackageCategories/${id}`, "HEAD", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/PackageCategories/{id}
     */
    packageCategoryFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/PackageCategories/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.replaceById__put_PackageCategories_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/PackageCategories/{id}
     */
    packageCategoryReplaceByIdPutPackageCategoriesId: (id: string, data: PackageCategory, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/PackageCategories/${id}`, "PUT", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/PackageCategories/{id}
     */
    packageCategoryDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/PackageCategories/${id}`, "DELETE", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/PackageCategories/{id}
     */
    packageCategoryPrototypePatchAttributes: (id: string, data: PackageCategory, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/PackageCategories/${id}`, "PATCH", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.replaceById__post_PackageCategories_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/PackageCategories/{id}/replace
     */
    packageCategoryReplaceByIdPostPackageCategoriesIdReplace: (
      id: string,
      data: PackageCategory,
      params?: RequestParams,
    ) => this.request<PackageCategory, any>(`/PackageCategories/${id}/replace`, "POST", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/PackageCategories/findOne
     */
    packageCategoryFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PackageCategory, any>(`/PackageCategories/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/PackageCategories/update
     */
    packageCategoryUpdateAll: (data: PackageCategory, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/PackageCategories/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PackageCategory
     * @name PackageCategory.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/PackageCategories/count
     */
    packageCategoryCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/PackageCategories/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PackageCategory
     * @name PackageCategory.createChangeStream__post_PackageCategories_change-stream
     * @summary Create a change stream.
     * @request POST:/PackageCategories/change-stream
     */
    packageCategoryCreateChangeStreamPostPackageCategoriesChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PackageCategories/change-stream`, "POST", params, data),

    /**
     * @tags PackageCategory
     * @name PackageCategory.createChangeStream__get_PackageCategories_change-stream
     * @summary Create a change stream.
     * @request GET:/PackageCategories/change-stream
     */
    packageCategoryCreateChangeStreamGetPackageCategoriesChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PackageCategories/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  messages = {
    /**
     * @tags Message
     * @name Message.prototype.__get__author
     * @summary Fetches belongsTo relation author.
     * @request GET:/Messages/{id}/author
     */
    messagePrototypeGetAuthor: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Messages/${id}/author${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Message
     * @name Message.prototype.__get__conversation
     * @summary Fetches belongsTo relation conversation.
     * @request GET:/Messages/{id}/conversation
     */
    messagePrototypeGetConversation: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Conversation, any>(`/Messages/${id}/conversation${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Message
     * @name Message.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Messages
     */
    messageCreate: (data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Messages`, "POST", params, data),

    /**
     * @tags Message
     * @name Message.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Messages
     */
    messagePatchOrCreate: (data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Messages`, "PATCH", params, data),

    /**
     * @tags Message
     * @name Message.replaceOrCreate__put_Messages
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Messages
     */
    messageReplaceOrCreatePutMessages: (data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Messages`, "PUT", params, data),

    /**
     * @tags Message
     * @name Message.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Messages
     */
    messageFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Message[], any>(`/Messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Message
     * @name Message.replaceOrCreate__post_Messages_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Messages/replaceOrCreate
     */
    messageReplaceOrCreatePostMessagesReplaceOrCreate: (data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Messages/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Message
     * @name Message.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Messages/upsertWithWhere
     */
    messageUpsertWithWhere: (data: Message, query?: { where?: string }, params?: RequestParams) =>
      this.request<Message, any>(`/Messages/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Message
     * @name Message.exists__get_Messages_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Messages/{id}/exists
     */
    messageExistsGetMessagesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Messages/${id}/exists`, "GET", params),

    /**
     * @tags Message
     * @name Message.exists__head_Messages_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Messages/{id}
     */
    messageExistsHeadMessagesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Messages/${id}`, "HEAD", params),

    /**
     * @tags Message
     * @name Message.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Messages/{id}
     */
    messageFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Message, any>(`/Messages/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Message
     * @name Message.replaceById__put_Messages_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Messages/{id}
     */
    messageReplaceByIdPutMessagesId: (id: string, data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Messages/${id}`, "PUT", params, data),

    /**
     * @tags Message
     * @name Message.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Messages/{id}
     */
    messageDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Messages/${id}`, "DELETE", params),

    /**
     * @tags Message
     * @name Message.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Messages/{id}
     */
    messagePrototypePatchAttributes: (id: string, data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Messages/${id}`, "PATCH", params, data),

    /**
     * @tags Message
     * @name Message.replaceById__post_Messages_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Messages/{id}/replace
     */
    messageReplaceByIdPostMessagesIdReplace: (id: string, data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Messages/${id}/replace`, "POST", params, data),

    /**
     * @tags Message
     * @name Message.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Messages/findOne
     */
    messageFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Message, any>(`/Messages/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Message
     * @name Message.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Messages/update
     */
    messageUpdateAll: (data: Message, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Messages/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Message
     * @name Message.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Messages/count
     */
    messageCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Messages/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Message
     * @name Message.createChangeStream__post_Messages_change-stream
     * @summary Create a change stream.
     * @request POST:/Messages/change-stream
     */
    messageCreateChangeStreamPostMessagesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Messages/change-stream`, "POST", params, data),

    /**
     * @tags Message
     * @name Message.createChangeStream__get_Messages_change-stream
     * @summary Create a change stream.
     * @request GET:/Messages/change-stream
     */
    messageCreateChangeStreamGetMessagesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Messages/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  conversations = {
    /**
     * @tags Conversation
     * @name Conversation.prototype.__findById__messages
     * @summary Find a related item by id for messages.
     * @request GET:/Conversations/{id}/messages/{fk}
     */
    conversationPrototypeFindByIdMessages: (id: string, fk: string, params?: RequestParams) =>
      this.request<Message, any>(`/Conversations/${id}/messages/${fk}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__destroyById__messages
     * @summary Delete a related item by id for messages.
     * @request DELETE:/Conversations/{id}/messages/{fk}
     */
    conversationPrototypeDestroyByIdMessages: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/messages/${fk}`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__updateById__messages
     * @summary Update a related item by id for messages.
     * @request PUT:/Conversations/{id}/messages/{fk}
     */
    conversationPrototypeUpdateByIdMessages: (id: string, fk: string, data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Conversations/${id}/messages/${fk}`, "PUT", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__get__order
     * @summary Fetches belongsTo relation order.
     * @request GET:/Conversations/{id}/order
     */
    conversationPrototypeGetOrder: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Order, any>(`/Conversations/${id}/order${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__findById__accounts
     * @summary Find a related item by id for accounts.
     * @request GET:/Conversations/{id}/accounts/{fk}
     */
    conversationPrototypeFindByIdAccounts: (id: string, fk: string, params?: RequestParams) =>
      this.request<Account, any>(`/Conversations/${id}/accounts/${fk}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__destroyById__accounts
     * @summary Delete a related item by id for accounts.
     * @request DELETE:/Conversations/{id}/accounts/{fk}
     */
    conversationPrototypeDestroyByIdAccounts: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/accounts/${fk}`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__updateById__accounts
     * @summary Update a related item by id for accounts.
     * @request PUT:/Conversations/{id}/accounts/{fk}
     */
    conversationPrototypeUpdateByIdAccounts: (id: string, fk: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Conversations/${id}/accounts/${fk}`, "PUT", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__link__accounts
     * @summary Add a related item by id for accounts.
     * @request PUT:/Conversations/{id}/accounts/rel/{fk}
     */
    conversationPrototypeLinkAccounts: (id: string, fk: string, data: AccountConversation, params?: RequestParams) =>
      this.request<AccountConversation, any>(`/Conversations/${id}/accounts/rel/${fk}`, "PUT", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__unlink__accounts
     * @summary Remove the accounts relation to an item by id.
     * @request DELETE:/Conversations/{id}/accounts/rel/{fk}
     */
    conversationPrototypeUnlinkAccounts: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/accounts/rel/${fk}`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__exists__accounts
     * @summary Check the existence of accounts relation to an item by id.
     * @request HEAD:/Conversations/{id}/accounts/rel/{fk}
     */
    conversationPrototypeExistsAccounts: (id: string, fk: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Conversations/${id}/accounts/rel/${fk}`, "HEAD", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__findById__inAppNotifications
     * @summary Find a related item by id for inAppNotifications.
     * @request GET:/Conversations/{id}/inAppNotifications/{fk}
     */
    conversationPrototypeFindByIdInAppNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/Conversations/${id}/inAppNotifications/${fk}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__destroyById__inAppNotifications
     * @summary Delete a related item by id for inAppNotifications.
     * @request DELETE:/Conversations/{id}/inAppNotifications/{fk}
     */
    conversationPrototypeDestroyByIdInAppNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/inAppNotifications/${fk}`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__updateById__inAppNotifications
     * @summary Update a related item by id for inAppNotifications.
     * @request PUT:/Conversations/{id}/inAppNotifications/{fk}
     */
    conversationPrototypeUpdateByIdInAppNotifications: (
      id: string,
      fk: string,
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/Conversations/${id}/inAppNotifications/${fk}`, "PUT", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__findById__payments
     * @summary Find a related item by id for payments.
     * @request GET:/Conversations/{id}/payments/{fk}
     */
    conversationPrototypeFindByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<Payment, any>(`/Conversations/${id}/payments/${fk}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__destroyById__payments
     * @summary Delete a related item by id for payments.
     * @request DELETE:/Conversations/{id}/payments/{fk}
     */
    conversationPrototypeDestroyByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/payments/${fk}`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__updateById__payments
     * @summary Update a related item by id for payments.
     * @request PUT:/Conversations/{id}/payments/{fk}
     */
    conversationPrototypeUpdateByIdPayments: (id: string, fk: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Conversations/${id}/payments/${fk}`, "PUT", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__get__messages
     * @summary Queries messages of Conversation.
     * @request GET:/Conversations/{id}/messages
     */
    conversationPrototypeGetMessages: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Message[], any>(`/Conversations/${id}/messages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__create__messages
     * @summary Creates a new instance in messages of this model.
     * @request POST:/Conversations/{id}/messages
     */
    conversationPrototypeCreateMessages: (id: string, data: Message, params?: RequestParams) =>
      this.request<Message, any>(`/Conversations/${id}/messages`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__delete__messages
     * @summary Deletes all messages of this model.
     * @request DELETE:/Conversations/{id}/messages
     */
    conversationPrototypeDeleteMessages: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/messages`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__count__messages
     * @summary Counts messages of Conversation.
     * @request GET:/Conversations/{id}/messages/count
     */
    conversationPrototypeCountMessages: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Conversations/${id}/messages/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__get__accounts
     * @summary Queries accounts of Conversation.
     * @request GET:/Conversations/{id}/accounts
     */
    conversationPrototypeGetAccounts: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account[], any>(`/Conversations/${id}/accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__create__accounts
     * @summary Creates a new instance in accounts of this model.
     * @request POST:/Conversations/{id}/accounts
     */
    conversationPrototypeCreateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Conversations/${id}/accounts`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__delete__accounts
     * @summary Deletes all accounts of this model.
     * @request DELETE:/Conversations/{id}/accounts
     */
    conversationPrototypeDeleteAccounts: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/accounts`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__count__accounts
     * @summary Counts accounts of Conversation.
     * @request GET:/Conversations/{id}/accounts/count
     */
    conversationPrototypeCountAccounts: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Conversations/${id}/accounts/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__get__inAppNotifications
     * @summary Queries inAppNotifications of Conversation.
     * @request GET:/Conversations/{id}/inAppNotifications
     */
    conversationPrototypeGetInAppNotifications: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification[], any>(
        `/Conversations/${id}/inAppNotifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__create__inAppNotifications
     * @summary Creates a new instance in inAppNotifications of this model.
     * @request POST:/Conversations/{id}/inAppNotifications
     */
    conversationPrototypeCreateInAppNotifications: (id: string, data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/Conversations/${id}/inAppNotifications`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__delete__inAppNotifications
     * @summary Deletes all inAppNotifications of this model.
     * @request DELETE:/Conversations/{id}/inAppNotifications
     */
    conversationPrototypeDeleteInAppNotifications: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/inAppNotifications`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__count__inAppNotifications
     * @summary Counts inAppNotifications of Conversation.
     * @request GET:/Conversations/{id}/inAppNotifications/count
     */
    conversationPrototypeCountInAppNotifications: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Conversations/${id}/inAppNotifications/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__get__payments
     * @summary Queries payments of Conversation.
     * @request GET:/Conversations/{id}/payments
     */
    conversationPrototypeGetPayments: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Payment[], any>(`/Conversations/${id}/payments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__create__payments
     * @summary Creates a new instance in payments of this model.
     * @request POST:/Conversations/{id}/payments
     */
    conversationPrototypeCreatePayments: (id: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Conversations/${id}/payments`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__delete__payments
     * @summary Deletes all payments of this model.
     * @request DELETE:/Conversations/{id}/payments
     */
    conversationPrototypeDeletePayments: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Conversations/${id}/payments`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.__count__payments
     * @summary Counts payments of Conversation.
     * @request GET:/Conversations/{id}/payments/count
     */
    conversationPrototypeCountPayments: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Conversations/${id}/payments/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Conversation
     * @name Conversation.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Conversations
     */
    conversationCreate: (data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Conversations
     */
    conversationPatchOrCreate: (data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations`, "PATCH", params, data),

    /**
     * @tags Conversation
     * @name Conversation.replaceOrCreate__put_Conversations
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Conversations
     */
    conversationReplaceOrCreatePutConversations: (data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations`, "PUT", params, data),

    /**
     * @tags Conversation
     * @name Conversation.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Conversations
     */
    conversationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Conversation[], any>(`/Conversations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.replaceOrCreate__post_Conversations_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Conversations/replaceOrCreate
     */
    conversationReplaceOrCreatePostConversationsReplaceOrCreate: (data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Conversations/upsertWithWhere
     */
    conversationUpsertWithWhere: (data: Conversation, query?: { where?: string }, params?: RequestParams) =>
      this.request<Conversation, any>(
        `/Conversations/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Conversation
     * @name Conversation.exists__get_Conversations_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Conversations/{id}/exists
     */
    conversationExistsGetConversationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Conversations/${id}/exists`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.exists__head_Conversations_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Conversations/{id}
     */
    conversationExistsHeadConversationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Conversations/${id}`, "HEAD", params),

    /**
     * @tags Conversation
     * @name Conversation.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Conversations/{id}
     */
    conversationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.replaceById__put_Conversations_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Conversations/{id}
     */
    conversationReplaceByIdPutConversationsId: (id: string, data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations/${id}`, "PUT", params, data),

    /**
     * @tags Conversation
     * @name Conversation.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Conversations/{id}
     */
    conversationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Conversations/${id}`, "DELETE", params),

    /**
     * @tags Conversation
     * @name Conversation.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Conversations/{id}
     */
    conversationPrototypePatchAttributes: (id: string, data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations/${id}`, "PATCH", params, data),

    /**
     * @tags Conversation
     * @name Conversation.replaceById__post_Conversations_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Conversations/{id}/replace
     */
    conversationReplaceByIdPostConversationsIdReplace: (id: string, data: Conversation, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations/${id}/replace`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Conversations/findOne
     */
    conversationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Conversation, any>(`/Conversations/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Conversations/update
     */
    conversationUpdateAll: (data: Conversation, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Conversations/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Conversations/count
     */
    conversationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Conversations/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Conversation
     * @name Conversation.createChangeStream__post_Conversations_change-stream
     * @summary Create a change stream.
     * @request POST:/Conversations/change-stream
     */
    conversationCreateChangeStreamPostConversationsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Conversations/change-stream`, "POST", params, data),

    /**
     * @tags Conversation
     * @name Conversation.createChangeStream__get_Conversations_change-stream
     * @summary Create a change stream.
     * @request GET:/Conversations/change-stream
     */
    conversationCreateChangeStreamGetConversationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Conversations/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  paymentRequests = {
    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__get__author
     * @summary Fetches belongsTo relation author.
     * @request GET:/PaymentRequests/{id}/author
     */
    paymentRequestPrototypeGetAuthor: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/PaymentRequests/${id}/author${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__findById__payments
     * @summary Find a related item by id for payments.
     * @request GET:/PaymentRequests/{id}/payments/{fk}
     */
    paymentRequestPrototypeFindByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<Payment, any>(`/PaymentRequests/${id}/payments/${fk}`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__destroyById__payments
     * @summary Delete a related item by id for payments.
     * @request DELETE:/PaymentRequests/{id}/payments/{fk}
     */
    paymentRequestPrototypeDestroyByIdPayments: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/PaymentRequests/${id}/payments/${fk}`, "DELETE", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__updateById__payments
     * @summary Update a related item by id for payments.
     * @request PUT:/PaymentRequests/{id}/payments/{fk}
     */
    paymentRequestPrototypeUpdateByIdPayments: (id: string, fk: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/PaymentRequests/${id}/payments/${fk}`, "PUT", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__get__payments
     * @summary Queries payments of PaymentRequest.
     * @request GET:/PaymentRequests/{id}/payments
     */
    paymentRequestPrototypeGetPayments: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Payment[], any>(`/PaymentRequests/${id}/payments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__create__payments
     * @summary Creates a new instance in payments of this model.
     * @request POST:/PaymentRequests/{id}/payments
     */
    paymentRequestPrototypeCreatePayments: (id: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/PaymentRequests/${id}/payments`, "POST", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__delete__payments
     * @summary Deletes all payments of this model.
     * @request DELETE:/PaymentRequests/{id}/payments
     */
    paymentRequestPrototypeDeletePayments: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/PaymentRequests/${id}/payments`, "DELETE", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.__count__payments
     * @summary Counts payments of PaymentRequest.
     * @request GET:/PaymentRequests/{id}/payments/count
     */
    paymentRequestPrototypeCountPayments: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/PaymentRequests/${id}/payments/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/PaymentRequests
     */
    paymentRequestCreate: (data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests`, "POST", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/PaymentRequests
     */
    paymentRequestPatchOrCreate: (data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests`, "PATCH", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.replaceOrCreate__put_PaymentRequests
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/PaymentRequests
     */
    paymentRequestReplaceOrCreatePutPaymentRequests: (data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests`, "PUT", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/PaymentRequests
     */
    paymentRequestFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentRequest[], any>(`/PaymentRequests${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.replaceOrCreate__post_PaymentRequests_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/PaymentRequests/replaceOrCreate
     */
    paymentRequestReplaceOrCreatePostPaymentRequestsReplaceOrCreate: (data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests/replaceOrCreate`, "POST", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/PaymentRequests/upsertWithWhere
     */
    paymentRequestUpsertWithWhere: (data: PaymentRequest, query?: { where?: string }, params?: RequestParams) =>
      this.request<PaymentRequest, any>(
        `/PaymentRequests/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.exists__get_PaymentRequests_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/PaymentRequests/{id}/exists
     */
    paymentRequestExistsGetPaymentRequestsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PaymentRequests/${id}/exists`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.exists__head_PaymentRequests_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/PaymentRequests/{id}
     */
    paymentRequestExistsHeadPaymentRequestsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PaymentRequests/${id}`, "HEAD", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/PaymentRequests/{id}
     */
    paymentRequestFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.replaceById__put_PaymentRequests_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/PaymentRequests/{id}
     */
    paymentRequestReplaceByIdPutPaymentRequestsId: (id: string, data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests/${id}`, "PUT", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/PaymentRequests/{id}
     */
    paymentRequestDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/PaymentRequests/${id}`, "DELETE", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/PaymentRequests/{id}
     */
    paymentRequestPrototypePatchAttributes: (id: string, data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests/${id}`, "PATCH", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.replaceById__post_PaymentRequests_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/PaymentRequests/{id}/replace
     */
    paymentRequestReplaceByIdPostPaymentRequestsIdReplace: (id: string, data: PaymentRequest, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests/${id}/replace`, "POST", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/PaymentRequests/findOne
     */
    paymentRequestFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/PaymentRequests/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/PaymentRequests/update
     */
    paymentRequestUpdateAll: (data: PaymentRequest, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/PaymentRequests/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/PaymentRequests/count
     */
    paymentRequestCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/PaymentRequests/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.createChangeStream__post_PaymentRequests_change-stream
     * @summary Create a change stream.
     * @request POST:/PaymentRequests/change-stream
     */
    paymentRequestCreateChangeStreamPostPaymentRequestsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PaymentRequests/change-stream`, "POST", params, data),

    /**
     * @tags PaymentRequest
     * @name PaymentRequest.createChangeStream__get_PaymentRequests_change-stream
     * @summary Create a change stream.
     * @request GET:/PaymentRequests/change-stream
     */
    paymentRequestCreateChangeStreamGetPaymentRequestsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PaymentRequests/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  routes = {
    /**
     * @tags Route
     * @name Route.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Routes/{id}/account
     */
    routePrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Routes/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.prototype.__findById__addresses
     * @summary Find a related item by id for addresses.
     * @request GET:/Routes/{id}/addresses/{fk}
     */
    routePrototypeFindByIdAddresses: (id: string, fk: string, params?: RequestParams) =>
      this.request<Address, any>(`/Routes/${id}/addresses/${fk}`, "GET", params),

    /**
     * @tags Route
     * @name Route.prototype.__destroyById__addresses
     * @summary Delete a related item by id for addresses.
     * @request DELETE:/Routes/{id}/addresses/{fk}
     */
    routePrototypeDestroyByIdAddresses: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Routes/${id}/addresses/${fk}`, "DELETE", params),

    /**
     * @tags Route
     * @name Route.prototype.__updateById__addresses
     * @summary Update a related item by id for addresses.
     * @request PUT:/Routes/{id}/addresses/{fk}
     */
    routePrototypeUpdateByIdAddresses: (id: string, fk: string, data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Routes/${id}/addresses/${fk}`, "PUT", params, data),

    /**
     * @tags Route
     * @name Route.prototype.__get__vehicle
     * @summary Fetches belongsTo relation vehicle.
     * @request GET:/Routes/{id}/vehicle
     */
    routePrototypeGetVehicle: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Routes/${id}/vehicle${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.prototype.__findById__suggestedOrders
     * @summary Find a related item by id for suggestedOrders.
     * @request GET:/Routes/{id}/suggestedOrders/{fk}
     */
    routePrototypeFindByIdSuggestedOrders: (id: string, fk: string, params?: RequestParams) =>
      this.request<Order, any>(`/Routes/${id}/suggestedOrders/${fk}`, "GET", params),

    /**
     * @tags Route
     * @name Route.prototype.__destroyById__suggestedOrders
     * @summary Delete a related item by id for suggestedOrders.
     * @request DELETE:/Routes/{id}/suggestedOrders/{fk}
     */
    routePrototypeDestroyByIdSuggestedOrders: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Routes/${id}/suggestedOrders/${fk}`, "DELETE", params),

    /**
     * @tags Route
     * @name Route.prototype.__updateById__suggestedOrders
     * @summary Update a related item by id for suggestedOrders.
     * @request PUT:/Routes/{id}/suggestedOrders/{fk}
     */
    routePrototypeUpdateByIdSuggestedOrders: (id: string, fk: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Routes/${id}/suggestedOrders/${fk}`, "PUT", params, data),

    /**
     * @tags Route
     * @name Route.prototype.__link__suggestedOrders
     * @summary Add a related item by id for suggestedOrders.
     * @request PUT:/Routes/{id}/suggestedOrders/rel/{fk}
     */
    routePrototypeLinkSuggestedOrders: (id: string, fk: string, data: RouteOrderSuggestion, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(`/Routes/${id}/suggestedOrders/rel/${fk}`, "PUT", params, data),

    /**
     * @tags Route
     * @name Route.prototype.__unlink__suggestedOrders
     * @summary Remove the suggestedOrders relation to an item by id.
     * @request DELETE:/Routes/{id}/suggestedOrders/rel/{fk}
     */
    routePrototypeUnlinkSuggestedOrders: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Routes/${id}/suggestedOrders/rel/${fk}`, "DELETE", params),

    /**
     * @tags Route
     * @name Route.prototype.__exists__suggestedOrders
     * @summary Check the existence of suggestedOrders relation to an item by id.
     * @request HEAD:/Routes/{id}/suggestedOrders/rel/{fk}
     */
    routePrototypeExistsSuggestedOrders: (id: string, fk: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Routes/${id}/suggestedOrders/rel/${fk}`, "HEAD", params),

    /**
     * @tags Route
     * @name Route.prototype.__get__addresses
     * @summary Queries addresses of Route.
     * @request GET:/Routes/{id}/addresses
     */
    routePrototypeGetAddresses: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Address[], any>(`/Routes/${id}/addresses${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.prototype.__create__addresses
     * @summary Creates a new instance in addresses of this model.
     * @request POST:/Routes/{id}/addresses
     */
    routePrototypeCreateAddresses: (id: string, data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Routes/${id}/addresses`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.prototype.__delete__addresses
     * @summary Deletes all addresses of this model.
     * @request DELETE:/Routes/{id}/addresses
     */
    routePrototypeDeleteAddresses: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Routes/${id}/addresses`, "DELETE", params),

    /**
     * @tags Route
     * @name Route.prototype.__count__addresses
     * @summary Counts addresses of Route.
     * @request GET:/Routes/{id}/addresses/count
     */
    routePrototypeCountAddresses: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Routes/${id}/addresses/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Route
     * @name Route.prototype.__get__suggestedOrders
     * @summary Queries suggestedOrders of Route.
     * @request GET:/Routes/{id}/suggestedOrders
     */
    routePrototypeGetSuggestedOrders: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Order[], any>(`/Routes/${id}/suggestedOrders${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.prototype.__create__suggestedOrders
     * @summary Creates a new instance in suggestedOrders of this model.
     * @request POST:/Routes/{id}/suggestedOrders
     */
    routePrototypeCreateSuggestedOrders: (id: string, data: Order, params?: RequestParams) =>
      this.request<Order, any>(`/Routes/${id}/suggestedOrders`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.prototype.__delete__suggestedOrders
     * @summary Deletes all suggestedOrders of this model.
     * @request DELETE:/Routes/{id}/suggestedOrders
     */
    routePrototypeDeleteSuggestedOrders: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Routes/${id}/suggestedOrders`, "DELETE", params),

    /**
     * @tags Route
     * @name Route.prototype.__count__suggestedOrders
     * @summary Counts suggestedOrders of Route.
     * @request GET:/Routes/{id}/suggestedOrders/count
     */
    routePrototypeCountSuggestedOrders: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Routes/${id}/suggestedOrders/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Route
     * @name Route.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Routes
     */
    routeCreate: (data: Route, params?: RequestParams) => this.request<Route, any>(`/Routes`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Routes
     */
    routePatchOrCreate: (data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Routes`, "PATCH", params, data),

    /**
     * @tags Route
     * @name Route.replaceOrCreate__put_Routes
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Routes
     */
    routeReplaceOrCreatePutRoutes: (data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Routes`, "PUT", params, data),

    /**
     * @tags Route
     * @name Route.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Routes
     */
    routeFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Route[], any>(`/Routes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.replaceOrCreate__post_Routes_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Routes/replaceOrCreate
     */
    routeReplaceOrCreatePostRoutesReplaceOrCreate: (data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Routes/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Routes/upsertWithWhere
     */
    routeUpsertWithWhere: (data: Route, query?: { where?: string }, params?: RequestParams) =>
      this.request<Route, any>(`/Routes/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.exists__get_Routes_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Routes/{id}/exists
     */
    routeExistsGetRoutesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Routes/${id}/exists`, "GET", params),

    /**
     * @tags Route
     * @name Route.exists__head_Routes_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Routes/{id}
     */
    routeExistsHeadRoutesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Routes/${id}`, "HEAD", params),

    /**
     * @tags Route
     * @name Route.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Routes/{id}
     */
    routeFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Route, any>(`/Routes/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.replaceById__put_Routes_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Routes/{id}
     */
    routeReplaceByIdPutRoutesId: (id: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Routes/${id}`, "PUT", params, data),

    /**
     * @tags Route
     * @name Route.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Routes/{id}
     */
    routeDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Routes/${id}`, "DELETE", params),

    /**
     * @tags Route
     * @name Route.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Routes/{id}
     */
    routePrototypePatchAttributes: (id: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Routes/${id}`, "PATCH", params, data),

    /**
     * @tags Route
     * @name Route.replaceById__post_Routes_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Routes/{id}/replace
     */
    routeReplaceByIdPostRoutesIdReplace: (id: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Routes/${id}/replace`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Routes/findOne
     */
    routeFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Route, any>(`/Routes/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Routes/update
     */
    routeUpdateAll: (data: Route, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Routes/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Routes/count
     */
    routeCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Routes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.createChangeStream__post_Routes_change-stream
     * @summary Create a change stream.
     * @request POST:/Routes/change-stream
     */
    routeCreateChangeStreamPostRoutesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Routes/change-stream`, "POST", params, data),

    /**
     * @tags Route
     * @name Route.createChangeStream__get_Routes_change-stream
     * @summary Create a change stream.
     * @request GET:/Routes/change-stream
     */
    routeCreateChangeStreamGetRoutesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Routes/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Route
     * @name Route.prototype.fetchSuggestedOrders
     * @summary Get suggested orders for specific route
     * @request GET:/Routes/{id}/suggestedOrders/fetch
     */
    routePrototypeFetchSuggestedOrders: (
      id: string,
      query?: { skip?: number; limit?: number; search_id?: string },
      params?: RequestParams,
    ) => this.request<XAny[], any>(`/Routes/${id}/suggestedOrders/fetch${this.addQueryParams(query)}`, "GET", params),
  };
  containers = {
    /**
     * @tags Container
     * @name Container.getContainers
     * @request GET:/Containers
     */
    containerGetContainers: (params?: RequestParams) => this.request<XAny[], any>(`/Containers`, "GET", params),

    /**
     * @tags Container
     * @name Container.createContainer
     * @request POST:/Containers
     */
    containerCreateContainer: (options: object, params?: RequestParams) =>
      this.request<object, any>(`/Containers`, "POST", params, options),

    /**
     * @tags Container
     * @name Container.destroyContainer
     * @request DELETE:/Containers/{container}
     */
    containerDestroyContainer: (container: string, params?: RequestParams) =>
      this.request<{ undefined?: object }, any>(`/Containers/${container}`, "DELETE", params),

    /**
     * @tags Container
     * @name Container.getContainer
     * @request GET:/Containers/{container}
     */
    containerGetContainer: (container: string, params?: RequestParams) =>
      this.request<object, any>(`/Containers/${container}`, "GET", params),

    /**
     * @tags Container
     * @name Container.getFiles
     * @request GET:/Containers/{container}/files
     */
    containerGetFiles: (container: string, params?: RequestParams) =>
      this.request<XAny[], any>(`/Containers/${container}/files`, "GET", params),

    /**
     * @tags Container
     * @name Container.getFile
     * @request GET:/Containers/{container}/files/{file}
     */
    containerGetFile: (container: string, file: string, params?: RequestParams) =>
      this.request<object, any>(`/Containers/${container}/files/${file}`, "GET", params),

    /**
     * @tags Container
     * @name Container.removeFile
     * @request DELETE:/Containers/{container}/files/{file}
     */
    containerRemoveFile: (container: string, file: string, params?: RequestParams) =>
      this.request<{ undefined?: object }, any>(`/Containers/${container}/files/${file}`, "DELETE", params),

    /**
     * @tags Container
     * @name Container.upload
     * @request POST:/Containers/{container}/upload
     */
    containerUpload: (container: string, params?: RequestParams) =>
      this.request<{ result?: object }, any>(`/Containers/${container}/upload`, "POST", params),

    /**
     * @tags Container
     * @name Container.download
     * @request GET:/Containers/{container}/download/{file}
     */
    containerDownload: (container: string, file: string, params?: RequestParams) =>
      this.request<any, any>(`/Containers/${container}/download/${file}`, "GET", params),
  };
  userCredentials = {
    /**
     * @tags UserCredential
     * @name UserCredential.prototype.__get__user
     * @summary Fetches belongsTo relation user.
     * @request GET:/UserCredentials/{id}/user
     */
    userCredentialPrototypeGetUser: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/UserCredentials/${id}/user${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserCredential
     * @name UserCredential.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/UserCredentials
     */
    userCredentialCreate: (data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials`, "POST", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/UserCredentials
     */
    userCredentialPatchOrCreate: (data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials`, "PATCH", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.replaceOrCreate__put_UserCredentials
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/UserCredentials
     */
    userCredentialReplaceOrCreatePutUserCredentials: (data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials`, "PUT", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/UserCredentials
     */
    userCredentialFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserCredential[], any>(`/UserCredentials${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserCredential
     * @name UserCredential.replaceOrCreate__post_UserCredentials_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/UserCredentials/replaceOrCreate
     */
    userCredentialReplaceOrCreatePostUserCredentialsReplaceOrCreate: (data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials/replaceOrCreate`, "POST", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/UserCredentials/upsertWithWhere
     */
    userCredentialUpsertWithWhere: (data: UserCredential, query?: { where?: string }, params?: RequestParams) =>
      this.request<UserCredential, any>(
        `/UserCredentials/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags UserCredential
     * @name UserCredential.exists__get_UserCredentials_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/UserCredentials/{id}/exists
     */
    userCredentialExistsGetUserCredentialsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/UserCredentials/${id}/exists`, "GET", params),

    /**
     * @tags UserCredential
     * @name UserCredential.exists__head_UserCredentials_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/UserCredentials/{id}
     */
    userCredentialExistsHeadUserCredentialsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/UserCredentials/${id}`, "HEAD", params),

    /**
     * @tags UserCredential
     * @name UserCredential.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/UserCredentials/{id}
     */
    userCredentialFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserCredential
     * @name UserCredential.replaceById__put_UserCredentials_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/UserCredentials/{id}
     */
    userCredentialReplaceByIdPutUserCredentialsId: (id: string, data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials/${id}`, "PUT", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/UserCredentials/{id}
     */
    userCredentialDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/UserCredentials/${id}`, "DELETE", params),

    /**
     * @tags UserCredential
     * @name UserCredential.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/UserCredentials/{id}
     */
    userCredentialPrototypePatchAttributes: (id: string, data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials/${id}`, "PATCH", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.replaceById__post_UserCredentials_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/UserCredentials/{id}/replace
     */
    userCredentialReplaceByIdPostUserCredentialsIdReplace: (id: string, data: UserCredential, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials/${id}/replace`, "POST", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/UserCredentials/findOne
     */
    userCredentialFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserCredential, any>(`/UserCredentials/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserCredential
     * @name UserCredential.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/UserCredentials/update
     */
    userCredentialUpdateAll: (data: UserCredential, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/UserCredentials/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags UserCredential
     * @name UserCredential.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/UserCredentials/count
     */
    userCredentialCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/UserCredentials/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserCredential
     * @name UserCredential.createChangeStream__post_UserCredentials_change-stream
     * @summary Create a change stream.
     * @request POST:/UserCredentials/change-stream
     */
    userCredentialCreateChangeStreamPostUserCredentialsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/UserCredentials/change-stream`, "POST", params, data),

    /**
     * @tags UserCredential
     * @name UserCredential.createChangeStream__get_UserCredentials_change-stream
     * @summary Create a change stream.
     * @request GET:/UserCredentials/change-stream
     */
    userCredentialCreateChangeStreamGetUserCredentialsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/UserCredentials/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  userIdentities = {
    /**
     * @tags UserIdentity
     * @name UserIdentity.prototype.__get__user
     * @summary Fetches belongsTo relation user.
     * @request GET:/UserIdentities/{id}/user
     */
    userIdentityPrototypeGetUser: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/UserIdentities/${id}/user${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/UserIdentities
     */
    userIdentityCreate: (data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities`, "POST", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/UserIdentities
     */
    userIdentityPatchOrCreate: (data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities`, "PATCH", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.replaceOrCreate__put_UserIdentities
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/UserIdentities
     */
    userIdentityReplaceOrCreatePutUserIdentities: (data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities`, "PUT", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/UserIdentities
     */
    userIdentityFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserIdentity[], any>(`/UserIdentities${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.replaceOrCreate__post_UserIdentities_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/UserIdentities/replaceOrCreate
     */
    userIdentityReplaceOrCreatePostUserIdentitiesReplaceOrCreate: (data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities/replaceOrCreate`, "POST", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/UserIdentities/upsertWithWhere
     */
    userIdentityUpsertWithWhere: (data: UserIdentity, query?: { where?: string }, params?: RequestParams) =>
      this.request<UserIdentity, any>(
        `/UserIdentities/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags UserIdentity
     * @name UserIdentity.exists__get_UserIdentities_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/UserIdentities/{id}/exists
     */
    userIdentityExistsGetUserIdentitiesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/UserIdentities/${id}/exists`, "GET", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.exists__head_UserIdentities_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/UserIdentities/{id}
     */
    userIdentityExistsHeadUserIdentitiesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/UserIdentities/${id}`, "HEAD", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/UserIdentities/{id}
     */
    userIdentityFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.replaceById__put_UserIdentities_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/UserIdentities/{id}
     */
    userIdentityReplaceByIdPutUserIdentitiesId: (id: string, data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities/${id}`, "PUT", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/UserIdentities/{id}
     */
    userIdentityDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/UserIdentities/${id}`, "DELETE", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/UserIdentities/{id}
     */
    userIdentityPrototypePatchAttributes: (id: string, data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities/${id}`, "PATCH", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.replaceById__post_UserIdentities_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/UserIdentities/{id}/replace
     */
    userIdentityReplaceByIdPostUserIdentitiesIdReplace: (id: string, data: UserIdentity, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities/${id}/replace`, "POST", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/UserIdentities/findOne
     */
    userIdentityFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<UserIdentity, any>(`/UserIdentities/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/UserIdentities/update
     */
    userIdentityUpdateAll: (data: UserIdentity, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/UserIdentities/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags UserIdentity
     * @name UserIdentity.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/UserIdentities/count
     */
    userIdentityCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/UserIdentities/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags UserIdentity
     * @name UserIdentity.createChangeStream__post_UserIdentities_change-stream
     * @summary Create a change stream.
     * @request POST:/UserIdentities/change-stream
     */
    userIdentityCreateChangeStreamPostUserIdentitiesChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/UserIdentities/change-stream`, "POST", params, data),

    /**
     * @tags UserIdentity
     * @name UserIdentity.createChangeStream__get_UserIdentities_change-stream
     * @summary Create a change stream.
     * @request GET:/UserIdentities/change-stream
     */
    userIdentityCreateChangeStreamGetUserIdentitiesChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/UserIdentities/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  addresses = {
    /**
     * @tags Address
     * @name Address.prototype.__get__route
     * @summary Fetches belongsTo relation route.
     * @request GET:/Addresses/{id}/route
     */
    addressPrototypeGetRoute: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Route, any>(`/Addresses/${id}/route${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Address
     * @name Address.prototype.__get__order
     * @summary Fetches belongsTo relation order.
     * @request GET:/Addresses/{id}/order
     */
    addressPrototypeGetOrder: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Order, any>(`/Addresses/${id}/order${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Address
     * @name Address.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Addresses
     */
    addressCreate: (data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses`, "POST", params, data),

    /**
     * @tags Address
     * @name Address.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Addresses
     */
    addressPatchOrCreate: (data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses`, "PATCH", params, data),

    /**
     * @tags Address
     * @name Address.replaceOrCreate__put_Addresses
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Addresses
     */
    addressReplaceOrCreatePutAddresses: (data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses`, "PUT", params, data),

    /**
     * @tags Address
     * @name Address.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Addresses
     */
    addressFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Address[], any>(`/Addresses${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Address
     * @name Address.replaceOrCreate__post_Addresses_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Addresses/replaceOrCreate
     */
    addressReplaceOrCreatePostAddressesReplaceOrCreate: (data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Address
     * @name Address.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Addresses/upsertWithWhere
     */
    addressUpsertWithWhere: (data: Address, query?: { where?: string }, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Address
     * @name Address.exists__get_Addresses_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Addresses/{id}/exists
     */
    addressExistsGetAddressesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Addresses/${id}/exists`, "GET", params),

    /**
     * @tags Address
     * @name Address.exists__head_Addresses_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Addresses/{id}
     */
    addressExistsHeadAddressesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Addresses/${id}`, "HEAD", params),

    /**
     * @tags Address
     * @name Address.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Addresses/{id}
     */
    addressFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Address
     * @name Address.replaceById__put_Addresses_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Addresses/{id}
     */
    addressReplaceByIdPutAddressesId: (id: string, data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses/${id}`, "PUT", params, data),

    /**
     * @tags Address
     * @name Address.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Addresses/{id}
     */
    addressDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Addresses/${id}`, "DELETE", params),

    /**
     * @tags Address
     * @name Address.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Addresses/{id}
     */
    addressPrototypePatchAttributes: (id: string, data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses/${id}`, "PATCH", params, data),

    /**
     * @tags Address
     * @name Address.replaceById__post_Addresses_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Addresses/{id}/replace
     */
    addressReplaceByIdPostAddressesIdReplace: (id: string, data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses/${id}/replace`, "POST", params, data),

    /**
     * @tags Address
     * @name Address.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Addresses/findOne
     */
    addressFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Address, any>(`/Addresses/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Address
     * @name Address.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Addresses/update
     */
    addressUpdateAll: (data: Address, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Addresses/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Address
     * @name Address.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Addresses/count
     */
    addressCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Addresses/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Address
     * @name Address.createChangeStream__post_Addresses_change-stream
     * @summary Create a change stream.
     * @request POST:/Addresses/change-stream
     */
    addressCreateChangeStreamPostAddressesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Addresses/change-stream`, "POST", params, data),

    /**
     * @tags Address
     * @name Address.createChangeStream__get_Addresses_change-stream
     * @summary Create a change stream.
     * @request GET:/Addresses/change-stream
     */
    addressCreateChangeStreamGetAddressesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Addresses/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  vehicles = {
    /**
     * @tags Vehicle
     * @name Vehicle.prototype.__findById__routes
     * @summary Find a related item by id for routes.
     * @request GET:/Vehicles/{id}/routes/{fk}
     */
    vehiclePrototypeFindByIdRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<Route, any>(`/Vehicles/${id}/routes/${fk}`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.prototype.__destroyById__routes
     * @summary Delete a related item by id for routes.
     * @request DELETE:/Vehicles/{id}/routes/{fk}
     */
    vehiclePrototypeDestroyByIdRoutes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Vehicles/${id}/routes/${fk}`, "DELETE", params),

    /**
     * @tags Vehicle
     * @name Vehicle.prototype.__updateById__routes
     * @summary Update a related item by id for routes.
     * @request PUT:/Vehicles/{id}/routes/{fk}
     */
    vehiclePrototypeUpdateByIdRoutes: (id: string, fk: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Vehicles/${id}/routes/${fk}`, "PUT", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.prototype.__get__routes
     * @summary Queries routes of Vehicle.
     * @request GET:/Vehicles/{id}/routes
     */
    vehiclePrototypeGetRoutes: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Route[], any>(`/Vehicles/${id}/routes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.prototype.__create__routes
     * @summary Creates a new instance in routes of this model.
     * @request POST:/Vehicles/{id}/routes
     */
    vehiclePrototypeCreateRoutes: (id: string, data: Route, params?: RequestParams) =>
      this.request<Route, any>(`/Vehicles/${id}/routes`, "POST", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.prototype.__delete__routes
     * @summary Deletes all routes of this model.
     * @request DELETE:/Vehicles/{id}/routes
     */
    vehiclePrototypeDeleteRoutes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Vehicles/${id}/routes`, "DELETE", params),

    /**
     * @tags Vehicle
     * @name Vehicle.prototype.__count__routes
     * @summary Counts routes of Vehicle.
     * @request GET:/Vehicles/{id}/routes/count
     */
    vehiclePrototypeCountRoutes: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Vehicles/${id}/routes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Vehicles
     */
    vehicleCreate: (data: Vehicle, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles`, "POST", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Vehicles
     */
    vehiclePatchOrCreate: (data: Vehicle, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles`, "PATCH", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.replaceOrCreate__put_Vehicles
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Vehicles
     */
    vehicleReplaceOrCreatePutVehicles: (data: Vehicle, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles`, "PUT", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Vehicles
     */
    vehicleFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Vehicle[], any>(`/Vehicles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.replaceOrCreate__post_Vehicles_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Vehicles/replaceOrCreate
     */
    vehicleReplaceOrCreatePostVehiclesReplaceOrCreate: (data: Vehicle, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Vehicles/upsertWithWhere
     */
    vehicleUpsertWithWhere: (data: Vehicle, query?: { where?: string }, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.exists__get_Vehicles_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Vehicles/{id}/exists
     */
    vehicleExistsGetVehiclesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Vehicles/${id}/exists`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.exists__head_Vehicles_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Vehicles/{id}
     */
    vehicleExistsHeadVehiclesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Vehicles/${id}`, "HEAD", params),

    /**
     * @tags Vehicle
     * @name Vehicle.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Vehicles/{id}
     */
    vehicleFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.replaceById__put_Vehicles_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Vehicles/{id}
     */
    vehicleReplaceByIdPutVehiclesId: (id: string, data: Vehicle, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles/${id}`, "PUT", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Vehicles/{id}
     */
    vehicleDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Vehicles/${id}`, "DELETE", params),

    /**
     * @tags Vehicle
     * @name Vehicle.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Vehicles/{id}
     */
    vehiclePrototypePatchAttributes: (id: string, data: Vehicle, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles/${id}`, "PATCH", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.replaceById__post_Vehicles_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Vehicles/{id}/replace
     */
    vehicleReplaceByIdPostVehiclesIdReplace: (id: string, data: Vehicle, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles/${id}/replace`, "POST", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Vehicles/findOne
     */
    vehicleFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Vehicle, any>(`/Vehicles/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Vehicles/update
     */
    vehicleUpdateAll: (data: Vehicle, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Vehicles/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Vehicles/count
     */
    vehicleCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Vehicles/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Vehicle
     * @name Vehicle.createChangeStream__post_Vehicles_change-stream
     * @summary Create a change stream.
     * @request POST:/Vehicles/change-stream
     */
    vehicleCreateChangeStreamPostVehiclesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Vehicles/change-stream`, "POST", params, data),

    /**
     * @tags Vehicle
     * @name Vehicle.createChangeStream__get_Vehicles_change-stream
     * @summary Create a change stream.
     * @request GET:/Vehicles/change-stream
     */
    vehicleCreateChangeStreamGetVehiclesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Vehicles/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  settings = {
    /**
     * @tags Setting
     * @name Setting.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Settings
     */
    settingCreate: (data: Setting, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings`, "POST", params, data),

    /**
     * @tags Setting
     * @name Setting.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Settings
     */
    settingPatchOrCreate: (data: Setting, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings`, "PATCH", params, data),

    /**
     * @tags Setting
     * @name Setting.replaceOrCreate__put_Settings
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Settings
     */
    settingReplaceOrCreatePutSettings: (data: Setting, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings`, "PUT", params, data),

    /**
     * @tags Setting
     * @name Setting.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Settings
     */
    settingFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Setting[], any>(`/Settings${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Setting
     * @name Setting.replaceOrCreate__post_Settings_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Settings/replaceOrCreate
     */
    settingReplaceOrCreatePostSettingsReplaceOrCreate: (data: Setting, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Setting
     * @name Setting.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Settings/upsertWithWhere
     */
    settingUpsertWithWhere: (data: Setting, query?: { where?: string }, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Setting
     * @name Setting.exists__get_Settings_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Settings/{id}/exists
     */
    settingExistsGetSettingsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Settings/${id}/exists`, "GET", params),

    /**
     * @tags Setting
     * @name Setting.exists__head_Settings_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Settings/{id}
     */
    settingExistsHeadSettingsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Settings/${id}`, "HEAD", params),

    /**
     * @tags Setting
     * @name Setting.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Settings/{id}
     */
    settingFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Setting
     * @name Setting.replaceById__put_Settings_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Settings/{id}
     */
    settingReplaceByIdPutSettingsId: (id: string, data: Setting, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings/${id}`, "PUT", params, data),

    /**
     * @tags Setting
     * @name Setting.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Settings/{id}
     */
    settingDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Settings/${id}`, "DELETE", params),

    /**
     * @tags Setting
     * @name Setting.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Settings/{id}
     */
    settingPrototypePatchAttributes: (id: string, data: Setting, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings/${id}`, "PATCH", params, data),

    /**
     * @tags Setting
     * @name Setting.replaceById__post_Settings_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Settings/{id}/replace
     */
    settingReplaceByIdPostSettingsIdReplace: (id: string, data: Setting, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings/${id}/replace`, "POST", params, data),

    /**
     * @tags Setting
     * @name Setting.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Settings/findOne
     */
    settingFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Setting, any>(`/Settings/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Setting
     * @name Setting.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Settings/update
     */
    settingUpdateAll: (data: Setting, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Settings/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Setting
     * @name Setting.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Settings/count
     */
    settingCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Settings/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Setting
     * @name Setting.createChangeStream__post_Settings_change-stream
     * @summary Create a change stream.
     * @request POST:/Settings/change-stream
     */
    settingCreateChangeStreamPostSettingsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Settings/change-stream`, "POST", params, data),

    /**
     * @tags Setting
     * @name Setting.createChangeStream__get_Settings_change-stream
     * @summary Create a change stream.
     * @request GET:/Settings/change-stream
     */
    settingCreateChangeStreamGetSettingsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Settings/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  accountConversations = {
    /**
     * @tags AccountConversation
     * @name AccountConversation.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/AccountConversations/{id}/account
     */
    accountConversationPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/AccountConversations/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountConversation
     * @name AccountConversation.prototype.__get__conversation
     * @summary Fetches belongsTo relation conversation.
     * @request GET:/AccountConversations/{id}/conversation
     */
    accountConversationPrototypeGetConversation: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Conversation, any>(
        `/AccountConversations/${id}/conversation${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags AccountConversation
     * @name AccountConversation.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AccountConversations
     */
    accountConversationCreate: (data: AccountConversation, params?: RequestParams) =>
      this.request<AccountConversation, any>(`/AccountConversations`, "POST", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AccountConversations
     */
    accountConversationPatchOrCreate: (data: AccountConversation, params?: RequestParams) =>
      this.request<AccountConversation, any>(`/AccountConversations`, "PATCH", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.replaceOrCreate__put_AccountConversations
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AccountConversations
     */
    accountConversationReplaceOrCreatePutAccountConversations: (data: AccountConversation, params?: RequestParams) =>
      this.request<AccountConversation, any>(`/AccountConversations`, "PUT", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AccountConversations
     */
    accountConversationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountConversation[], any>(`/AccountConversations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountConversation
     * @name AccountConversation.replaceOrCreate__post_AccountConversations_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AccountConversations/replaceOrCreate
     */
    accountConversationReplaceOrCreatePostAccountConversationsReplaceOrCreate: (
      data: AccountConversation,
      params?: RequestParams,
    ) => this.request<AccountConversation, any>(`/AccountConversations/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AccountConversations/upsertWithWhere
     */
    accountConversationUpsertWithWhere: (
      data: AccountConversation,
      query?: { where?: string },
      params?: RequestParams,
    ) =>
      this.request<AccountConversation, any>(
        `/AccountConversations/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountConversation
     * @name AccountConversation.exists__get_AccountConversations_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AccountConversations/{id}/exists
     */
    accountConversationExistsGetAccountConversationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountConversations/${id}/exists`, "GET", params),

    /**
     * @tags AccountConversation
     * @name AccountConversation.exists__head_AccountConversations_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AccountConversations/{id}
     */
    accountConversationExistsHeadAccountConversationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountConversations/${id}`, "HEAD", params),

    /**
     * @tags AccountConversation
     * @name AccountConversation.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AccountConversations/{id}
     */
    accountConversationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountConversation, any>(`/AccountConversations/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountConversation
     * @name AccountConversation.replaceById__put_AccountConversations_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AccountConversations/{id}
     */
    accountConversationReplaceByIdPutAccountConversationsId: (
      id: string,
      data: AccountConversation,
      params?: RequestParams,
    ) => this.request<AccountConversation, any>(`/AccountConversations/${id}`, "PUT", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AccountConversations/{id}
     */
    accountConversationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AccountConversations/${id}`, "DELETE", params),

    /**
     * @tags AccountConversation
     * @name AccountConversation.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AccountConversations/{id}
     */
    accountConversationPrototypePatchAttributes: (id: string, data: AccountConversation, params?: RequestParams) =>
      this.request<AccountConversation, any>(`/AccountConversations/${id}`, "PATCH", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.replaceById__post_AccountConversations_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AccountConversations/{id}/replace
     */
    accountConversationReplaceByIdPostAccountConversationsIdReplace: (
      id: string,
      data: AccountConversation,
      params?: RequestParams,
    ) => this.request<AccountConversation, any>(`/AccountConversations/${id}/replace`, "POST", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AccountConversations/findOne
     */
    accountConversationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountConversation, any>(
        `/AccountConversations/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags AccountConversation
     * @name AccountConversation.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AccountConversations/update
     */
    accountConversationUpdateAll: (data: AccountConversation, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/AccountConversations/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountConversation
     * @name AccountConversation.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AccountConversations/count
     */
    accountConversationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountConversations/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountConversation
     * @name AccountConversation.createChangeStream__post_AccountConversations_change-stream
     * @summary Create a change stream.
     * @request POST:/AccountConversations/change-stream
     */
    accountConversationCreateChangeStreamPostAccountConversationsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountConversations/change-stream`, "POST", params, data),

    /**
     * @tags AccountConversation
     * @name AccountConversation.createChangeStream__get_AccountConversations_change-stream
     * @summary Create a change stream.
     * @request GET:/AccountConversations/change-stream
     */
    accountConversationCreateChangeStreamGetAccountConversationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountConversations/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  push = {
    /**
     * @tags Push
     * @name Push.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/push
     */
    pushCreate: (data: Push, params?: RequestParams) => this.request<Push, any>(`/push`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/push
     */
    pushPatchOrCreate: (data: Push, params?: RequestParams) => this.request<Push, any>(`/push`, "PATCH", params, data),

    /**
     * @tags Push
     * @name Push.replaceOrCreate__put_push
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/push
     */
    pushReplaceOrCreatePutPush: (data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push`, "PUT", params, data),

    /**
     * @tags Push
     * @name Push.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/push
     */
    pushFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Push[], any>(`/push${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.replaceOrCreate__post_push_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/push/replaceOrCreate
     */
    pushReplaceOrCreatePostPushReplaceOrCreate: (data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/push/upsertWithWhere
     */
    pushUpsertWithWhere: (data: Push, query?: { where?: string }, params?: RequestParams) =>
      this.request<Push, any>(`/push/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.exists__get_push_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/push/{id}/exists
     */
    pushExistsGetPushIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/push/${id}/exists`, "GET", params),

    /**
     * @tags Push
     * @name Push.exists__head_push_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/push/{id}
     */
    pushExistsHeadPushId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/push/${id}`, "HEAD", params),

    /**
     * @tags Push
     * @name Push.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/push/{id}
     */
    pushFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.replaceById__put_push_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/push/{id}
     */
    pushReplaceByIdPutPushId: (id: string, data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}`, "PUT", params, data),

    /**
     * @tags Push
     * @name Push.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/push/{id}
     */
    pushDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/push/${id}`, "DELETE", params),

    /**
     * @tags Push
     * @name Push.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/push/{id}
     */
    pushPrototypePatchAttributes: (id: string, data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}`, "PATCH", params, data),

    /**
     * @tags Push
     * @name Push.replaceById__post_push_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/push/{id}/replace
     */
    pushReplaceByIdPostPushIdReplace: (id: string, data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}/replace`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/push/findOne
     */
    pushFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Push, any>(`/push/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/push/update
     */
    pushUpdateAll: (data: Push, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/push/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/push/count
     */
    pushCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/push/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.createChangeStream__post_push_change-stream
     * @summary Create a change stream.
     * @request POST:/push/change-stream
     */
    pushCreateChangeStreamPostPushChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/push/change-stream`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.createChangeStream__get_push_change-stream
     * @summary Create a change stream.
     * @request GET:/push/change-stream
     */
    pushCreateChangeStreamGetPushChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/push/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  notifications = {
    /**
     * @tags Notification
     * @name Notification.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Notifications
     */
    notificationCreate: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Notifications
     */
    notificationPatchOrCreate: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications`, "PATCH", params, data),

    /**
     * @tags Notification
     * @name Notification.replaceOrCreate__put_Notifications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Notifications
     */
    notificationReplaceOrCreatePutNotifications: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications`, "PUT", params, data),

    /**
     * @tags Notification
     * @name Notification.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Notifications
     */
    notificationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Notification[], any>(`/Notifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.replaceOrCreate__post_Notifications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Notifications/replaceOrCreate
     */
    notificationReplaceOrCreatePostNotificationsReplaceOrCreate: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Notifications/upsertWithWhere
     */
    notificationUpsertWithWhere: (data: Notification, query?: { where?: string }, params?: RequestParams) =>
      this.request<Notification, any>(
        `/Notifications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Notification
     * @name Notification.exists__get_Notifications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Notifications/{id}/exists
     */
    notificationExistsGetNotificationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Notifications/${id}/exists`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.exists__head_Notifications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Notifications/{id}
     */
    notificationExistsHeadNotificationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Notifications/${id}`, "HEAD", params),

    /**
     * @tags Notification
     * @name Notification.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Notifications/{id}
     */
    notificationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.replaceById__put_Notifications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Notifications/{id}
     */
    notificationReplaceByIdPutNotificationsId: (id: string, data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}`, "PUT", params, data),

    /**
     * @tags Notification
     * @name Notification.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Notifications/{id}
     */
    notificationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Notifications/${id}`, "DELETE", params),

    /**
     * @tags Notification
     * @name Notification.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Notifications/{id}
     */
    notificationPrototypePatchAttributes: (id: string, data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}`, "PATCH", params, data),

    /**
     * @tags Notification
     * @name Notification.replaceById__post_Notifications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Notifications/{id}/replace
     */
    notificationReplaceByIdPostNotificationsIdReplace: (id: string, data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}/replace`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Notifications/findOne
     */
    notificationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Notifications/update
     */
    notificationUpdateAll: (data: Notification, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Notifications/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Notifications/count
     */
    notificationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Notifications/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.createChangeStream__post_Notifications_change-stream
     * @summary Create a change stream.
     * @request POST:/Notifications/change-stream
     */
    notificationCreateChangeStreamPostNotificationsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Notifications/change-stream`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.createChangeStream__get_Notifications_change-stream
     * @summary Create a change stream.
     * @request GET:/Notifications/change-stream
     */
    notificationCreateChangeStreamGetNotificationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Notifications/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  applications = {
    /**
     * @tags Application
     * @name Application.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Applications
     */
    applicationCreate: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Applications
     */
    applicationPatchOrCreate: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications`, "PATCH", params, data),

    /**
     * @tags Application
     * @name Application.replaceOrCreate__put_Applications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Applications
     */
    applicationReplaceOrCreatePutApplications: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications`, "PUT", params, data),

    /**
     * @tags Application
     * @name Application.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Applications
     */
    applicationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Application[], any>(`/Applications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.replaceOrCreate__post_Applications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Applications/replaceOrCreate
     */
    applicationReplaceOrCreatePostApplicationsReplaceOrCreate: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Applications/upsertWithWhere
     */
    applicationUpsertWithWhere: (data: Application, query?: { where?: string }, params?: RequestParams) =>
      this.request<Application, any>(
        `/Applications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Application
     * @name Application.exists__get_Applications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Applications/{id}/exists
     */
    applicationExistsGetApplicationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Applications/${id}/exists`, "GET", params),

    /**
     * @tags Application
     * @name Application.exists__head_Applications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Applications/{id}
     */
    applicationExistsHeadApplicationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Applications/${id}`, "HEAD", params),

    /**
     * @tags Application
     * @name Application.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Applications/{id}
     */
    applicationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.replaceById__put_Applications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Applications/{id}
     */
    applicationReplaceByIdPutApplicationsId: (id: string, data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}`, "PUT", params, data),

    /**
     * @tags Application
     * @name Application.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Applications/{id}
     */
    applicationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Applications/${id}`, "DELETE", params),

    /**
     * @tags Application
     * @name Application.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Applications/{id}
     */
    applicationPrototypePatchAttributes: (id: string, data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}`, "PATCH", params, data),

    /**
     * @tags Application
     * @name Application.replaceById__post_Applications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Applications/{id}/replace
     */
    applicationReplaceByIdPostApplicationsIdReplace: (id: string, data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}/replace`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Applications/findOne
     */
    applicationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Applications/update
     */
    applicationUpdateAll: (data: Application, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Applications/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Applications/count
     */
    applicationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Applications/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.createChangeStream__post_Applications_change-stream
     * @summary Create a change stream.
     * @request POST:/Applications/change-stream
     */
    applicationCreateChangeStreamPostApplicationsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Applications/change-stream`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.createChangeStream__get_Applications_change-stream
     * @summary Create a change stream.
     * @request GET:/Applications/change-stream
     */
    applicationCreateChangeStreamGetApplicationsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Applications/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  installations = {
    /**
     * @tags Installation
     * @name Installation.findByApp
     * @summary Find installations by application id
     * @request GET:/Installations/byApp
     */
    installationFindByApp: (
      query?: { deviceType?: string; appId?: string; appVersion?: string },
      params?: RequestParams,
    ) => this.request<object, any>(`/Installations/byApp${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.findByUser
     * @summary Find installations by user id
     * @request GET:/Installations/byUser
     */
    installationFindByUser: (query?: { deviceType?: string; userId?: string }, params?: RequestParams) =>
      this.request<object, any>(`/Installations/byUser${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.findBySubscriptions
     * @summary Find installations by subscriptions
     * @request GET:/Installations/bySubscriptions
     */
    installationFindBySubscriptions: (
      query?: { deviceType?: string; subscriptions?: string },
      params?: RequestParams,
    ) => this.request<object, any>(`/Installations/bySubscriptions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Installations
     */
    installationCreate: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Installations
     */
    installationPatchOrCreate: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations`, "PATCH", params, data),

    /**
     * @tags Installation
     * @name Installation.replaceOrCreate__put_Installations
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Installations
     */
    installationReplaceOrCreatePutInstallations: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations`, "PUT", params, data),

    /**
     * @tags Installation
     * @name Installation.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Installations
     */
    installationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Installation[], any>(`/Installations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.replaceOrCreate__post_Installations_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Installations/replaceOrCreate
     */
    installationReplaceOrCreatePostInstallationsReplaceOrCreate: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Installations/upsertWithWhere
     */
    installationUpsertWithWhere: (data: Installation, query?: { where?: string }, params?: RequestParams) =>
      this.request<Installation, any>(
        `/Installations/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Installation
     * @name Installation.exists__get_Installations_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Installations/{id}/exists
     */
    installationExistsGetInstallationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Installations/${id}/exists`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.exists__head_Installations_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Installations/{id}
     */
    installationExistsHeadInstallationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Installations/${id}`, "HEAD", params),

    /**
     * @tags Installation
     * @name Installation.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Installations/{id}
     */
    installationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.replaceById__put_Installations_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Installations/{id}
     */
    installationReplaceByIdPutInstallationsId: (id: string, data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}`, "PUT", params, data),

    /**
     * @tags Installation
     * @name Installation.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Installations/{id}
     */
    installationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Installations/${id}`, "DELETE", params),

    /**
     * @tags Installation
     * @name Installation.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Installations/{id}
     */
    installationPrototypePatchAttributes: (id: string, data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}`, "PATCH", params, data),

    /**
     * @tags Installation
     * @name Installation.replaceById__post_Installations_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Installations/{id}/replace
     */
    installationReplaceByIdPostInstallationsIdReplace: (id: string, data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}/replace`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Installations/findOne
     */
    installationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Installations/update
     */
    installationUpdateAll: (data: Installation, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Installations/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Installations/count
     */
    installationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Installations/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.createChangeStream__post_Installations_change-stream
     * @summary Create a change stream.
     * @request POST:/Installations/change-stream
     */
    installationCreateChangeStreamPostInstallationsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Installations/change-stream`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.createChangeStream__get_Installations_change-stream
     * @summary Create a change stream.
     * @request GET:/Installations/change-stream
     */
    installationCreateChangeStreamGetInstallationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Installations/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  inAppNotifications = {
    /**
     * @tags InAppNotification
     * @name InAppNotification.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/InAppNotifications/{id}/account
     */
    inAppNotificationPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/InAppNotifications/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.prototype.__get__order
     * @summary Fetches belongsTo relation order.
     * @request GET:/InAppNotifications/{id}/order
     */
    inAppNotificationPrototypeGetOrder: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Order, any>(`/InAppNotifications/${id}/order${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.prototype.__get__route
     * @summary Fetches belongsTo relation route.
     * @request GET:/InAppNotifications/{id}/route
     */
    inAppNotificationPrototypeGetRoute: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Route, any>(`/InAppNotifications/${id}/route${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.prototype.__get__conversation
     * @summary Fetches belongsTo relation conversation.
     * @request GET:/InAppNotifications/{id}/conversation
     */
    inAppNotificationPrototypeGetConversation: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Conversation, any>(
        `/InAppNotifications/${id}/conversation${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags InAppNotification
     * @name InAppNotification.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/InAppNotifications
     */
    inAppNotificationCreate: (data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/InAppNotifications
     */
    inAppNotificationPatchOrCreate: (data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications`, "PATCH", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceOrCreate__put_InAppNotifications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/InAppNotifications
     */
    inAppNotificationReplaceOrCreatePutInAppNotifications: (data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications`, "PUT", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/InAppNotifications
     */
    inAppNotificationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification[], any>(`/InAppNotifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceOrCreate__post_InAppNotifications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/InAppNotifications/replaceOrCreate
     */
    inAppNotificationReplaceOrCreatePostInAppNotificationsReplaceOrCreate: (
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/InAppNotifications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/InAppNotifications/upsertWithWhere
     */
    inAppNotificationUpsertWithWhere: (data: InAppNotification, query?: { where?: string }, params?: RequestParams) =>
      this.request<InAppNotification, any>(
        `/InAppNotifications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags InAppNotification
     * @name InAppNotification.exists__get_InAppNotifications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/InAppNotifications/{id}/exists
     */
    inAppNotificationExistsGetInAppNotificationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/InAppNotifications/${id}/exists`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.exists__head_InAppNotifications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/InAppNotifications/{id}
     */
    inAppNotificationExistsHeadInAppNotificationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/InAppNotifications/${id}`, "HEAD", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/InAppNotifications/{id}
     */
    inAppNotificationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceById__put_InAppNotifications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/InAppNotifications/{id}
     */
    inAppNotificationReplaceByIdPutInAppNotificationsId: (
      id: string,
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/InAppNotifications/${id}`, "PUT", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/InAppNotifications/{id}
     */
    inAppNotificationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/InAppNotifications/${id}`, "DELETE", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/InAppNotifications/{id}
     */
    inAppNotificationPrototypePatchAttributes: (id: string, data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications/${id}`, "PATCH", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceById__post_InAppNotifications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/InAppNotifications/{id}/replace
     */
    inAppNotificationReplaceByIdPostInAppNotificationsIdReplace: (
      id: string,
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/InAppNotifications/${id}/replace`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/InAppNotifications/findOne
     */
    inAppNotificationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/InAppNotifications/update
     */
    inAppNotificationUpdateAll: (data: InAppNotification, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/InAppNotifications/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags InAppNotification
     * @name InAppNotification.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/InAppNotifications/count
     */
    inAppNotificationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/InAppNotifications/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.createChangeStream__post_InAppNotifications_change-stream
     * @summary Create a change stream.
     * @request POST:/InAppNotifications/change-stream
     */
    inAppNotificationCreateChangeStreamPostInAppNotificationsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/InAppNotifications/change-stream`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.createChangeStream__get_InAppNotifications_change-stream
     * @summary Create a change stream.
     * @request GET:/InAppNotifications/change-stream
     */
    inAppNotificationCreateChangeStreamGetInAppNotificationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/InAppNotifications/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  accountReports = {
    /**
     * @tags AccountReport
     * @name AccountReport.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/AccountReports/{id}/account
     */
    accountReportPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/AccountReports/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountReport
     * @name AccountReport.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AccountReports
     */
    accountReportCreate: (data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports`, "POST", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AccountReports
     */
    accountReportPatchOrCreate: (data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports`, "PATCH", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.replaceOrCreate__put_AccountReports
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AccountReports
     */
    accountReportReplaceOrCreatePutAccountReports: (data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports`, "PUT", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AccountReports
     */
    accountReportFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountReport[], any>(`/AccountReports${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountReport
     * @name AccountReport.replaceOrCreate__post_AccountReports_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AccountReports/replaceOrCreate
     */
    accountReportReplaceOrCreatePostAccountReportsReplaceOrCreate: (data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AccountReports/upsertWithWhere
     */
    accountReportUpsertWithWhere: (data: AccountReport, query?: { where?: string }, params?: RequestParams) =>
      this.request<AccountReport, any>(
        `/AccountReports/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountReport
     * @name AccountReport.exists__get_AccountReports_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AccountReports/{id}/exists
     */
    accountReportExistsGetAccountReportsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountReports/${id}/exists`, "GET", params),

    /**
     * @tags AccountReport
     * @name AccountReport.exists__head_AccountReports_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AccountReports/{id}
     */
    accountReportExistsHeadAccountReportsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountReports/${id}`, "HEAD", params),

    /**
     * @tags AccountReport
     * @name AccountReport.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AccountReports/{id}
     */
    accountReportFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountReport
     * @name AccountReport.replaceById__put_AccountReports_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AccountReports/{id}
     */
    accountReportReplaceByIdPutAccountReportsId: (id: string, data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports/${id}`, "PUT", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AccountReports/{id}
     */
    accountReportDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AccountReports/${id}`, "DELETE", params),

    /**
     * @tags AccountReport
     * @name AccountReport.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AccountReports/{id}
     */
    accountReportPrototypePatchAttributes: (id: string, data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports/${id}`, "PATCH", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.replaceById__post_AccountReports_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AccountReports/{id}/replace
     */
    accountReportReplaceByIdPostAccountReportsIdReplace: (id: string, data: AccountReport, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports/${id}/replace`, "POST", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AccountReports/findOne
     */
    accountReportFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountReport, any>(`/AccountReports/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountReport
     * @name AccountReport.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AccountReports/update
     */
    accountReportUpdateAll: (data: AccountReport, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/AccountReports/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountReport
     * @name AccountReport.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AccountReports/count
     */
    accountReportCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountReports/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountReport
     * @name AccountReport.createChangeStream__post_AccountReports_change-stream
     * @summary Create a change stream.
     * @request POST:/AccountReports/change-stream
     */
    accountReportCreateChangeStreamPostAccountReportsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountReports/change-stream`, "POST", params, data),

    /**
     * @tags AccountReport
     * @name AccountReport.createChangeStream__get_AccountReports_change-stream
     * @summary Create a change stream.
     * @request GET:/AccountReports/change-stream
     */
    accountReportCreateChangeStreamGetAccountReportsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountReports/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  payments = {
    /**
     * @tags Payment
     * @name Payment.prototype.__get__order
     * @summary Fetches belongsTo relation order.
     * @request GET:/Payments/{id}/order
     */
    paymentPrototypeGetOrder: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Order, any>(`/Payments/${id}/order${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__get__paymentRequest
     * @summary Fetches belongsTo relation paymentRequest.
     * @request GET:/Payments/{id}/paymentRequest
     */
    paymentPrototypeGetPaymentRequest: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<PaymentRequest, any>(`/Payments/${id}/paymentRequest${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__get__author
     * @summary Fetches belongsTo relation author.
     * @request GET:/Payments/{id}/author
     */
    paymentPrototypeGetAuthor: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Payments/${id}/author${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__get__conversation
     * @summary Fetches belongsTo relation conversation.
     * @request GET:/Payments/{id}/conversation
     */
    paymentPrototypeGetConversation: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Conversation, any>(`/Payments/${id}/conversation${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__findById__paymentDetails
     * @summary Find a related item by id for paymentDetails.
     * @request GET:/Payments/{id}/paymentDetails/{fk}
     */
    paymentPrototypeFindByIdPaymentDetails: (id: string, fk: string, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/Payments/${id}/paymentDetails/${fk}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__destroyById__paymentDetails
     * @summary Delete a related item by id for paymentDetails.
     * @request DELETE:/Payments/{id}/paymentDetails/{fk}
     */
    paymentPrototypeDestroyByIdPaymentDetails: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Payments/${id}/paymentDetails/${fk}`, "DELETE", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__updateById__paymentDetails
     * @summary Update a related item by id for paymentDetails.
     * @request PUT:/Payments/{id}/paymentDetails/{fk}
     */
    paymentPrototypeUpdateByIdPaymentDetails: (id: string, fk: string, data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/Payments/${id}/paymentDetails/${fk}`, "PUT", params, data),

    /**
     * @tags Payment
     * @name Payment.prototype.__get__paymentDetails
     * @summary Queries paymentDetails of Payment.
     * @request GET:/Payments/{id}/paymentDetails
     */
    paymentPrototypeGetPaymentDetails: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentDetail[], any>(`/Payments/${id}/paymentDetails${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__create__paymentDetails
     * @summary Creates a new instance in paymentDetails of this model.
     * @request POST:/Payments/{id}/paymentDetails
     */
    paymentPrototypeCreatePaymentDetails: (id: string, data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/Payments/${id}/paymentDetails`, "POST", params, data),

    /**
     * @tags Payment
     * @name Payment.prototype.__delete__paymentDetails
     * @summary Deletes all paymentDetails of this model.
     * @request DELETE:/Payments/{id}/paymentDetails
     */
    paymentPrototypeDeletePaymentDetails: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Payments/${id}/paymentDetails`, "DELETE", params),

    /**
     * @tags Payment
     * @name Payment.prototype.__count__paymentDetails
     * @summary Counts paymentDetails of Payment.
     * @request GET:/Payments/{id}/paymentDetails/count
     */
    paymentPrototypeCountPaymentDetails: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Payments/${id}/paymentDetails/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Payment
     * @name Payment.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Payments
     */
    paymentCreate: (data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments`, "POST", params, data),

    /**
     * @tags Payment
     * @name Payment.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Payments
     */
    paymentPatchOrCreate: (data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments`, "PATCH", params, data),

    /**
     * @tags Payment
     * @name Payment.replaceOrCreate__put_Payments
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Payments
     */
    paymentReplaceOrCreatePutPayments: (data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments`, "PUT", params, data),

    /**
     * @tags Payment
     * @name Payment.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Payments
     */
    paymentFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Payment[], any>(`/Payments${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.replaceOrCreate__post_Payments_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Payments/replaceOrCreate
     */
    paymentReplaceOrCreatePostPaymentsReplaceOrCreate: (data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Payment
     * @name Payment.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Payments/upsertWithWhere
     */
    paymentUpsertWithWhere: (data: Payment, query?: { where?: string }, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Payment
     * @name Payment.exists__get_Payments_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Payments/{id}/exists
     */
    paymentExistsGetPaymentsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Payments/${id}/exists`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.exists__head_Payments_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Payments/{id}
     */
    paymentExistsHeadPaymentsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Payments/${id}`, "HEAD", params),

    /**
     * @tags Payment
     * @name Payment.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Payments/{id}
     */
    paymentFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.replaceById__put_Payments_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Payments/{id}
     */
    paymentReplaceByIdPutPaymentsId: (id: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments/${id}`, "PUT", params, data),

    /**
     * @tags Payment
     * @name Payment.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Payments/{id}
     */
    paymentDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Payments/${id}`, "DELETE", params),

    /**
     * @tags Payment
     * @name Payment.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Payments/{id}
     */
    paymentPrototypePatchAttributes: (id: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments/${id}`, "PATCH", params, data),

    /**
     * @tags Payment
     * @name Payment.replaceById__post_Payments_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Payments/{id}/replace
     */
    paymentReplaceByIdPostPaymentsIdReplace: (id: string, data: Payment, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments/${id}/replace`, "POST", params, data),

    /**
     * @tags Payment
     * @name Payment.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Payments/findOne
     */
    paymentFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Payment, any>(`/Payments/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Payments/update
     */
    paymentUpdateAll: (data: Payment, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Payments/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Payment
     * @name Payment.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Payments/count
     */
    paymentCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Payments/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Payment
     * @name Payment.createChangeStream__post_Payments_change-stream
     * @summary Create a change stream.
     * @request POST:/Payments/change-stream
     */
    paymentCreateChangeStreamPostPaymentsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Payments/change-stream`, "POST", params, data),

    /**
     * @tags Payment
     * @name Payment.createChangeStream__get_Payments_change-stream
     * @summary Create a change stream.
     * @request GET:/Payments/change-stream
     */
    paymentCreateChangeStreamGetPaymentsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Payments/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  routeOrderSuggestions = {
    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.prototype.__get__route
     * @summary Fetches belongsTo relation route.
     * @request GET:/RouteOrderSuggestions/{id}/route
     */
    routeOrderSuggestionPrototypeGetRoute: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Route, any>(`/RouteOrderSuggestions/${id}/route${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.prototype.__get__order
     * @summary Fetches belongsTo relation order.
     * @request GET:/RouteOrderSuggestions/{id}/order
     */
    routeOrderSuggestionPrototypeGetOrder: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Order, any>(`/RouteOrderSuggestions/${id}/order${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/RouteOrderSuggestions
     */
    routeOrderSuggestionCreate: (data: RouteOrderSuggestion, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(`/RouteOrderSuggestions`, "POST", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/RouteOrderSuggestions
     */
    routeOrderSuggestionPatchOrCreate: (data: RouteOrderSuggestion, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(`/RouteOrderSuggestions`, "PATCH", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.replaceOrCreate__put_RouteOrderSuggestions
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/RouteOrderSuggestions
     */
    routeOrderSuggestionReplaceOrCreatePutRouteOrderSuggestions: (data: RouteOrderSuggestion, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(`/RouteOrderSuggestions`, "PUT", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/RouteOrderSuggestions
     */
    routeOrderSuggestionFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RouteOrderSuggestion[], any>(`/RouteOrderSuggestions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.replaceOrCreate__post_RouteOrderSuggestions_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/RouteOrderSuggestions/replaceOrCreate
     */
    routeOrderSuggestionReplaceOrCreatePostRouteOrderSuggestionsReplaceOrCreate: (
      data: RouteOrderSuggestion,
      params?: RequestParams,
    ) => this.request<RouteOrderSuggestion, any>(`/RouteOrderSuggestions/replaceOrCreate`, "POST", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/RouteOrderSuggestions/upsertWithWhere
     */
    routeOrderSuggestionUpsertWithWhere: (
      data: RouteOrderSuggestion,
      query?: { where?: string },
      params?: RequestParams,
    ) =>
      this.request<RouteOrderSuggestion, any>(
        `/RouteOrderSuggestions/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.exists__get_RouteOrderSuggestions_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/RouteOrderSuggestions/{id}/exists
     */
    routeOrderSuggestionExistsGetRouteOrderSuggestionsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RouteOrderSuggestions/${id}/exists`, "GET", params),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.exists__head_RouteOrderSuggestions_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/RouteOrderSuggestions/{id}
     */
    routeOrderSuggestionExistsHeadRouteOrderSuggestionsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RouteOrderSuggestions/${id}`, "HEAD", params),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/RouteOrderSuggestions/{id}
     */
    routeOrderSuggestionFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(
        `/RouteOrderSuggestions/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.replaceById__put_RouteOrderSuggestions_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/RouteOrderSuggestions/{id}
     */
    routeOrderSuggestionReplaceByIdPutRouteOrderSuggestionsId: (
      id: string,
      data: RouteOrderSuggestion,
      params?: RequestParams,
    ) => this.request<RouteOrderSuggestion, any>(`/RouteOrderSuggestions/${id}`, "PUT", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/RouteOrderSuggestions/{id}
     */
    routeOrderSuggestionDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/RouteOrderSuggestions/${id}`, "DELETE", params),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/RouteOrderSuggestions/{id}
     */
    routeOrderSuggestionPrototypePatchAttributes: (id: string, data: RouteOrderSuggestion, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(`/RouteOrderSuggestions/${id}`, "PATCH", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.replaceById__post_RouteOrderSuggestions_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/RouteOrderSuggestions/{id}/replace
     */
    routeOrderSuggestionReplaceByIdPostRouteOrderSuggestionsIdReplace: (
      id: string,
      data: RouteOrderSuggestion,
      params?: RequestParams,
    ) => this.request<RouteOrderSuggestion, any>(`/RouteOrderSuggestions/${id}/replace`, "POST", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/RouteOrderSuggestions/findOne
     */
    routeOrderSuggestionFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RouteOrderSuggestion, any>(
        `/RouteOrderSuggestions/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/RouteOrderSuggestions/update
     */
    routeOrderSuggestionUpdateAll: (data: RouteOrderSuggestion, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/RouteOrderSuggestions/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/RouteOrderSuggestions/count
     */
    routeOrderSuggestionCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/RouteOrderSuggestions/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.createChangeStream__post_RouteOrderSuggestions_change-stream
     * @summary Create a change stream.
     * @request POST:/RouteOrderSuggestions/change-stream
     */
    routeOrderSuggestionCreateChangeStreamPostRouteOrderSuggestionsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/RouteOrderSuggestions/change-stream`, "POST", params, data),

    /**
     * @tags RouteOrderSuggestion
     * @name RouteOrderSuggestion.createChangeStream__get_RouteOrderSuggestions_change-stream
     * @summary Create a change stream.
     * @request GET:/RouteOrderSuggestions/change-stream
     */
    routeOrderSuggestionCreateChangeStreamGetRouteOrderSuggestionsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/RouteOrderSuggestions/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  paymentDetails = {
    /**
     * @tags PaymentDetail
     * @name PaymentDetail.prototype.__get__payment
     * @summary Fetches belongsTo relation payment.
     * @request GET:/PaymentDetails/{id}/payment
     */
    paymentDetailPrototypeGetPayment: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Payment, any>(`/PaymentDetails/${id}/payment${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.prototype.__get__owner
     * @summary Fetches belongsTo relation owner.
     * @request GET:/PaymentDetails/{id}/owner
     */
    paymentDetailPrototypeGetOwner: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/PaymentDetails/${id}/owner${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/PaymentDetails
     */
    paymentDetailCreate: (data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails`, "POST", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/PaymentDetails
     */
    paymentDetailPatchOrCreate: (data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails`, "PATCH", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.replaceOrCreate__put_PaymentDetails
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/PaymentDetails
     */
    paymentDetailReplaceOrCreatePutPaymentDetails: (data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails`, "PUT", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/PaymentDetails
     */
    paymentDetailFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentDetail[], any>(`/PaymentDetails${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.replaceOrCreate__post_PaymentDetails_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/PaymentDetails/replaceOrCreate
     */
    paymentDetailReplaceOrCreatePostPaymentDetailsReplaceOrCreate: (data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails/replaceOrCreate`, "POST", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/PaymentDetails/upsertWithWhere
     */
    paymentDetailUpsertWithWhere: (data: PaymentDetail, query?: { where?: string }, params?: RequestParams) =>
      this.request<PaymentDetail, any>(
        `/PaymentDetails/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.exists__get_PaymentDetails_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/PaymentDetails/{id}/exists
     */
    paymentDetailExistsGetPaymentDetailsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PaymentDetails/${id}/exists`, "GET", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.exists__head_PaymentDetails_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/PaymentDetails/{id}
     */
    paymentDetailExistsHeadPaymentDetailsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PaymentDetails/${id}`, "HEAD", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/PaymentDetails/{id}
     */
    paymentDetailFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.replaceById__put_PaymentDetails_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/PaymentDetails/{id}
     */
    paymentDetailReplaceByIdPutPaymentDetailsId: (id: string, data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails/${id}`, "PUT", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/PaymentDetails/{id}
     */
    paymentDetailDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/PaymentDetails/${id}`, "DELETE", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/PaymentDetails/{id}
     */
    paymentDetailPrototypePatchAttributes: (id: string, data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails/${id}`, "PATCH", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.replaceById__post_PaymentDetails_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/PaymentDetails/{id}/replace
     */
    paymentDetailReplaceByIdPostPaymentDetailsIdReplace: (id: string, data: PaymentDetail, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails/${id}/replace`, "POST", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/PaymentDetails/findOne
     */
    paymentDetailFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PaymentDetail, any>(`/PaymentDetails/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/PaymentDetails/update
     */
    paymentDetailUpdateAll: (data: PaymentDetail, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/PaymentDetails/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/PaymentDetails/count
     */
    paymentDetailCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/PaymentDetails/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.createChangeStream__post_PaymentDetails_change-stream
     * @summary Create a change stream.
     * @request POST:/PaymentDetails/change-stream
     */
    paymentDetailCreateChangeStreamPostPaymentDetailsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PaymentDetails/change-stream`, "POST", params, data),

    /**
     * @tags PaymentDetail
     * @name PaymentDetail.createChangeStream__get_PaymentDetails_change-stream
     * @summary Create a change stream.
     * @request GET:/PaymentDetails/change-stream
     */
    paymentDetailCreateChangeStreamGetPaymentDetailsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PaymentDetails/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  contacts = {
    /**
     * @tags Contact
     * @name Contact.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Contacts
     */
    contactCreate: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Contacts
     */
    contactPatchOrCreate: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts`, "PATCH", params, data),

    /**
     * @tags Contact
     * @name Contact.replaceOrCreate__put_Contacts
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Contacts
     */
    contactReplaceOrCreatePutContacts: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts`, "PUT", params, data),

    /**
     * @tags Contact
     * @name Contact.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Contacts
     */
    contactFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact[], any>(`/Contacts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.replaceOrCreate__post_Contacts_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Contacts/replaceOrCreate
     */
    contactReplaceOrCreatePostContactsReplaceOrCreate: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Contacts/upsertWithWhere
     */
    contactUpsertWithWhere: (data: Contact, query?: { where?: string }, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.exists__get_Contacts_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Contacts/{id}/exists
     */
    contactExistsGetContactsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Contacts/${id}/exists`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.exists__head_Contacts_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Contacts/{id}
     */
    contactExistsHeadContactsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Contacts/${id}`, "HEAD", params),

    /**
     * @tags Contact
     * @name Contact.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Contacts/{id}
     */
    contactFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.replaceById__put_Contacts_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Contacts/{id}
     */
    contactReplaceByIdPutContactsId: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}`, "PUT", params, data),

    /**
     * @tags Contact
     * @name Contact.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Contacts/{id}
     */
    contactDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Contacts/${id}`, "DELETE", params),

    /**
     * @tags Contact
     * @name Contact.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Contacts/{id}
     */
    contactPrototypePatchAttributes: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}`, "PATCH", params, data),

    /**
     * @tags Contact
     * @name Contact.replaceById__post_Contacts_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Contacts/{id}/replace
     */
    contactReplaceByIdPostContactsIdReplace: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}/replace`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Contacts/findOne
     */
    contactFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Contacts/update
     */
    contactUpdateAll: (data: Contact, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Contacts/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Contacts/count
     */
    contactCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Contacts/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.createChangeStream__post_Contacts_change-stream
     * @summary Create a change stream.
     * @request POST:/Contacts/change-stream
     */
    contactCreateChangeStreamPostContactsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Contacts/change-stream`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.createChangeStream__get_Contacts_change-stream
     * @summary Create a change stream.
     * @request GET:/Contacts/change-stream
     */
    contactCreateChangeStreamGetContactsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Contacts/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  accountStatistics = {
    /**
     * @tags AccountStatistics
     * @name AccountStatistics.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/AccountStatistics/{id}/account
     */
    accountStatisticsPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/AccountStatistics/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AccountStatistics
     */
    accountStatisticsCreate: (data: AccountStatistics, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/AccountStatistics`, "POST", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AccountStatistics
     */
    accountStatisticsPatchOrCreate: (data: AccountStatistics, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/AccountStatistics`, "PATCH", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.replaceOrCreate__put_AccountStatistics
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AccountStatistics
     */
    accountStatisticsReplaceOrCreatePutAccountStatistics: (data: AccountStatistics, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/AccountStatistics`, "PUT", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AccountStatistics
     */
    accountStatisticsFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountStatistics[], any>(`/AccountStatistics${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.replaceOrCreate__post_AccountStatistics_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AccountStatistics/replaceOrCreate
     */
    accountStatisticsReplaceOrCreatePostAccountStatisticsReplaceOrCreate: (
      data: AccountStatistics,
      params?: RequestParams,
    ) => this.request<AccountStatistics, any>(`/AccountStatistics/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AccountStatistics/upsertWithWhere
     */
    accountStatisticsUpsertWithWhere: (data: AccountStatistics, query?: { where?: string }, params?: RequestParams) =>
      this.request<AccountStatistics, any>(
        `/AccountStatistics/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.exists__get_AccountStatistics_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AccountStatistics/{id}/exists
     */
    accountStatisticsExistsGetAccountStatisticsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountStatistics/${id}/exists`, "GET", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.exists__head_AccountStatistics_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AccountStatistics/{id}
     */
    accountStatisticsExistsHeadAccountStatisticsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountStatistics/${id}`, "HEAD", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AccountStatistics/{id}
     */
    accountStatisticsFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/AccountStatistics/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.replaceById__put_AccountStatistics_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AccountStatistics/{id}
     */
    accountStatisticsReplaceByIdPutAccountStatisticsId: (id: string, data: AccountStatistics, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/AccountStatistics/${id}`, "PUT", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AccountStatistics/{id}
     */
    accountStatisticsDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AccountStatistics/${id}`, "DELETE", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AccountStatistics/{id}
     */
    accountStatisticsPrototypePatchAttributes: (id: string, data: AccountStatistics, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/AccountStatistics/${id}`, "PATCH", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.replaceById__post_AccountStatistics_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AccountStatistics/{id}/replace
     */
    accountStatisticsReplaceByIdPostAccountStatisticsIdReplace: (
      id: string,
      data: AccountStatistics,
      params?: RequestParams,
    ) => this.request<AccountStatistics, any>(`/AccountStatistics/${id}/replace`, "POST", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AccountStatistics/findOne
     */
    accountStatisticsFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountStatistics, any>(`/AccountStatistics/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AccountStatistics/update
     */
    accountStatisticsUpdateAll: (data: AccountStatistics, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/AccountStatistics/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AccountStatistics/count
     */
    accountStatisticsCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountStatistics/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.createChangeStream__post_AccountStatistics_change-stream
     * @summary Create a change stream.
     * @request POST:/AccountStatistics/change-stream
     */
    accountStatisticsCreateChangeStreamPostAccountStatisticsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountStatistics/change-stream`, "POST", params, data),

    /**
     * @tags AccountStatistics
     * @name AccountStatistics.createChangeStream__get_AccountStatistics_change-stream
     * @summary Create a change stream.
     * @request GET:/AccountStatistics/change-stream
     */
    accountStatisticsCreateChangeStreamGetAccountStatisticsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountStatistics/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  systemStatistics = {
    /**
     * @tags SystemStatistics
     * @name SystemStatistics.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/SystemStatistics
     */
    systemStatisticsCreate: (data: SystemStatistics, params?: RequestParams) =>
      this.request<SystemStatistics, any>(`/SystemStatistics`, "POST", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/SystemStatistics
     */
    systemStatisticsPatchOrCreate: (data: SystemStatistics, params?: RequestParams) =>
      this.request<SystemStatistics, any>(`/SystemStatistics`, "PATCH", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.replaceOrCreate__put_SystemStatistics
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/SystemStatistics
     */
    systemStatisticsReplaceOrCreatePutSystemStatistics: (data: SystemStatistics, params?: RequestParams) =>
      this.request<SystemStatistics, any>(`/SystemStatistics`, "PUT", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/SystemStatistics
     */
    systemStatisticsFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<SystemStatistics[], any>(`/SystemStatistics${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.replaceOrCreate__post_SystemStatistics_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/SystemStatistics/replaceOrCreate
     */
    systemStatisticsReplaceOrCreatePostSystemStatisticsReplaceOrCreate: (
      data: SystemStatistics,
      params?: RequestParams,
    ) => this.request<SystemStatistics, any>(`/SystemStatistics/replaceOrCreate`, "POST", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/SystemStatistics/upsertWithWhere
     */
    systemStatisticsUpsertWithWhere: (data: SystemStatistics, query?: { where?: string }, params?: RequestParams) =>
      this.request<SystemStatistics, any>(
        `/SystemStatistics/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.exists__get_SystemStatistics_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/SystemStatistics/{id}/exists
     */
    systemStatisticsExistsGetSystemStatisticsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/SystemStatistics/${id}/exists`, "GET", params),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.exists__head_SystemStatistics_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/SystemStatistics/{id}
     */
    systemStatisticsExistsHeadSystemStatisticsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/SystemStatistics/${id}`, "HEAD", params),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/SystemStatistics/{id}
     */
    systemStatisticsFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<SystemStatistics, any>(`/SystemStatistics/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.replaceById__put_SystemStatistics_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/SystemStatistics/{id}
     */
    systemStatisticsReplaceByIdPutSystemStatisticsId: (id: string, data: SystemStatistics, params?: RequestParams) =>
      this.request<SystemStatistics, any>(`/SystemStatistics/${id}`, "PUT", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/SystemStatistics/{id}
     */
    systemStatisticsDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/SystemStatistics/${id}`, "DELETE", params),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/SystemStatistics/{id}
     */
    systemStatisticsPrototypePatchAttributes: (id: string, data: SystemStatistics, params?: RequestParams) =>
      this.request<SystemStatistics, any>(`/SystemStatistics/${id}`, "PATCH", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.replaceById__post_SystemStatistics_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/SystemStatistics/{id}/replace
     */
    systemStatisticsReplaceByIdPostSystemStatisticsIdReplace: (
      id: string,
      data: SystemStatistics,
      params?: RequestParams,
    ) => this.request<SystemStatistics, any>(`/SystemStatistics/${id}/replace`, "POST", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/SystemStatistics/findOne
     */
    systemStatisticsFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<SystemStatistics, any>(`/SystemStatistics/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/SystemStatistics/update
     */
    systemStatisticsUpdateAll: (data: SystemStatistics, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/SystemStatistics/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/SystemStatistics/count
     */
    systemStatisticsCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/SystemStatistics/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.createChangeStream__post_SystemStatistics_change-stream
     * @summary Create a change stream.
     * @request POST:/SystemStatistics/change-stream
     */
    systemStatisticsCreateChangeStreamPostSystemStatisticsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/SystemStatistics/change-stream`, "POST", params, data),

    /**
     * @tags SystemStatistics
     * @name SystemStatistics.createChangeStream__get_SystemStatistics_change-stream
     * @summary Create a change stream.
     * @request GET:/SystemStatistics/change-stream
     */
    systemStatisticsCreateChangeStreamGetSystemStatisticsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/SystemStatistics/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
}
