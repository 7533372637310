interface GeoPoint {
  'lat'?: number;
  'lng'?: number;
}

export enum AccountLanguage {
  FRENCH = 'fr',
  ENGLISH = 'en',
  GERMAN = 'de'
}

export enum StripeCustomerLocale {
  FRENCH = 'fr',
  ENGLISH = 'en',
  GERMAN = 'de'
}

export enum AccountGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export enum AccountType {
  INDIVIDUAL = 'INDIVIDUAL',
  PROFESSIONAL = 'PROFESSIONAL',
  ENTERPRISE = 'ENTERPRISE',
  MANAGER = 'MANAGER'
}

export enum AccountStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum AccountDocumentStatus {
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED'
}

export enum PackageEstimatedSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL'
}

export enum DesiredPackageEstimatedSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL'
}

export enum VehicleDefaultSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL'
}

export enum OrderStatus {
  ONLINE = 'ONLINE',
  CONFIRMED = 'CONFIRMED',
  PAID = 'PAID',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
  REFUSED = 'REFUSED',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED'
}

export enum OrderType {
  ROOM = 'ROOM',
  PACKAGE = 'PACKAGE'

}

export enum MessageProposalStatus {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED'
}

export enum ConversationStatus {
  OPEN = 'OPEN',
  CHARGING = 'CHARGING',
  CHARGED = 'CHARGED',
  CLOSED = 'CLOSED'
}

export enum PaymentRequestStatus {
  ONLINE = 'ONLINE',
  RESOLVED = 'RESOLVED'
}

export enum AddressType {
  // For Order
  ORDER_SENDER_RELATIVE_ADDR = 'ORDER_SENDER_RELATIVE_ADDR',
  ORDER_SENDER_DETAILED_ADDR = 'ORDER_SENDER_DETAILED_ADDR',
  ORDER_RECEIVER_RELATIVE_ADDR = 'ORDER_RECEIVER_RELATIVE_ADDR',
  ORDER_RECEIVER_DETAILED_ADDR = 'ORDER_RECEIVER_DETAILED_ADDR',
  // For Route
  ROUTE_START_POINT = 'ROUTE_START_POINT',
  ROUTE_END_POINT = 'ROUTE_END_POINT',
  ROUTE_POINT = 'ROUTE_POINT'
}

export enum RouteStatus {
  ONLINE = 'ONLINE',
  PAUSED = 'PAUSED',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED'
}

export enum TwilioVerificationLocale {
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de'
}

export enum ApplicationLanguage {
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de'
}

export interface AddressDisplayData {
  postal_code?: string
  location?: GeoPoint

  [key: string]: {
    full_display_string?: string,
    country_name?: string,
    administrative_area_level_1?: string,
    administrative_area_level_2?: string,
  } | any,
};

export enum PaymentStatus {
  WAITING = 'WAITING',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED'
}

export enum PaymentMethod {
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
  VIREMENT = 'VIREMENT'
}

export enum NotificationType {
  MESSAGE = 'MESSAGE',
  ORDER = 'ORDER'
}

export enum AccountStatisticsType {
  INCOME = 'INCOME',
  OUTCOME = 'OUTCOME',
  CREATED_ORDER = 'CREATED_ORDER',
  DELIVERED_ORDER = 'DELIVERED_ORDER',
  UNRESOLVED_PAYMENT_REQUEST_AMOUNT = 'UNRESOLVED_PAYMENT_REQUEST_AMOUNT'
}

export enum AccountStatisticsDurationType {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  ALL = 'ALL'
}

export enum SystemStatisticsType {
  NEW_USER = 'NEW_USER',
  NEW_ORDER = 'NEW_ORDER',
  ECOPOST_EARNING_AMOUNT = 'ECOPOST_EARNING_AMOUNT'
}

export enum SystemStatisticsDurationType {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  ALL = 'ALL'
}
