import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  Show,
  SimpleShowLayout,
  RichTextField,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { PaymentRequestStatus } from "../../react-admin-loopback/api/shared-constants";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import HideAction from "../../components/hide-action"
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
const ShowAmount = ({ record }: any) => <div>{record.amount} €</div>;

const ActionButton = ({ record }: any) => {
  return record.status === PaymentRequestStatus.ONLINE ? (
    <ResolveButton record={record} />
  ) : (
    <ViewDetail record={record} />
  );
};

export const PaymentList = (props: any) => {
  const handleClickOpen = () => {};
  const translate = useTranslate();
  return (
    <div>
      <List {...props} actions={<HideAction/>}>
        <Datagrid onClick={handleClickOpen} {...props}>
          <ReferenceField source="authorId" label="back-office.author" reference="Accounts">
            <TextField source="lastName" />
          </ReferenceField>
          <ShowAmount label="back-office.request-amount"></ShowAmount>
          <DateField label="Date" source="createdAt" />
          <TextField source="status" label="mesAnnonce.status" />
          <ActionButton label="Action" />
        </Datagrid>
      </List>
    </div>
  );
};

export const ViewDetail = ({ record }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const notify = useNotify();
  const refresh = useRefresh();

  const handleClose = () => {
    setOpen(false);
  };

  const transactionInformation = record.metadata
    ? record.metadata.transactionInformation
    : "";
  const [content, setContent] = React.useState(transactionInformation);

  const changeContent = (e) => {
    setContent(e.target.value);
  };

  const API = new LoopbackAPI();

  const dataPatch = {
    metadata: {
      transactionInformation: content,
    },
  };
  const patchransactionInformation = () => {
    API.paymentRequests
      .paymentRequestPrototypePatchAttributes(record.id, dataPatch as any)
      .then((res) => {
        notify("Success");
        setOpen(false);
      });
    
  };
  const translate = useTranslate();
  return (
    <div>
      <Button
        style={{ width: "100px" }}
        variant="text"
        color="primary"
        onClick={handleClickOpen}
      >
        <VisibilityIcon className="mr-1"/>  {translate("button.view")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
        <b> {translate("back-office.transaction-information")} </b>
          <div className="float-right">
            <a onClick={handleClose} title="Close">
              {" "}
              <ClearIcon />
            </a>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <textarea
              rows={10}
              cols={10}
              className="form-control mb-1"
              style={{ width: "30rem" }}
              value={content}
              onChange={changeContent}
            ></textarea>
            <div className="text-center">
              {" "}
              <Button onClick={patchransactionInformation} color="inherit" variant="contained">
              {translate("back-office.edit")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ResolveButton = ({ record }: any) => {
  const [open, setOpen] = React.useState(false);

  const notify = useNotify();
  const refresh = useRefresh();

  const handleClickOpen = () => {
    if (record.status === PaymentRequestStatus.ONLINE) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const transactionInformation = record.metadata
    ? record.metadata.transactionInformation
    : "";
  const [content, setContent] = React.useState(transactionInformation);

  const changeContent = (e) => {
    setContent(e.target.value);
  };

  const API = new LoopbackAPI();

  const dataPatch = {
    status: PaymentRequestStatus.RESOLVED,
    metadata: {
      transactionInformation: content,
    },
  };
  const createTransactionInformation = () => {
    API.paymentRequests
      .paymentRequestPrototypePatchAttributes(record.id, dataPatch as any)
      .then((res) => {
        refresh();
        notify("Success");
      });
  };
const translate = useTranslate();
  return (
    <div>
      <Button
        style={{ width: "100px" }}
        variant="text"
        color="primary"
        onClick={handleClickOpen}
      >
        <EditIcon className="mr-1"/>
        {translate("back-office.resolve")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
        <b> {translate("back-office.transaction-information")} </b>
          <div className="float-right">
            <a onClick={handleClose} title="Close">
              {" "}
              <ClearIcon />
            </a>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <textarea
              onChange={changeContent}
              value={content}
              rows={10}
              cols={10}
              className="form-control mb-1"
              style={{ width: "30rem" }}
            ></textarea>
            <div className="text-center m-1">
              <Button onClick={createTransactionInformation} variant="contained" >
                Enter
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
