// in src/Menu.js
import * as React from "react";
import { createElement, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources, useTranslate } from "react-admin";
import { withRouter } from "react-router-dom";
import SubMenu from "./subMenu";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalTaxiIcon from "@material-ui/icons/LocalTaxi";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PaymentIcon from "@material-ui/icons/Payment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MailIcon from '@material-ui/icons/Mail';
interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu = ({ onMenuClick, dense, logout }) => {
  const open = true;
  const resources: any[] = useSelector(getResources);
  const [state, setState] = useState({
    menuCatalog: false,
    menuSales: false,
    menuCustomers: false,
  });
  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const translate = useTranslate();
  return (
    <div>
      <MenuItemLink
        to="/Accounts"
        primaryText={`${translate("back-office.account")}s`}
        leftIcon={<SupervisorAccountIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      ></MenuItemLink>
      <MenuItemLink
        to="/Orders"
        primaryText={translate("back-office.orders")}
        leftIcon={<AssignmentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      ></MenuItemLink>
      <MenuItemLink
        to="/PaymentRequests"
        primaryText={translate("back-office.payment-request")}
        leftIcon={<PaymentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      ></MenuItemLink>
       <MenuItemLink
        to="/Statistics"
        primaryText={translate("back-office.statistics")}
        leftIcon={<TrendingUpIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      ></MenuItemLink>
      {/* <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        sidebarIsOpen={open}
        name="Settings"
        icon={<SettingsIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/Vehicles"
          primaryText="Vehicles"
          leftIcon={<LocalTaxiIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      </SubMenu> */}
      <MenuItemLink
           to="/Contacts"
           primaryText={`Contacts`}
          leftIcon={<MailIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        ></MenuItemLink>
      <MenuItemLink
           to="/Vehicles"
           primaryText={translate("back-office.vehicles")}
          leftIcon={<LocalTaxiIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        ></MenuItemLink>
    </div>
  );
};

export default withRouter(Menu as any);
