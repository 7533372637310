import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  useTranslate,
  required
} from "react-admin";
import React from "react";
import "./vehicles.scss";
import {AccountLanguage} from "../../react-admin-loopback/api/shared-constants"

const TextFieldDataName = (props: any) => {
  const record = props.record;
  let lang = "en";
  let displayDataName;
  if (lang === AccountLanguage.ENGLISH) {
    displayDataName = <div>{record?.displayData?.en.name}</div>;
  } else if (lang === AccountLanguage.FRENCH) {
    displayDataName = <div>{record?.displayData?.fr.name}</div>;
  } else if (lang === AccountLanguage.GERMAN) {
    displayDataName = <div>{record?.displayData?.de.name}</div>;
  }
  return <div>{displayDataName}</div>;
};


export const ListVehicle = (props: any) => {
  const translate = useTranslate();
  return (
    <List {...props} bulkActions={true} >
      <Datagrid>
        <TextField source="id" />
        <TextFieldDataName label="back-office.display-name"></TextFieldDataName>
        <TextField source="defaultPackageEstimatedSize" label="detailAnnonce.size" />
        <TextField source="defaultVolume" label="back-office.volume" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const EditVehicles = (props: any) => {
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm>
        <div className="create">
          <div className="row">
            <div className="col-md-3">
              <TextInput
                validate={[required()]}
                style={style.w100}
                resettable="true"
                source="displayData.fr.name"
                label={`${translate("back-office.display-name")} (fr)`}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                validate={[required()]}
                style={style.w100}
                multiline
                resettable="true"
                source="displayData.fr.description"
                label={`${translate("back-office.description")} (fr)`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <TextInput
              validate={[required()]}
                style={style.w100}
                resettable="true"
                source="displayData.en.name"
                label={`${translate("back-office.display-name")} (en)`}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                style={style.w100}
                multiline
                validate={[required()]}
                resettable="true"
                source="displayData.en.description"
                label={`${translate("back-office.description")} (en)`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <TextInput
                style={style.w100}
                resettable="true"
                validate={[required()]}
                source="displayData.de.name"
                label={`${translate("back-office.display-name")} (de)`}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                style={style.w100}
                multiline
                validate={[required()]}
                resettable="true"
                source="displayData.de.description"
                label={`${translate("back-office.description")} (de)`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SelectInput
                style={style.w100}
                validate={[required()]}
                source="defaultPackageEstimatedSize"
                label={`${translate("back-office.estimated-size")}`}
                choices={[
                  { id: "M", name: "M" },
                  { id: "S", name: "S" },
                  { id: "L", name: "L" },
                ]}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                style={style.w100}
                validate={[required()]}
                resettable="true"
                source="defaultVolume"
                label={`${translate("back-office.volume")}`}
                type="number"
              />
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const style = {
  w100: {
    width: "100%",
  },
};
export const CreateVehicles = (props: any) => {
  const translate = useTranslate();
  return (
    <Create {...props}>
     <SimpleForm>
        <div className="create">
          <div className="row">
            <div className="col-md-3">
              <TextInput
              validate={[required()]}
                style={style.w100}
                resettable="true"
                source="displayData.fr.name"
                label={`${translate("back-office.display-name")} (fr)`}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                style={style.w100}
                multiline
                validate={[required()]}
                resettable="true"
                source="displayData.fr.description"
                label={`${translate("back-office.description")} (fr)`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <TextInput
                style={style.w100}
                validate={[required()]}
                resettable="true"
                source="displayData.en.name"
                label={`${translate("back-office.display-name")} (en)`}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                style={style.w100}
                multiline
                validate={[required()]}
                resettable="true"
                source="displayData.en.description"
                label={`${translate("back-office.description")} (en)`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <TextInput
                style={style.w100}
                resettable="true"
                validate={[required()]}
                source="displayData.de.name"
                label={`${translate("back-office.display-name")} (de)`}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                style={style.w100}
                multiline
                validate={[required()]}
                resettable="true"
                source="displayData.de.description"
                label={`${translate("back-office.description")} (de)`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SelectInput
              validate={[required()]}
                style={style.w100}
                source="defaultPackageEstimatedSize"
                label={`${translate("back-office.estimated-size")}`}
                choices={[
                  { id: "M", name: "M" },
                  { id: "S", name: "S" },
                  { id: "L", name: "L" },
                ]}
              />
            </div>
            <div className="col-md-9">
              <TextInput
                style={style.w100}
                resettable="true"
                validate={[required()]}
                source="defaultVolume"
                label={`${translate("back-office.volume")}`}
                type="number"
              />
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
