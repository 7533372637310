import { List, Datagrid, TextField, useTranslate } from "react-admin";
import React from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
const ShowSender = (props) => {
  const record = props.record;
  return (
    <div>
      <p>{record?.email}</p>
      <p>{record?.phoneNumber}</p>
    </div>
  );
};

const ResolveButton = ({ record }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const translate = useTranslate();
  return (
    <div>
      <Button
        style={{ width: "100px" }}
        variant="text"
        color="primary"
        onClick={handleClickOpen}
      >
        <EditIcon className="mr-1" />
        {translate("button.view")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
          <b> Message </b>
          <div className="float-right">
            <a onClick={handleClose} title="Close">
              {" "}
              <ClearIcon />
            </a>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <textarea
              value={record && record.message}
              readOnly={true}
              rows={10}
              cols={10}
              className="form-control mb-1"
              style={{ width: "30rem" }}
            ></textarea>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ContactsList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }} actions={(<div></div>)}>
    <Datagrid>
      <TextField source="id" />
      <ShowSender label="back-office.sender" />
      <TextField source="message" />
      <ResolveButton />
    </Datagrid>
  </List>
);

export default ContactsList;
