import React from "react";
import { Admin, Resource, resolveBrowserLocale } from "react-admin";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/App.css";
import loopbackClient, { authProvider } from "./react-admin-loopback";
import {
  AccountCreate,
  AccountEdit,
  AccountList,
} from "./pages/accounts/accounts";
import { AnnoncesList, AnnoncesEdit } from "./pages/annonces/annonces";
import { PaymentList } from "./pages/payment/payment";
import { API_URL } from "./constant";
import fetchJson from "./react-admin-loopback/fetch";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PaymentIcon from "@material-ui/icons/Payment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import "../src/App.css";
import "react-notifications/lib/notifications.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { Dashboard } from "./dashboard/dashboard";
import { createBrowserHistory as createHistory } from "history";
import NotFound from "./admin/NotFound";
import SettingsIcon from "@material-ui/icons/Settings";
import Menu from "./admin/menu";
import customRoutes from "./admin/custom-routers"
import {ListVehicle,EditVehicles,CreateVehicles} from "./pages/vehicles/vehicles";
import MyLayout from "./admin/my-layout"
import Statistics from "./pages/statistics"
import Contacts from "./pages/contacts"
import frenchMessages from "./services/i18n/fr"
import englishMessages from "./services/i18n/en"
import polyglotI18nProvider from 'ra-i18n-polyglot';
const withUpload = (
  requestHandler: (type: any, resource: any, params: any) => any
) => async (
  type: string,
  resource: string,
  params: { data: any; previousData: any }
) => {
  if (
    resource === "Advertisements" &&
    (type === "UPDATE" || type === "CREATE")
  ) {
    const { pictures, ...data } = params.data;
    if (!pictures && params.data.type !== params.previousData.type) {
      return Promise.resolve({ data: params.previousData });
    }
    if (!pictures) {
      return requestHandler(type, resource, params);
    }
    if (!pictures.rawFile.type.startsWith(params.data.type)) {
      throw new Error(
        "The uploaded media does not match the selected media type"
      );
      return Promise.resolve({ data: params.previousData });
    }

    const formData = new FormData();
    formData.append("file", pictures.rawFile);
    const json = await fetchJson(
      `${API_URL}/Containers/Advertisements/upload`,
      { method: "POST", body: formData }
    );

    return requestHandler(type, resource, {
      ...params,
      data: {
        ...data,
        Asset: json.json.result.files.file[0].name,
      },
    });
  }

  if ((type === "UPDATE" || type === "CREATE") && resource === "Accounts") {
    const { pictures, ...data } = params.data;
    if (pictures && pictures.rawFile) {
      const formData = new FormData();
      formData.append("file", pictures.rawFile);
      const options = {
        method: "POST",
        body: formData,
      };
      if (resource === "Accounts") {
        const json = await fetchJson(
          `${API_URL}/Containers/avatar/upload`,
          options
        );
        return requestHandler(type, resource, {
          ...params,
          data: {
            ...data,
            avatar: json.json.result.files.file[0].name,
          },
        });
      }
    }
    return requestHandler(type, resource, { ...params, data: { ...data } });
  }
  return requestHandler(type, resource, params);
};

const initialState = {
  theme: "dark",
  grid: 5,
};

const history = createHistory();
const messages = {
  fr: frenchMessages,
  en: englishMessages,
}

const i18nProvider = polyglotI18nProvider(locale =>{
  // locale = "fr";
  return messages[locale]
})


const App = () => (
  <Admin
  locale={resolveBrowserLocale()}
  i18nProvider={i18nProvider}
  customRoutes={customRoutes} 
    layout={MyLayout}
    history={history}
    catchAll={NotFound}
    initialState={initialState}
    dataProvider={withUpload(loopbackClient())}
    dashboard={Statistics}
    authProvider={authProvider(`${API_URL}/Accounts/login`)}
  >
    <Resource
      name="Accounts"
      icon={SupervisorAccountIcon}
      list={AccountList}
      edit={AccountEdit}
    />
    <Resource
      name="Orders"
      options={{ label: "Annonces" }}
      icon={AssignmentTurnedInIcon}
      list={AnnoncesList}
      edit={AnnoncesEdit}
    />
    <Resource name="PaymentRequests" icon={PaymentIcon} list={PaymentList} />
    <Resource name="Vehicles" create={CreateVehicles} icon={PaymentIcon} edit={EditVehicles} list={ListVehicle} />
    <Resource name="Contacts" {...Contacts} />
  </Admin>
);

export default App;
