import React, { useEffect, Fragment } from "react";
import {
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  DateField,
  useRefresh,
  useNotify,
  ReferenceField,
  useTranslate,
} from "react-admin";
import "../annonces/annonces.scss";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import ClearIcon from "@material-ui/icons/Clear";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import fetch from "../../react-admin-loopback/fetch";
import { API_URL } from "../../constant";
import moment from "moment";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import DeleteButton from "../annonces/delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ListActions } from "./actions";
import HideAction from "../../components/hide-action";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
const useStyles = makeStyles({
  header: {
    backgroundColor: "red",
  },
  actions: {
    backgroundColor: "red",
  },
});

const PostBulkActionButtons = (props) => {
  console.log(props);

  return (
    <Fragment>
      <DeleteButton {...props} />
    </Fragment>
  );
};

const containerImagePackages = `${API_URL}/Containers/pic-package/download/`;
const containerImageSignature = `${API_URL}/Containers/pic-signature/download/`;
export const AnnoncesList = (props: any) => {
  return (
    <List
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      actions={<HideAction />}
    >
      <Datagrid
        rowClick="expand"
        expand={(__props) => (
          <ExpandedDetail {...__props} expanded={props.query} />
        )}
      >
        <ReferenceField source="ownerId" reference="Accounts">
          <TextField source="lastName" />
        </ReferenceField>
        <TextField source="type" />
        <DateField source="createdAt" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};
const ExpandedDetail = (props: any) => {
  console.log(props);
  const filter = {};
  return (
    <div>
      <DetailExpaned
        url={props.basePath}
        show={`expaned`}
        record={props.record}
      />
    </div>
  );
};

const DetailExpaned = ({
  record,
  show,
  url,
}: {
  record: any;
  show: string;
  url: string;
}) => {
  const [_status, setCurrentStatus] = React.useState(-100000);
  const [_newStatus, setnewStatus] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const popupAskChangeStatus = (newStatusId: number, enForce = false) => {
    setnewStatus(newStatusId);
    if (
      !enForce &&
      (newStatusId > _status || (newStatusId >= 3 && newStatusId < _status)) &&
      _status > 0
    ) {
      setOpen(true);
    } else if (enForce && newStatusId <= 1) {
      // enForce == true
      if (!(_status > newStatusId && newStatusId === 1)) {
        setOpen(true);
      }
    }
  };

  const filter = {
    include: ["packages"],
  };

  const query = {
    filter: '{"include": ["packages", "addresses","transporter"]}',
  };

  const [addresses, setAddresses]: any = React.useState([]);
  const [packages, setPackages]: any = React.useState([]);
  const [transporter, setTransporterId]: any = React.useState("");
  useEffect(() => {
    const API = new LoopbackAPI();
    API.orders
      .orderFindById(record.id, query, { secure: true })
      .then((order: any) => {
        setAddresses(order.addresses);
        setPackages(order.packages);
        setTransporterId(order.transporter);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(addresses);
  console.log("pack", packages);

  const handleClose = () => {
    setOpen(false);
  };
  const [statusLoading, setStatusLoading] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const changeStatus = () => {
    let a, b, c, d, e, f, g, h;
    [a, b, c, d, e, f, g, h] = [
      "ONLINE",
      "CONFIRMED",
      "PAID",
      "DELIVERING",
      "DELIVERED",
      "REFUSED",
      "EXPIRED",
      "DELETED",
    ];
    let sendStatus;
    if (_newStatus == 1) sendStatus = a;
    else if (_newStatus === 2) sendStatus = b;
    else if (_newStatus === 3) sendStatus = c;
    else if (_newStatus === 4) sendStatus = d;
    else if (_newStatus === 5) sendStatus = e;
    else if (_newStatus === -1) sendStatus = f;
    else if (_newStatus === -2) sendStatus = g;
    else if (_newStatus === -3) sendStatus = h;
    setStatusLoading(true);
    fetch(`${API_URL}/Orders/${record.id}`, {
      method: "PATCH",
      body: JSON.stringify({ status: sendStatus }),
    })
      .then((_response) => {
        setStatusLoading(false);
        setCurrentStatus(_newStatus);
        setOpen(false);
        notify("Update success");
        if (show === `expaned`) {
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const styleStatus = {
    statusSuccess: {
      border: "5px solid #47C66A",
    },
    statusError: {
      border: "5px solid red",
      color: "red",
    },
  };

  let slideImage: any[] = [];
  record.images &&
    record.images.map((image) => {
      var path = containerImagePackages + image.path;
      slideImage.push({
        original: path,
        thumbnail: path,
      });
    });
  const dateToFormat = "1976-04-19T12:59-0500";
  const arrImages = record.images || [];
  useEffect(() => {
    console.log("status update");

    const status = record.status;
    if (status === "ONLINE") setCurrentStatus(1);
    else if (status === "CONFIRMED") setCurrentStatus(2);
    else if (status === "PAID") setCurrentStatus(3);
    else if (status === "DELIVERING") setCurrentStatus(4);
    else if (status === "DELIVERED") setCurrentStatus(5);
    else if (status === "REFUSED") setCurrentStatus(-1);
    else if (status === "EXPIRED") setCurrentStatus(-2);
    else if (status === "DELETED") setCurrentStatus(-3);
    else {
      setCurrentStatus(0);
    }
  }, [record]);
  const translate = useTranslate();
  if (record.status) {
    return (
      <div className="expand">
        <div className="row head-expand">
          <div className="col-9"></div>
          <div
            className="col-3"
            style={
              show === `expaned` ? { display: "block" } : { display: "none" }
            }
          >
            <div className="float-right">
              <Link to={`${url}/${record.id}`}>
                <Button color="secondary" variant="contained">
                  {translate("back-office.detail")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row main-expand">
          <div className="main-info">
            <div className="col-9">
              <b>ID: {record.id}</b>
              <ul>
                {addresses.map((addr: any) => {
                  return <li> <LocationOnIcon/> {addr.displayData?.en.full_display_string}</li>;
                })}
              </ul>
              {/* <p>From: {addresses[0].displayData.en.full_display_string}</p> */}
              <p>{record.receiverDetailedAddress}</p>
              <div className="mb-2"><CalendarTodayIcon/> <span>{moment(record.createdAt).format("L")}</span></div>
              {transporter ? (
              
                 <div>
                    <LocalShippingIcon style={{marginRight: "0.3rem"}}/>
                  <b>{translate("back-office.transporter")}: </b>
                  <Link to={`../Accounts/${transporter.id}`}>{transporter?.firstName} {transporter?.lastName}</Link>
                 </div>
              
              ) : null}
            </div>
            <div className="col-3">
              <div className="float-right">
                <h3>
                  <div className="show-cost">{record.confirmedTotalCost} €</div>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="row status-expand">
          <div className="col-1">
            <div
              className="status rounded-circle"
              style={
                _status >= 1
                  ? styleStatus.statusSuccess
                  : styleStatus.statusError
              }
              onClick={() => popupAskChangeStatus(1)}
            >
              {_status >= 1 ? (
                <DoneOutlineIcon color="primary"></DoneOutlineIcon>
              ) : (
                <div className="errorIcon">
                  <ClearIcon color="error"></ClearIcon>
                </div>
              )}
            </div>
            <div className="status-label">
              {_status >= 1 ? (
                <span>{translate("back-office.online")}</span>
              ) : null}
              {_status == -1 ? (
                <span className="status-error">
                  {translate("back-office.refused")}
                </span>
              ) : null}
              {_status == -2 ? (
                <span className="status-error">
                  {translate("back-office.expired")}
                </span>
              ) : null}
              {_status == -3 ? (
                <span className="status-error">
                  {translate("back-office.deleted")}
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-1">
            <ArrowForwardIosIcon />
          </div>
          <div className="col-1">
            <div
              className="status rounded-circle"
              style={_status >= 2 ? styleStatus.statusSuccess : undefined}
              onClick={() => popupAskChangeStatus(2)}
            >
              {_status >= 2 ? (
                <DoneOutlineIcon color="primary"></DoneOutlineIcon>
              ) : null}
            </div>
            <div className="status-label">
              <span>{translate("back-office.confirmed")}</span>
            </div>
          </div>
          <div className="col-1">
            <ArrowForwardIosIcon />
          </div>
          <div className="col-1">
            <div
              className="status rounded-circle"
              style={_status >= 3 ? styleStatus.statusSuccess : undefined}
              onClick={() => popupAskChangeStatus(3)}
            >
              {_status >= 3 ? (
                <DoneOutlineIcon color="primary"></DoneOutlineIcon>
              ) : null}
            </div>
            <div className="status-label">
              <span>{translate("back-office.paid")}</span>
            </div>
          </div>
          <div className="col-1">
            <ArrowForwardIosIcon />
          </div>
          <div className="col-1">
            <div
              className="status rounded-circle"
              style={_status >= 4 ? styleStatus.statusSuccess : undefined}
              onClick={() => popupAskChangeStatus(4)}
            >
              {_status >= 4 ? (
                <DoneOutlineIcon color="primary"></DoneOutlineIcon>
              ) : null}
            </div>
            <div className="status-label">
              <span>{translate("back-office.delivering")}</span>
            </div>
          </div>
          <div className="col-1">
            <ArrowForwardIosIcon />
          </div>
          <div className="col-1">
            <div
              className="status rounded-circle"
              style={_status >= 5 ? styleStatus.statusSuccess : undefined}
              onClick={() => popupAskChangeStatus(5)}
            >
              {_status >= 5 ? (
                <DoneOutlineIcon color="primary"></DoneOutlineIcon>
              ) : null}
            </div>
            <div className="status-label">
              <span>{translate("back-office.delivered")}</span>
            </div>
          </div>
        </div>

        <div
          className="viewDetail"
          style={
            show !== `expaned` ? { display: "block" } : { display: "none" }
          }
        >
          <div className="button-set-status">
            <Button
              variant="outlined"
              disabled={_status < 0}
              onClick={() => popupAskChangeStatus(-1, true)}
            >
              <span>{translate("back-office.refused")}</span>
            </Button>
            <Button
              variant="contained"
              disabled={_status >= 1}
              onClick={() => popupAskChangeStatus(1, true)}
              color="secondary"
            >
              {translate("back-office.active-online")}
            </Button>
          </div>
          {/* {record.images.length>0?
                    (<div className="showSlideImage">
                        <div className="slideImage">
                            <ImageGallery items={slideImage} />
                        </div>
                    </div>
                    ) : null} */}

          <div className="show-slide">
            {/* <div className="slide-image">
              {arrImages.map((image) => (
                <div className="image-pacage">
                  <img src={containerImagePackages + image.path} />
                </div>
              ))}
            </div> */}
            <div className="slide-image">
              {arrImages.map((image) => {
                return (
                  <div className="image-package">
                    {image.path.includes("http") ? (
                      <img src={image.path} />
                    ) : (
                      <img src={containerImagePackages + image.path} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="show-info"
          style={
            show !== `expaned` ? { display: "block" } : { display: "none" }
          }
        >
          <h5>{translate("back-office.size")}:</h5>
          {record.type == "ROOM" ? (
            <div>
              {packages.map((pack: any) => (
                <div>
                  {pack.volume} m <sup>3</sup>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {packages.map((pack: any) => (
                <div>
                  {pack.estimatedSize ? (
                    <div>
                      {pack.name}: {pack.estimatedSize}
                    </div>
                  ) : (
                    <div>
                      {pack.name}: {pack.height} x {pack.width} x {pack.length}{" "}
                      (cm) | {pack.weight} kg
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <hr />
          <h5>
            {translate("form.depart")} {translate("newAnnonce.note.title")}:
          </h5>
          <blockquote>{record.departureNote}</blockquote>
          <hr />
          <h5>
            {translate("search.arrivalAddress")}{" "}
            {translate("newAnnonce.note.title")}:
          </h5>
          <blockquote>{record.arrivalNote}</blockquote>
          <hr />
          <h5>{translate("newAnnonce.note.title")}:</h5>
          <blockquote>{record.note}</blockquote>
        </div>
        {record.verifiedSignature ? (
          <div className="text-center w-100 signature">
            {" "}
            <img
              src={`${containerImageSignature}${record.verifiedSignature}`}
              alt=""
            />
          </div>
        ) : null}
        <div>
          <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogContent>
              {statusLoading ? (
                <div className="text-center">
                  <CircularProgress />{" "}
                </div>
              ) : (
                <DialogContentText>
                  {translate("back-office.change-status")}
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={statusLoading}
                variant="outlined"
                onClick={handleClose}
              >
                {translate("button.cancel")}
              </Button>
              <Button
                disabled={statusLoading}
                onClick={changeStatus}
                color="secondary"
                variant="contained"
              >
                {translate("button.ok")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const accountRedirect = (props: any) => <Link to="/Orders" {...props} />;

export const AnnoncesEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm toolbar={null}>
        <div className="row">
          <Button component={accountRedirect}>
            <ArrowBackIcon />
          </Button>
          <h3>{translate("back-office.order")}</h3>
        </div>
        <DetailExpaned
          url={props.basePath}
          record={{ ...props.record }}
          show={`viewDetail`}
        />
      </SimpleForm>
    </Edit>
  );
};
