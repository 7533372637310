import React from "react";
import "./statistics.scss";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EuroIcon from "@material-ui/icons/Euro";
import Chart from "react-google-charts";
import LinearProgress from "@material-ui/core/LinearProgress";
import storage from "../../react-admin-loopback/storage"
import {
  InputLabel,
  NativeSelect,
  Backdrop,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import {
  SystemStatisticsType,
  SystemStatisticsDurationType,
} from "../../react-admin-loopback/api/shared-constants";
import _ from "lodash";
import { useNotify, useTranslate } from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const fillEmptyValue = (data, from, to) => {
  const b = _.defaults(
    data
      .map((x) => ({ [x[0]]: x[1] }))
      .reduce((r, x) => Object.assign(r, x), {}),
    _(_.range(from, to))
      .zipObject(null)
      .mapValues((x) => 0)
      .value()
  );
  return Object.keys(b).map((x) => [x, b[x]]);
};

const Statistics = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const yearArr = range(currentYear, currentYear - 4, -1);
  const [summary, setSummary] = React.useState(1);
  
  const summaryType = [
    { text: `${translate("back-office.user")}s`, color: "#0064E0", type: SystemStatisticsType.NEW_USER },
    {
      text: `${translate("back-office.order")}s`,
      color: "rgb(7, 135, 107)",
      type: SystemStatisticsType.NEW_ORDER,
    },
    {
      text: `${translate("back-office.amount")}`,
      color: "#6744B1",
      type: SystemStatisticsType.ECOPOST_EARNING_AMOUNT,
    },
  ];

  ///////////////////
  const [year, setYear] = React.useState(currentYear.toString());
  const [month, setMonth] = React.useState(currentMonth.toString());
  const [duration, setDuration] = React.useState(
    SystemStatisticsDurationType.YEAR.toString()
  );
  const [timeLabel, setTimeLabel] = React.useState("");
  const [data, setData] = React.useState([
    ["x", summaryType[summary - 1].text],
    [100, 100],
  ]);
  ///////////////////
  const [dataAll, setDataAll] = React.useState([]);
  // Style Chart //
  var cssClassNames = {
    headerRow: "italic-darkblue-font large-font bold-font",
    tableRow: "",
    oddTableRow: "beige-background",
    selectedTableRow: "orange-background large-font",
    hoverTableRow: "",
    headerCell: "gold-border",
    tableCell: "",
    rowNumberCell: "underline-blue-font",
  };

  const changeFilter = () => {
    let filter = {};
    console.log(duration);

    if (duration == SystemStatisticsDurationType.YEAR) {
      filter = {
        where: {
          durationType: duration,
          type: summaryType[summary - 1].type,
        },
      };
    } else if (duration == SystemStatisticsDurationType.MONTH) {
      filter = {
        where: {
          durationType: duration,
          type: summaryType[summary - 1].type,
          year: year,
        },
      };
    } else if (duration == SystemStatisticsDurationType.DAY) {
      filter = {
        where: {
          durationType: duration,
          type: summaryType[summary - 1].type,
          year: year,
          month: month,
        },
      };
    }
    return JSON.stringify(filter);
  };
  const [loading, setLoading] = React.useState(false);
  // Calling API//
  const API = new LoopbackAPI();
  React.useEffect(() => {
  
    console.log(duration);
    const filter = changeFilter();
    setLoading(true);
    API.systemStatistics
      .systemStatisticsFind({
        filter: filter,
      })
      .then((data) => {
        setLoading(false);
        console.log(data);
        var dataChart: any[] = [["x", summaryType[summary - 1].text]];
        if (data.length == 0) {
          dataChart.push([0, 0]);
        }
        let tempData: any[] = data.map((d: any) => {
          if (duration == SystemStatisticsDurationType.YEAR) {
            setTimeLabel(`${translate("back-office.year")}`);
            return [d.year.toString(), parseInt(d.amount)];
          } else if (duration == SystemStatisticsDurationType.MONTH) {
            setTimeLabel(`${translate("back-office.month")} (${year})`);
            return [(d.month + 1).toString(), parseInt(d.amount)];
          } else if (duration == SystemStatisticsDurationType.DAY) {
            setTimeLabel(`${translate("back-office.day")}s  (${year}-${parseInt(d.month) + 1})`);
            return [d.day.toString(), parseInt(d.amount)];
          }
          return ["0", "0"];
        });
        console.log(tempData);
        if (duration == SystemStatisticsDurationType.MONTH) {
          tempData = fillEmptyValue(tempData, 1, 13);
        } else if (duration == SystemStatisticsDurationType.DAY) {
          tempData = fillEmptyValue(tempData, 1, 31);
        }

        dataChart.push(...tempData);
        setData(dataChart);
      })
      .catch((e) => {
        const statusCode = e.error.statusCode;
        if(statusCode === 401 || statusCode === 403){
          storage.remove('lbtoken');
        }
        notify("Error", "warning");
      });
  }, [duration, summary, year, month]);

  React.useEffect(() => {
    const filter = {
      where: {
        durationType: SystemStatisticsDurationType.ALL,
      },
    };
    API.systemStatistics
      .systemStatisticsFind({ filter: JSON.stringify(filter) })
      .then((dataRes: any[]) => {
        setDataAll(dataRes as any);
      });
  }, []);
  const getDataAll = (type) =>{
      const data  : any = dataAll.find((data:any) => data.type === type) ;
      console.log(data);
      let amount = (type == SystemStatisticsType.ECOPOST_EARNING_AMOUNT) 
      ? 
      parseFloat((data)? data.amount : -1).toLocaleString('de-DE')
      :   
       parseInt((data)? data.amount : -1).toLocaleString('de-DE') 
      if(parseInt(amount)<0 && loading){
        return `${translate("back-office.calculating")}...`
      }
      else if(parseInt(amount)<0){
        return "0";
      }
      return amount;
  }
  return (
    <div className="mx-3">
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mt-2 summary">
        <div className="row mt-3">
          <div className="col-4 ">
            <div
              className={
                summary == 1 ? "border-user sum-item user" : "sum-item user"
              }
              title={translate("back-office.user")}
              onClick={() => {
                setSummary(1);
              }}
            >
              <div>
                {getDataAll(SystemStatisticsType.NEW_USER)} <SupervisorAccountIcon />
              </div>
              <span>{translate("back-office.user")}s</span>
            </div>
          </div>
          <div className="col-4 ">
            <div
              title="Orders"
              className={
                summary == 2 ? "border-order sum-item order" : "sum-item order"
              }
              onClick={() => {
                setSummary(2);
              }}
            >
              <div>
                {getDataAll(SystemStatisticsType.NEW_ORDER)} <AssignmentIcon />
              </div>
            <span>{translate("back-office.order")}s</span>
            </div>
          </div>
          <div className="col-4 ">
            <div
              title="Amounts"
              className={
                summary == 3
                  ? "border-amount sum-item amount"
                  : "sum-item amount"
              }
              onClick={() => {
                setSummary(3);
              }}
            >
              <div>
              {getDataAll(SystemStatisticsType.ECOPOST_EARNING_AMOUNT)} <EuroIcon />
              </div>
              <span>{translate("back-office.amount")}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Chart  */}
      <div className="mt-5 chart">
        <div className="row mb-1">
          <div className="col-4 row m-0 p-0">
            <div className="col-4 w-100">
              <InputLabel
                style={{ color: summaryType[summary - 1].color }}
                htmlFor="select"
              >
                <b>{translate("back-office.duration")}</b>
              </InputLabel>
              <NativeSelect
                style={{ color: summaryType[summary - 1].color, width: "100%" }}
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                id="select"
              >
                <option value="YEAR">{translate("mes-message.all")}</option>
              <option value="MONTH">{translate("back-office.year")}</option>
                <option value="DAY">{translate("back-office.month")}</option>
              </NativeSelect>
            </div>

            {duration == "DAY" || duration == "MONTH" ? (
              <div className="col-4 w-100">
                <InputLabel
                  style={{ color: summaryType[summary - 1].color }}
                  htmlFor="select"
                >
                  <b>{translate("back-office.year")}</b>
                </InputLabel>
                <NativeSelect
                  style={{
                    color: summaryType[summary - 1].color,
                    width: "100%",
                  }}
                  id="select"
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  {yearArr.map((y) => (
                    <option value={y}>{y}</option>
                  ))}
                </NativeSelect>
              </div>
            ) : null}

            {duration == "DAY" ? (
              <div className="col-4">
                <InputLabel
                  style={{ color: summaryType[summary - 1].color }}
                  htmlFor="select"
                >
                  <b>{translate("back-office.month")}</b>
                </InputLabel>
                <NativeSelect
                  style={{
                    color: summaryType[summary - 1].color,
                    width: "100%",
                  }}
                  value={month}
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                  id="select"
                >
                  {range(
                    parseInt(year) < new Date().getFullYear()
                      ? 12
                      : new Date().getMonth() + 1,
                    1,
                    -1
                  ).map((m) => (
                    <option value={m - 1}>{m}</option>
                  ))}
                </NativeSelect>
              </div>
            ) : null}
          </div>

          <div className="col-4 text-center">
            <div style={{ color: summaryType[summary - 1].color }}>
              <h3>
                <b>{summaryType[summary - 1].text}</b>
              </h3>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", maxWidth: "100%" }}>
          <Chart
            width={"100%"}
            height={"400px"}
            chartType="AreaChart"
            loader={<LinearProgress />}
            data={data}
            style={{}}
            options={{
              colors: [summaryType[summary - 1].color],
              animation: {
                duration: 500,
                easing: "in-out",
              },
              hAxis: {
                title: timeLabel,
              },
              vAxis: {
                title: summaryType[summary - 1].text,
              },
              cssClassNames: cssClassNames,
              curveType: "function",
              legend: { position: "right" },
              smoothLine: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
