import * as React from "react";
import {
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import Button from "@material-ui/core/Button";
import { OrderStatus } from "../../react-admin-loopback/api/shared-constants";
import CancelIcon from "@material-ui/icons/Cancel";
const DeleteButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  console.log(selectedIds);
  const API = new LoopbackAPI();

  // const [updateMany, { loading }] = useUpdateMany(
  //     'Orders',
  //     selectedIds,
  //     {status: "DELETED"},
  //     {method: "PATCH"},
  //     {
  //         onSuccess: () => {
  //             refresh();
  //             notify('Posts updated');
  //             unselectAll('posts');
  //         },
  //         onFailure: error => notify('Error: posts not updated', 'warning'),
  //     }
  // );

  const updateMany = () => {
    // API.orders.orderFindById(selectedIds[0]).then(data => console.log(data));
    const data = {
      status: OrderStatus.REFUSED,
    };
    Promise.all(
      selectedIds.map((id) =>
        API.orders.orderPrototypePatchAttributes(id, data as any)
      )
    ).then(() => {
      notify("Success");
      unselectAll("Orders");
      refresh();
    });
  };
  const style= {
      button: {
          color: "white",
          backgroundColor: "red",
          
      }
  }
  return (
    <Button
      variant="contained"
      startIcon={<CancelIcon />}
      style={style.button}
      onClick={updateMany}
    >
      Refuse
    </Button>
  );
};

export default DeleteButton;
