import React, { Component } from "react";
import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  List,
  PasswordInput,
  RadioButtonGroupInput,
  SelectInput,
  showNotification,
  SimpleForm,
  TextInput,
  EditButton,
  TextField,
  FormDataConsumer,
  Filter,
  SearchInput,
  useTranslate,
  useNotify,
} from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import "../accounts/account.scss";
import { Link } from "react-router-dom";
import { DateInput } from "../../react-admin-date-inputs";
import Button from "@material-ui/core/Button";
import { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  makeStyles,
  Theme,
  Paper,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ClearIcon from "@material-ui/icons/Clear";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckIcon from "@material-ui/icons/Check";
import ContentFilter from "@material-ui/icons/FilterList";
import { Form } from "react-final-form";
import { API_URL } from "../../constant";
import SetActiveButtons from "./set-active";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
const apiContainetAvatar = `${API_URL}/Containers/pic-avatar/download`;
const AvatarShow = ({ record = {}, size }: any) => {
  size = size || 32;
  const extra = { label: "avatar" };
  return record.avatar ? (
    <Avatar
      {...extra}
      // src={`${API_URL}/Containers/photos/download/${record.avatar}`}
      src={`${apiContainetAvatar}/${record.avatar}`}
      style={{
        width: size,
        height: size,
      }}
    />
  ) : null;
};

const DisplayName = ({ record = {} }: any) => {
  return <div>{`${record.firstName} ${record.lastName}`}</div>;
};

const StatusVerify = ({ record = {} }: any) => {
  let statusEmailVerify: string;
  let statusLegalDocumentsVerify: string;
  const translate = useTranslate()
  record.isEmailVerified
    ? (statusEmailVerify = `${translate("back-office.email")} ${translate("back-office.verified")}` )
    : (statusEmailVerify = `${translate("back-office.email")} ${translate("back-office.unverified")}` )
  record.isLegalDocumentVerified
    ? (statusLegalDocumentsVerify = `${translate("back-office.account")} ${translate("back-office.verified")}`)
    : (statusLegalDocumentsVerify = `${translate("back-office.account")} ${translate("back-office.unverified")}`);
  return <div>{`${statusEmailVerify} - ${statusLegalDocumentsVerify}`}</div>;
};

// const accountDetail = (props: any) =>  <Link to={`/Accounts/${props.record.id}`} {...props} />

const PostFilterButton = ({ showFilter }: { showFilter: any }) => (
  <Button
    size="small"
    color="primary"
    onClick={() => showFilter("main")}
    startIcon={<ContentFilter />}
  >
    Filter
  </Button>
);

const PostFilterForm = ({
  displayedFilters,
  filterValues,
  setFilters,
  hideFilter,
}: {
  displayedFilters: any;
  filterValues: any;
  setFilters: any;
  hideFilter: any;
  open: any;
}) => {
  if (!displayedFilters.main) return null;
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div className="w-100">
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }: { handleSubmit: any }) => (
          <form onSubmit={handleSubmit}>
            <Box component="span" mr={1}>
              <TextInput
                resettable
                helperText={false}
                source="phoneNumber"
                label="Search"
              />
            </Box>
            <Box component="span" mr={1}>
              <SelectInput
                source="type"
                label="Select type"
                choices={[
                  { id: "INDIVIDUAL", name: "INDIVIDUAL" },
                  { id: "PROFESSIONAL", name: "PROFESSIONAL" },
                  { id: "ENTERPRISE", name: "ENTERPRISE" },
                ]}
              />
            </Box>
            <Box component="span" mr={1}>
              <SelectInput
                source="isLegalDocumentVerified"
                label="Status verified"
                choices={[
                  { id: "true", name: "Verified" },
                  { id: "false", name: "Unverified" },
                ]}
              />
            </Box>
            <Box mt={1} mr={1} mb={1}>
              <Button variant="outlined" color="primary" type="submit">
                Apply filter
              </Button>
              <a title="Clear filter" onClick={resetFilter}>
                {" "}
                <ClearIcon />
              </a>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

const PostFilter = (props: any) => {
  return props.context === "button" ? (
    <PostFilterButton {...props} />
  ) : (
    <PostFilterForm {...props} open />
  );
};

const PostFilters = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput
        source="phoneNumber"
        alwaysOn
        placeholder={`${translate("search.title")} ${translate("form.telephone")}`}
      />
      <SelectInput
        source="isLegalDocumentVerified"
        label={`${translate("mesTrajet.status")}`}
        choices={[
          { id: "true", name: translate("back-office.verified") },
          { id: "false", name: translate("back-office.unverified") },
        ]}
      />
      <SelectInput
        source="type"
        label="mes-message.select-one"
        choices={[
          { id: "INDIVIDUAL", name: "INDIVIDUAL" },
          { id: "PROFESSIONAL", name: "PROFESSIONAL" },
          { id: "ENTERPRISE", name: "ENTERPRISE" },
        ]}
      />
    </Filter>
  );
}

const PostBulkActionButtons = (props) => {
  console.log(props);

  return (
    <Fragment>
      <SetActiveButtons {...props} />
    </Fragment>
  );
};

// export class AccountList extends Component<any, any> {
//   constructor(props: any) {
//     super(props);
//   }
//   translate = useTranslate();
//   render() {

//   }
// }

export const AccountList = (props) => {
  const translate = useTranslate();
  return (
    <List {...props} filters={<PostFilters />} bulkActionButtons={false}>
      <Datagrid>
        <AvatarShow />
        <DisplayName label="profile.contact.nameLabel" />
        <TextField source="type" label="mes-message.type" />
        <TextField source="phoneNumber" label="profile.contact.phoneLabel" />
        <StatusVerify label="profile.legalDocuments.statusLabel" />
        {/* <Button component={accountDetail} variant="contained">Detail</Button> */}
        <EditButton label="back-office.edit" />
      </Datagrid>
    </List>
  );
};

const accountRedirect = (props: any) => <Link to="/Accounts" {...props} />;

const ChangePasswordDialog = (props) => {
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const generatePassword = () => {
    const API = new LoopbackAPI();
    const userId = props.record.id;
    API.accounts.accountPrototypeGeneratePassword(userId).then((response) => {
      console.log(response);
      notify("Sended !");
    }).catch(error=>{
      notify("Server error", "warning");
    });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    input: {
      marginTop: "1rem",
    },
    main: {
      textAlign: "center",
    },
  };
  const translate = useTranslate();
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {translate("back-office.gen-password")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div className="float-right">
            <a onClick={handleClose} title="Close">
              {" "}
              <ClearIcon />
            </a>
          </div>
        </DialogTitle>
        <DialogContent> {translate("back-office.gen-password")} ?</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
          {translate("button.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={generatePassword}
            color="secondary"
          >
           {translate("button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const BankInfomationDialog = ({
  bankInformation,
}: {
  bankInformation: any;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    showNotification("hello");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    rowInfo: {
      border: "1px solid",
      marginBottom: "1rem",
      padding: "0.5rem",
      marginRight: "0.2rem",
    },
    div: {
      margin: "0",
    },
  };
  const translate = useTranslate();
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {translate("button.view")} {translate("back-office.bank-info")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div className="float-right">
            <a onClick={handleClose} title="Close">
              {" "}
              <ClearIcon />
            </a>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={style.rowInfo}>
            {" "}
            <b>{translate("back-office.address")} 1:</b> <span>{bankInformation?.address}</span>
          </div>
          <div style={style.rowInfo}>
            {" "}
            <b>{translate("back-office.address")} 2:</b> <span>{bankInformation?.additionalAddress}</span>
          </div>
          <div style={style.div} className="row">
            <div style={style.rowInfo}>
              {" "}
              <b>{translate("back-office.code-portal")}:</b> <span>{bankInformation?.postalCode}</span>
            </div>
            <div style={style.rowInfo}>
              {" "}
              <b>{translate("form.city")}:</b> <span>{bankInformation?.city}</span>
            </div>
          </div>
          <div style={style.rowInfo}>
            {" "}
            <b>IBAN:</b> <span>{bankInformation?.iBan}</span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabLegalDocuments({ record }: any) {
  const [legalDocuments, setLegalDocuments] = React.useState(
    record.legalDocuments
  );

  const classes = useStyles();
  const [tabID, setTabId] = React.useState(0);
  const [showButton, setShowButton] = React.useState(true);
  let _legalDocuments = legalDocuments;

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabId(newValue);
    setShowButton(true);
    console.log(newValue);
  };

  const handleChangeStatus = (newStatus: string) => {
    const confirm = window.confirm("Change status legaldocument ?");
    const API = new LoopbackAPI();
    _legalDocuments[tabID].status = newStatus;
    const data = {
      legalDocuments: _legalDocuments,
    };
    if (confirm) {
      API.accounts
        .accountPrototypePatchAttributes(record.id, data as any)
        .then((response) => {
          setLegalDocuments([..._legalDocuments]);
        });
    }
  };
  const ButtonVerify = () => {
    console.log(record.legalDocuments[tabID].status);

    if (
      _legalDocuments[tabID].status === "REJECTED" ||
      _legalDocuments[tabID].status === "VERIFIED"
    ) {
      return null;
    } else {
      return (
        <div style={{ marginTop: "1rem" }}>
          <Button
            onClick={() => handleChangeStatus("REJECTED")}
            style={{ marginRight: "1rem" }}
            variant="contained"
          >
            Reject
          </Button>
          <Button
            onClick={() => handleChangeStatus("VERIFIED")}
            variant="contained"
            color="secondary"
          >
            Verify
          </Button>
        </div>
      );
    }
  };
  const nameTab = (key: string) => {
    return key.replace("_", " ");
  };

  const ShowImageLegalDocument = (props: any) => {
    const [srcImage, setSrcImage] = React.useState(props.src);
    return (
      <img
        src={srcImage}
        onError={() => {
          setShowButton(false);
          setSrcImage("");
        }}
      ></img>
    );
  };
  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs
          value={tabID}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleChangeTab}
        >
          {legalDocuments.map((id, index) => (
            <Tab label={nameTab(legalDocuments[index].key)} />
          ))}
        </Tabs>
      </Paper>
      <div className="showTab">
        {legalDocuments.map((id, index) => (
          <TabPanel value={tabID} index={index}>
            {legalDocuments ? (
              <ShowImageLegalDocument
                src={`${API_URL}/Containers/` + legalDocuments[tabID].path}
              />
            ) : (
              <span>There are no photos</span>
            )}
          </TabPanel>
        ))}
        <b>{_legalDocuments[tabID].status}</b>
        <div style={showButton ? { display: "block" } : { display: "none" }}>
          {" "}
          <ButtonVerify></ButtonVerify>
        </div>
      </div>
    </div>
  );
}
const DocumentsDialog = ({ record }: any) => {
  const legalDocuments = record.legalDocuments;
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (legalDocuments !== null) {
    return (
      <div>
        <Button variant="contained" onClick={handleClickOpen}>
          {translate("button.view")} {translate("back-office.legal-documents")}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <div className="float-right">
              <a onClick={handleClose} title="Close">
                {" "}
                <ClearIcon />
              </a>
            </div>
          </DialogTitle>
          <DialogContent>
            <TabLegalDocuments record={record}></TabLegalDocuments>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    );
  } else return null;
};

export const AccountEdit = (props: any) => {
  const arr = [1, 2, 3, 4];
  const translate = useTranslate();
  return (
    <Edit {...props}>
      {/* <SimpleForm {...props} toolbar={<CustomToolbar />}> */}
      <SimpleForm {...props}>
        <div className="row">
          <Button component={accountRedirect}>
            <ArrowBackIcon />
          </Button>
          <h3>{translate("back-office.account")}</h3>
        </div>
        <div id="showDetailAccount" className="row">
          <div className="col-6 left">
            <TextInput disabled source="email" />
            <TextInput
              disabled
              source="phoneNumber"
              label="back-office.phone-number"
            />
            <div className="row">
              <div className="col-6">
                {" "}
                <TextInput
                  source="firstName"
                  fullWidth
                  label="form.firstName"
                />
              </div>
              <div className="col-6">
                {" "}
                <TextInput source="lastName" fullWidth label="form.lastName" />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <DateInput source="birthday" label="form.birthday" />
              </div>
              <div className="col-6">
                <RadioButtonGroupInput
                  source="gender"
                  label="form.sex"
                  choices={[
                    { id: "female", name: translate("form.female") },
                    { id: "male", name: translate("form.male") },
                  ]}
                />
              </div>
            </div>
            <SelectInput
              source="type"
              choices={[
                { id: "MANAGER", name: "MANAGER" },
                { id: "INDIVIDUAL", name: "INDIVIDUAL" },
                { id: "PROFESSIONAL", name: "PROFESSIONAL" },
                { id: "ENTERPRISE", name: "ENTERPRISE" },
              ]}
            />
          </div>
          <div className="col-6 right">
            <BooleanInput source="isActive" label="back-office.active" />
            <FormDataConsumer>
              {({ formData }: { formData: any }) => (
                <div>
                  <div className="statusVerify">
                    {formData.isEmailVerified ? (
                      <div>
                        <CheckIcon />
                        <b>
                          {translate("back-office.email")}{" "}
                          {translate("back-office.verified")}{" "}
                        </b>
                      </div>
                    ) : (
                      <div>
                        <ClearIcon></ClearIcon>
                        <b>
                          {translate("back-office.email")}{" "}
                          {translate("back-office.unverified")}{" "}
                        </b>
                      </div>
                    )}
                    {formData.isPhoneNumberVerified ? (
                      <div>
                        <CheckIcon />
                        <b>
                          {translate("back-office.phone-number")}{" "}
                          {translate("back-office.verified")}{" "}
                        </b>
                      </div>
                    ) : (
                      <div>
                        <ClearIcon></ClearIcon>
                        <b>
                          {translate("back-office.phone-number")}{" "}
                          {translate("back-office.unverified")}{" "}
                        </b>
                      </div>
                    )}
                    {formData.isLegalDocumentVerified ? (
                      <div>
                        <CheckIcon />
                        <b>
                          {translate("back-office.legal-documents")}{" "}
                          {translate("back-office.verified")}{" "}
                        </b>
                      </div>
                    ) : (
                      <div>
                        <ClearIcon></ClearIcon>
                        <b>
                          {translate("back-office.legal-documents")}{" "}
                          {translate("back-office.unverified")}{" "}
                        </b>
                      </div>
                    )}
                  </div>
                  <DocumentsDialog record={formData} />
                  <BankInfomationDialog
                    bankInformation={formData.bankInformation}
                  />
                  <ChangePasswordDialog record={formData} />
                </div>
              )}
            </FormDataConsumer>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export const AccountCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="lastName" />
      <TextInput source="firstName" />
      <RadioButtonGroupInput
        source="gender"
        choices={[
          { id: "female", name: "Female" },
          { id: "male", name: "Male" },
        ]}
      />
      <DateInput source="birthday" />
      <TextInput source="addresse" />
      <TextInput source="stripeCustomerId" />
      <ImageInput source="avatar" accept="image/*" multiple={false}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="username" />
      <TextInput source="email" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Create>
);
